<template>
  <div class="flex flex-col gap-2">
    <div class="flex items-center gap-1.5">
      <div class=" h-9 w-9 flex flex-col items-center justify-center border border-gray-200 rounded-md">
        <fw-icon-calendar v-if="type == 'date'" class="w-5 h-5 text-gray-400"></fw-icon-calendar>
        <fw-icon-clock-line v-else-if="type == 'datetime'" class="w-5 h-5 text-gray-400"></fw-icon-clock-line>
        <fw-icon-file v-else-if="type == 'upload_file'" class="w-5 h-5 text-gray-400"></fw-icon-file>
        <fw-icon-mail v-else-if="type == 'email'" class="w-5 h-5 text-gray-400"></fw-icon-mail>
        <fw-icon-phone v-else-if="type == 'phone_number'" class="w-5 h-5 text-gray-400"></fw-icon-phone>
        <fw-icon-map v-else-if="type == 'address'" class="w-5 h-5 text-gray-400"></fw-icon-map>
        <fw-icon-survey v-else-if="type == 'multiple_choice'" class="w-5 h-5 text-gray-400"></fw-icon-survey>
        <fw-icon-text-box v-else-if="type == 'text_area'" class="w-5 h-5 text-gray-400"></fw-icon-text-box>
        <fw-icon-flag v-else-if="type == 'country_input'" class="w-5 h-5 text-gray-400"></fw-icon-flag>
        <fw-icon-bank v-else-if="type == 'vat'" class="w-5 h-5 text-gray-400"></fw-icon-bank>
        <fw-icon-toggle v-else-if="type == 'bool'" class="w-5 h-5 text-gray-400"></fw-icon-toggle>
        <div v-else-if="type == 'select'" class="relative h-9 w-9 ">
          <fw-icon-chevron-down class="absolute left-1/2 -ml-2.5 bottom-0.5 h-5 w-5" />
          <fw-icon-chevron-up class="absolute left-1/2 -ml-2.5 top-0.5 h-5 w-5" />
        </div>
        <div v-else-if="type == 'text_input'" class="w-5 h-5 font-bold text-center">T</div>
        <div v-else-if="type == 'number'" class="w-5 h-5 font-bold text-center">123</div>
        <div
          v-else-if="type == 'text_html'"
          class="w-5 h-5 font-bold text-center"
          style="font-size: 8px; margin-left: -3px;"
        >
          HTML
        </div>
        <div v-else-if="type == 'identity_doc'" class="w-5 h-5 font-bold text-center">ID</div>
      </div>
      <div class="flex-1">
        <input
          v-if="editable"
          class="w-full py-1.5 text-gray-800 outline-none px-2.5 text-base bg-white border border-gray-200 shadow-sm rounded-md font-medium"
          type="text"
          placeholder="Nome do campo"
          :value="name"
          @input="$emit('input', $event.target.value)"
        />
        <div v-else class="px-1">{{ name ? name : 'Sem nome' }}</div>
      </div>
      <fw-tag :type="required ? 'light' : 'light-gray'">
        {{ required ? 'Obrigatório' : 'Opcional' }}
      </fw-tag>
      <b-dropdown v-if="editable" aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
          <fw-button size="sm" :class="{ 'opacity-100': active }">
            <fw-icon-more class="w-5 h-5" />
          </fw-button>
        </template>
        <b-dropdown-item v-if="!required" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            label="Marcar como obrigatório"
            :icon="'checkbox-circle'"
            @click.native="$emit('set-required', true)"
          >
            Marcar como obrigatório
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="required" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            label="Marcar como opcional"
            :icon="'checkbox-circle-blank'"
            @click.native="$emit('set-required', false)"
          >
            Marcar como opcional
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="canMoveUp" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            label="Mover para cima"
            :icon="'chevron-up'"
            @click.native="$emit('move-up')"
          >
            Mover para cima
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="canMoveDown" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            label="Mover para baixo"
            :icon="'chevron-down'"
            @click.native="$emit('move-down')"
          >
            Mover para baixo
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item paddingless aria-role="listitem">
          <fw-button type="simple" size="sm" label="Eliminar" :icon="'trash'" @click.native="$emit('delete')">
            Eliminar
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="type == 'select' || type == 'multiple_choice'" class="flex flex-col pl-11">
      <div class="text-sm text-gray-500 pb-1.5">Opções</div>
      <div class="flex flex-col gap-1">
        <div v-for="(option, index) in options" :key="index" class="flex items-center gap-1.5">
          <div class="flex-1">
            <input
              class="w-full py-1.5 text-gray-800 outline-none px-2.5 text-base bg-white border border-gray-200 shadow-sm rounded-md font-medium"
              type="text"
              placeholder="Nome da opção"
              :value="option.text[language]"
              @input="$emit('update-option', { index, value: $event.target.value })"
            />
          </div>
          <b-dropdown aria-role="list" position="is-bottom-left">
            <template #trigger="{ active }">
              <fw-button size="sm" :class="{ 'opacity-100': active }">
                <fw-icon-more class="w-5 h-5" />
              </fw-button>
            </template>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button
                type="simple"
                size="sm"
                label="Eliminar"
                :icon="'trash'"
                @click.native="$emit('delete-option', index)"
              >
                Eliminar
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <fw-button type="simple" size="sm" label="Adicionar opção" :icon="'plus'" @click.native="$emit('add-option')">
        Adicionar opção
      </fw-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MiniPlaceholder',
  props: {
    type: {
      type: String,
      default: 'text_input',
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    canMoveUp: {
      type: Boolean,
      default: true,
    },
    canMoveDown: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: 'pt',
    },
  },
  data() {
    return {
      type_labels: {
        text_input: 'Texto simples',
        number_input: 'Número',
        date_input: 'Data',
        country_input: 'País',
        address_input: 'Morada',
        custom_object: 'Objeto personalizado',
        text_area: 'Área de texto',
        multiple_choice: 'Escolha múltipla',
        upload_file: 'Ficheiro',
        email: 'Email',
        phone_number: 'Número de telefone',
        select: 'Lista de seleção',
      },
    }
  },
}
</script>
