<template>
  <div class="flex gap-5 w-full">
    <PlaceholderBase class="flex-1" :label="country[language]">
      <div class="placeholder-input">
        {{ country_placeholder[language] }}
        <fw-icon-chevron-up class="absolute top-1.5 right-2 h-5 w-5" />
        <fw-icon-chevron-down class="absolute bottom-1.5 right-2 h-5 w-5" />
      </div>
    </PlaceholderBase>
  </div>
</template>

<script>
import PlaceholderBase from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderBase'
export default {
  name: 'PlaceholderTextInput',
  components: {
    PlaceholderBase,
  },
  props: {
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Insira o número do documento',
          en: 'Enter here the document number',
        }
      },
    },
    language: {
      type: String,
      default: 'pt',
    },
  },
  data() {
    return {
      country: {
        pt: 'País',
        en: 'Country',
      },
      country_placeholder: {
        pt: 'Selecione o país',
        en: 'Select the country',
      },
    }
  },
}
</script>
