<template>
  <fw-layout-main-content>
    <template v-if="topics.length > 0" #sidebar>
      <div class="pl-4 py-2 flex items-center">
        <div class="flex-1">
          <fw-heading size="h4" muted :counter="pagination.total_items">Tópicos</fw-heading>
        </div>
        <div class="min-h-10">
          <b-dropdown v-if="topics.length > 0" aria-role="list" position="is-bottom-left">
            <template #trigger="{ active }">
              <fw-button size="sm">
                <fw-icon-more class="w-5 h-5" :class="{ 'opacity-50': active }" />
              </fw-button>
            </template>
            <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click="showNewTopic = true">
              Criar novo tópico
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="flex flex-1 flex-col gap-2 overflow-y-auto">
        <div v-if="loading" class="px-2 flex flex-col gap-0.5">
          <div class="bg-gray-200 bg-opacity-50 h-10 rounded-lg animate-pulse"></div>
          <div class="bg-gray-200 bg-opacity-50 h-10 rounded-lg animate-pulse"></div>
          <div class="bg-gray-200 bg-opacity-50 h-10 rounded-lg animate-pulse"></div>
          <div class="bg-gray-200 bg-opacity-50 h-10 rounded-lg animate-pulse"></div>
          <div class="bg-gray-200 bg-opacity-50 h-10 rounded-lg animate-pulse"></div>
        </div>
        <Container
          v-else-if="!loading && topics.length > 0"
          group-name="topics"
          drop-class="card-ghost-drop"
          :drop-placeholder="dropPlaceholderOptions"
          :should-animate-drop="() => true"
          :get-child-payload="index => getChildPayload(index)"
          drag-handle-selector=".drag-handle-new"
          class="flex flex-col gap-1"
          @drop="onDropSection($event)"
        >
          <Draggable
            v-for="(topicData, index) in topics"
            :key="'input_' + topicData.key"
            class="relative box-question group"
          >
            <div class="drag-handle-new top-2.5 opacity-50 group-hover:opacity-100">
              <fw-icon-draggable class="w-5 h-5" />
            </div>
            <RecordTopicNew :data="topicData" :index="index" :selected="topicId" @click.native="openTopic(topicData)" />
          </Draggable>
        </Container>
      </div>
      <BlockPagination
        v-if="pagination && pagination.total_pages > 1"
        :total="pagination.total_items"
        :total-pages="pagination.total_pages"
        :current.sync="page"
        @page-changed="pageChanged"
      />
    </template>

    <template #default>
      <LoadingPlaceholder v-if="loading" />
      <div v-else-if="currentTopic != null">
        <div class="flex items-center">
          <fw-heading size="h3" muted>
            <div class="flex items-center gap-2">{{ currentTopic.title }}</div>
          </fw-heading>
          <div class="flex-1"></div>
          <b-dropdown aria-role="list" position="is-bottom-left">
            <template #trigger="{ active }">
              <fw-button size="sm">
                <fw-icon-more class="w-6 h-6" :class="{ 'opacity-50': active }" />
              </fw-button>
            </template>
            <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click="editContent">
              Editar conteúdo
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click="changeTopicName">
              Mudar nome do tópico
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click="deleteTopic">
              Eliminar tópico
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <PanelPagePreview v-if="currentTopic.page_key" :key="currentTopic.page_key" :page-id="currentTopic.page_key" />
      </div>
      <fw-panel-info v-else type="basic" centered class="m-4 h-full flex justify-center items-center">
        <div class="flex items-center justify-center mb-3">
          <fw-icon-list-view class="w-10 h-10 opacity-70" />
        </div>
        <div>
          <div v-if="!topics.length">Não foram definidos tópicos para este módulo.</div>
          <div v-else>Escolha um tópico para visualizar o seu conteúdo.</div>
          <div v-if="!topics.length" class="flex items-center justify-center mt-3">
            <fw-button type="xlight" @click.native="showNewTopic = true">Criar novo tópico</fw-button>
          </div>
        </div>
      </fw-panel-info>

      <fw-modal v-if="showNewTopic" :active.sync="showNewTopic" :can-cancel="true" size="min" @close="close">
        <template #default>
          <ModalNewTopic @new="createNewTopic($event)" @cancel="close()" />
        </template>
      </fw-modal>
      <fw-modal
        v-if="showChangeOrderModal"
        :active.sync="showChangeOrderModal"
        :can-cancel="true"
        size="min"
        @close="close"
      >
        <template #default>
          <ModalChangeOrder :content="topics" :endpoint="changeOrder" @saved="savedOrder($event)" @close="close()" />
        </template>
      </fw-modal>
    </template>
  </fw-layout-main-content>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CoursesServices from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import RecordTopicNew from '@/fw-modules/fw-core-vue/courses/components/records/RecordTopicNew'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import ModalNewTopic from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewTopic'
import ModalChangeOrder from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalChangeOrder'
import PanelPagePreview from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagePreview'
import { Container, Draggable } from 'vue-dndrop'

export default {
  name: 'PanelCourseClasses',
  components: {
    LoadingPlaceholder,
    BlockPagination,
    RecordTopicNew,
    ModalNewTopic,
    ModalChangeOrder,
    PanelPagePreview,
    Container,
    Draggable,
  },

  props: {
    course: {
      type: Object,
      required: true,
    },
    edition: {
      type: Object,
      required: true,
    },
  },

  data: function() {
    return {
      showNewModuleModal: false,
      loading: false,
      topics: [],
      pagination: {
        active_limit: 30,
        current_page: 1,
        total_items: 0,
        total_pages: 1,
      },
      page: 1,
      showNewTopic: false,
      showChangeOrderModal: false,
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
    }
  },

  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    canCreateModules() {
      return true
    },
    courseKey() {
      return this.course.key
    },
    editionKey() {
      return this.edition.key
    },
    moduleId() {
      return this.$route.params.moduleId
    },
    topicId() {
      return this.$route.params.topicId
    },
    currentTopic() {
      return this.topics.find(t => t.key === this.topicId)
    },
  },

  created() {
    this.loadTopics()
  },

  methods: {
    onDropSection(dropResult) {
      console.log('onDropSection', dropResult)
      this.changeOrder(dropResult.payload.key, dropResult.addedIndex)
      this.topics = this.applyDrag(this.topics, dropResult)
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    getChildPayload(index) {
      //console.log('getChildPayload', p, s, index)
      return this.topics[index]
    },
    editContent() {
      this.$router.push({
        name: 'content-pages-editor',
        params: {
          key: this.currentTopic.page_key,
        },
      })
    },
    async deleteTopic() {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que deseja eliminar este tópico?',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
        onConfirm: async () => {
          try {
            let result = await CoursesServices.deleteTopic(this.courseKey, this.editionKey, this.moduleId, this.topicId)
            console.log('deleteTopic', result)
            this.loadTopics()
          } catch (e) {
            console.error(e)
            this.$buefy.dialog.alert({
              message: 'Ocorreu um erro ao eliminar o tópico.',
              type: 'is-danger',
              hasIcon: false,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true,
            })
          }
        },
      })
    },
    async changeTopicName() {
      this.$buefy.dialog.prompt({
        message: 'Editar nome do tópico',
        inputAttrs: {
          value: this.currentTopic.title,
          maxlength: 200,
          required: true,
        },
        onConfirm: async value => {
          try {
            let result = await CoursesServices.updateTopic(
              this.courseKey,
              this.editionKey,
              this.moduleId,
              this.topicId,
              {
                title: value,
              }
            )
            console.log('changeTopicName', result)
            this.topics = this.topics.map(item => {
              if (item.key === this.topicId) {
                item.title = value
              }
              return item
            })
          } catch (e) {
            console.error(e)
            this.$buefy.dialog.alert({
              message: 'Ocorreu um erro ao guardar o nome do tópico.',
              type: 'is-danger',
              hasIcon: false,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true,
            })
          }
        },
      })
    },
    async changeOrder(topicKey, index) {
      console.log('changeOrder', topicKey, index)
      try {
        let result = await CoursesServices.changeOrderTopic(
          this.courseKey,
          this.editionKey,
          this.moduleId,
          topicKey,
          index
        )
        console.log('changeOrder', result)
        this.$buefy.snackbar.open({
          message: 'Tópicos reordenados com sucesso.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 3000,
        })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao guardar a ordem dos tópicos.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },
    savedOrder(data) {
      console.log('savedOrder', data)
      this.topics = data
    },
    close() {
      this.showNewModuleModal = false
      this.showNewTopic = false
      this.showChangeOrderModal = false
    },
    createNewTopic(data) {
      console.log(data)
      CoursesServices.createTopic(this.courseKey, this.editionKey, this.moduleId, {
        title: data.name,
        type: data.type,
      })
        .then(response => {
          console.log(response)
          this.topics.push(response.data)
          this.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
    /*async createNewModule(data) {
      try {
        let result = await CoursesServices.createUnit(this.courseKey, this.editionKey, {
          title: data.name,
        })
        console.log('createNewModule', result)
        this.results.push(result.data)
        this.close()
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar o módulo.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },*/
    /*async loadModules() {
      this.loading = true
      try {
        let data = await CoursesServices.getUnits(this.courseKey, this.editionKey)
        console.log('load modules', data)
        this.results = data.data.units
        this.pagination = data.data.pagination
      } finally {
        this.loading = false
      }
    }*/
    async loadTopics() {
      this.loading = true
      try {
        let data = await CoursesServices.getTopics(this.courseKey, this.editionKey, this.moduleId, this.page)
        console.log('loadTopics', data)
        this.topics = data.data.topics
        this.pagination = data.data.pagination
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao carregar os tópicos.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      } finally {
        this.loading = false
      }
    },
    pageChanged(page) {
      this.page = page
      this.loadTopics()
    },
    openTopic(topic) {
      this.$router.push({
        name: 'course-manage-edition-module-topic',
        params: {
          id: this.courseKey,
          editionId: this.editionKey,
          view: 'modules',
          moduleId: this.moduleId,
          topicId: topic.key,
        },
      })
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "modules": "Units"
    },
    "pt": {
      "modules": "Módulos"
    }
  }
</i18n>

<style scoped>
.drag-handle-new {
  @apply h-6 w-6 rounded-lg items-center flex justify-center text-center absolute left-3 top-3.5 z-10;
  cursor: grab;
}
</style>
