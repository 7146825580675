<template>
  <fw-panel title="Configurações" featured class="p-5">
    <fw-panel v-if="allowMeetingsFeature" title="Alocação de participantes online" boxed shadow>
      <b-switch v-model="edition.allow_meetings.active" @input="updateAllowMeetings('active')">
        {{ edition.allow_meetings.active ? 'Ativo' : 'Inativo' }}
      </b-switch>
      <div v-if="allowMeetingsActive" class="mt-5">
        <div class="max-w-prose mb-10">
          <fw-label>Percentagem de lugares a ocupar na sala de aula (presencial)</fw-label>
          <div class="flex gap-2 items-center">
            <div class="text-xl font-bold text-primary p-2 w-16">{{ edition.allow_meetings.percentage }}%</div>
            <div class="flex-1">
              <b-slider
                v-model="edition.allow_meetings.percentage"
                type="is-dark"
                indicator
                :min="50"
                :max="100"
                :step="10"
              >
                <template v-for="val in [50, 60, 70, 80, 90, 100]">
                  <b-slider-tick :key="val" :value="val" class="mt-2">{{ val }}%</b-slider-tick>
                </template>
              </b-slider>
            </div>
          </div>
          <div class="mt-5">
            <div v-if="previousAllowMeetingsPercentage != edition.allow_meetings.percentage">
              <fw-button type="primary" @click.native="updateAllowMeetings('percentage')">
                Guardar e realocar estudantes
              </fw-button>
              <div class="text-sm text-gray-500 mt-2">
                Ao alterar a percentagem, a plataforma irá executar o algoritmo de alocação em todas as próximas aulas,
                para determinar quais os estudantes que devem participar remotamente.
              </div>
            </div>
          </div>
        </div>

        <fw-panel
          title="Estudantes autorizados"
          subtitle="Estudantes com autorização a participar online nas aulas da turma."
          :counter="editionAllowMeetingsUsers.length"
          :section="false"
        >
          <template v-if="editionAllowMeetingsUsers.length" #toolbar>
            <fw-button type="link" @click.native="showAddForm = true">Adicionar</fw-button>
          </template>
          <div
            v-if="editionAllowMeetingsUsers.length"
            class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-2"
          >
            <fw-card
              v-for="student in editionAllowMeetingsUsers"
              :key="student.user.key"
              no-clamp
              no-link
              :title="student.user.full_name"
            >
              <template #header>
                <fw-avatar size="md" :user="student.user" />
              </template>
              <template #main>
                <div class="text-sm text-gray-500">{{ student.user.number }}</div>
                <fw-label class="mt-2">Detalhes da autorização</fw-label>
                <div v-for="date in student.dates" :key="date.key" class="text-sm font-medium text-center">
                  <div class="mb-2">
                    <div>{{ date.start_date }} a {{ date.end_date }}</div>
                    <div v-if="date.description" class="text-gray-500">{{ date.description }}</div>
                  </div>
                  <div class="flex items-center justify-center">
                    <fw-button type="light" size="sm" @click.native="deleteUser(student.key, date.key)"
                      >Remover</fw-button
                    >
                  </div>
                </div>
              </template>
            </fw-card>
          </div>
          <div v-else class="flex items-center justify-center p-5 flex-col gap-2">
            <div class="text-gray-500 text-sm">
              Ainda não foram definidas autorizações.
            </div>
            <fw-button type="link" @click.native="showAddForm = true">Nova autorização</fw-button>
          </div>
        </fw-panel>

        <!-- Modal add user -->
        <b-modal
          v-if="showAddForm"
          :can-cancel="true"
          trap-focus
          :active.sync="showAddForm"
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          scroll="keep"
          :width="360"
          custom-class="modal-add-user"
        >
          <div class="px-5 pt-3 pb-5">
            <div class="flex items-center justify-between mb-4">
              <fw-label>Nova autorização</fw-label>
              <fw-button type="link" size="sm" @click.native="showAddForm = false">Fechar</fw-button>
            </div>
            <form class="p-4" @submit.prevent="addUser" @keyup.enter="addUser">
              <b-field v-if="formAddErrorMessage" class="notification is-danger has-padding">
                {{ formAddErrorMessage }}
              </b-field>
              <b-field label="Estudante">
                <b-select v-model="formAddStudentKey">
                  <option v-for="student in edition.students" :key="student.key" :value="student.key">
                    {{ student.user.full_name }} - {{ student.user.number }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Data início">
                <b-datepicker v-model="formAddStartDate"></b-datepicker>
              </b-field>
              <b-field label="Data fim">
                <b-datepicker v-model="formAddEndDate"></b-datepicker>
              </b-field>
              <b-field label="Notas adicionais">
                <b-input v-model="formAddDescription"></b-input>
              </b-field>
            </form>
            <div class="mt-5 flex justify-end items-center gap-2">
              <fw-button type="link-muted" @click.native="showAddForm = false">Cancelar</fw-button>
              <fw-button
                :type="!formAddIsReady ? 'disabled' : 'regular'"
                :disabled="!formAddIsReady"
                :class="{ 'is-primary': formAddIsReady }"
                @click.native="addUser"
              >
                Adicionar
              </fw-button>
            </div>
          </div>
        </b-modal>
      </div>
      <div v-else class="text-sm py-5 text-gray-500 max-w-prose">
        A alocação automática de participantes online está desligada. Todos os estudantes inscritos na turma estão
        autorizados a entrar na sala virtual de qualquer aula. Os estudantes não inscritos ou outros participantes
        precisam de autorização para entrar.
      </div>
    </fw-panel>
    <div v-else>
      Esta turma ainda não tem opções de configurações disponíveis para apresentar.
    </div>
  </fw-panel>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import FwAvatar from '../../../ui/components/FwAvatar.vue'

export default {
  components: { FwAvatar },
  props: {
    edition: Object,
  },

  data() {
    const usersRef = {}
    for (let student of this.edition.students) {
      usersRef[student.user.key] = student.user
    }

    return {
      loading: false,
      showAddForm: false,
      formAddStudentKey: null,
      formAddStartDate: null,
      formAddEndDate: null,
      formAddDescription: null,
      formAddErrorMessage: '',
      usersRef: usersRef,
      previousAllowMeetingsPercentage: this.edition.allow_meetings ? this.edition.allow_meetings.percentage : null,
    }
  },

  computed: {
    allowMeetingsFeature() {
      return Boolean(this.edition.allow_meetings_feature && this.edition.students.length)
    },
    allowMeetingsActive() {
      return Boolean(this.allowMeetingsFeature && this.edition.allow_meetings.active)
    },
    formAddIsReady() {
      return Boolean(this.formAddStudentKey && this.formAddStartDate && this.formAddEndDate)
    },
    editionAllowMeetingsUsers() {
      const users = []
      if (this.edition.allow_meetings.users && this.edition.allow_meetings.users.length) {
        for (let userInfo of this.edition.allow_meetings.users) {
          const user = this.usersRef[userInfo.user_key]
          if (user) {
            users.push({
              key: userInfo.user_key,
              user: user,
              dates: userInfo.dates,
            })
          }
        }
      }
      users.sort((a, b) => a.user.name.localeCompare(b.user.name))
      return users
    },
  },
  methods: {
    async updateTeacherClass(data) {
      const response = await ServiceAcademic.updateTeacherClass(this.edition.key, data)
      this.$emit('update-edition', response)
    },
    async updateAllowMeetings(key) {
      const data = {}
      data[`allow_meetings_${key}`] = this.edition.allow_meetings[key]
      await this.updateTeacherClass(data)

      if (key == 'percentage') {
        this.previousAllowMeetingsPercentage = this.edition.allow_meetings.percentage
      }
    },
    async addUser() {
      try {
        const data = {
          allow_meetings_student_key: this.formAddStudentKey,
          allow_meetings_start_date: Dates.toDateString(this.formAddStartDate),
          allow_meetings_end_date: Dates.toDateString(this.formAddEndDate),
          allow_meetings_description: this.formAddDescription,
        }
        await this.updateTeacherClass(data)

        this.formAddErrorMessage = ''
        this.showAddForm = false
        this.formAddStudentKey = ''
        this.formAddStartDate = null
        this.formAddEndDate = null
        this.formAddDescription = null
      } catch (error) {
        const errors = utils.errors(error)
        const errorKey = errors.getKey()
        if (errorKey == 'ItemNotFound') {
          this.formAddErrorMessage = 'O estudante não existe'
        } else {
          this.formAddErrorMessage = errors.getTranslated()
          if (!this.formAddErrorMessage) {
            this.formAddErrorMessage = `Não foi possível adicionar o estudante. Por favor, tente mais tarde.
              Caso o problema persista, contacte-nos através do nosso canal de suporte.`
          }
        }
      }
    },
    async deleteUser(userKey, dateKey) {
      const data = {
        allow_meetings_delete_user_key: userKey,
        allow_meetings_delete_date_key: dateKey,
      }
      await this.updateTeacherClass(data)
    },
  },
}
</script>

<style lang="scss">
.modal-add-user .modal-content {
  overflow: visible;
}
</style>
