<template>
  <DashboardCard :type="cardType" class="pt-2 pb-3 px-3" @click.native="$emit('open-user', activity.by_user_key)">
    <template #header>
      <div class="text-white text-opacity-60 flex text-xs gap-2 items-center">
        <fw-icon-alarm v-if="cardType === 'warning'" class="h-4 w-4 text-white"></fw-icon-alarm>
        <div class="font-semibold ">
          {{ activityType }}
        </div>
        <div class="flex-1"></div>
        <div>
          {{ activity.created_date | formatDateTime }}
        </div>
      </div>
    </template>
    <div class="flex gap-1.5 items-center">
      <fw-avatar v-if="person !== null" class="mr-1.5 flex-shrink-0" size="sm" :user="person" />
      <div class="text-white text-sm leading-4">
        <div v-if="person !== null" class="font-bold mb-0.5 overflow-hidden whitespace-nowrap">
          {{ person.name }}
        </div>
        <div v-else class="text-sm text-gray-400">
          Utilizador desconhecido
        </div>
        <div>
          {{ activityMessage }}
        </div>
      </div>
    </div>
  </DashboardCard>
</template>
<script>
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import DashboardCard from './DashboardCard.vue'
export default {
  name: 'ActivityCard',
  components: {
    DashboardCard,
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
    language: {
      type: String,
      default: 'pt',
    },
  },
  data() {
    return {
      warningCodes: FormServices.activityAlarmCodes(),
      frontendCodes: FormServices.frontendActivityCodes(),
    }
  },
  computed: {
    cardType() {
      return this.activity.code == 'reopen' && this.activity.context == 'answer'
        ? 'warning'
        : this.warningCodes.includes(this.activity.code)
        ? 'warning'
        : this.activity.code == 'withdraw' && this.activity.context == 'answer'
        ? 'attention'
        : (this.activity.code == 'closed' || this.activity.code == 'close') && this.activity.context == 'answer'
        ? 'attention'
        : 'normal'
    },
    activityMessage() {
      let context = this.frontendCodes.includes(this.activity.code) ? 'frontend' : this.activity.context
      let message =
        this.activity.message !== null && typeof this.activity.message !== 'undefined'
          ? this.activity.message
          : this.activityMessages[context] &&
            this.activityMessages[context][this.activity.code] &&
            this.activityMessages[context][this.activity.code][this.language]
          ? this.activityMessages[context][this.activity.code][this.language]
          : ''
      return message
      /* this.activity.code == 'start'
        ? 'Gestão: Iniciou o exame'
        : this.activity.code == 'leave-window'
        ? 'Saiu da janela do exame'
        : this.activity.code == 'copy'
        ? 'Usou funcionalidade de cópia'
        : this.activity.code == 'paste'
        ? 'Usou funcionalidade de colar'
        : this.activity.code == 'leaving-full-screen'
        ? 'Desativou ecrã inteiro'
        : this.activity.code == 'enter-full-screen'
        ? 'Ativou ecrã inteiro'
        : this.activity.code == 'enter-window'
        ? 'Voltou à janela do exame'
        : this.activity.code == 'stop'
        ? 'Gestão: Terminou o exame'
        : this.activity.code == 'enter-exam'
        ? 'Abriu o exame'
        : this.activity.code == 'reopen' && this.activity.context == 'answer'
        ? 'Reabriu o exame'
        : this.activity.code == 'withdraw' && this.activity.context == 'answer'
        ? 'Desistiu do exame'
        : this.activity.code == 'open' && this.activity.context == 'answer'
        ? 'Iniciou o exame'
        : (this.activity.code == 'closed' || this.activity.code == 'close') && this.activity.context == 'answer'
        ? 'Entregou o exame'
        : this.activity.code == 'new' && this.activity.context == 'page-answer'
        ? 'Carregou página do exame'
        :
        FRONTEND CODES
        this.activity.code == 'timeover-warning-ack'
        ? 'Reconheceu o aviso de que o tempo está a esgotar-se'
        : this.activity.code == 'answer-question'
        ? 'Respondeu a uma questão'
        : this.activity.code == 'exit-exam'
        ? 'Saiu do exame'
        : this.activity.code == 'update'
        ? 'Gestão: Atualizou o exame'
        : this.activity.code == 'publish'
        ? 'Gestão: Publicou o exame'
        : this.activity.code == 'new'
        ? 'Gestão: Criou o exame'
        : 'Atividade sem mensagem' */
    },
    activityType() {
      return 'Estado'
    },
    person() {
      return this.users[this.activity.by_user_key] ?? null
    },
    activityMessages() {
      return FormServices.activityMessages()
    },
  },
}
</script>
