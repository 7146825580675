<template>
  <div>
    <div>
      <div>
        <fw-heading size="h3" muted>Curso digital</fw-heading>
        <fw-heading size="h1">{{ course.title }}</fw-heading>
      </div>
      <div
        v-if="!loading && !editions.length"
        class="text-center flex flex-col items-center justify-center bg-gray-200 rounded-xl px-5 py-10 my-5 bg-opacity-80"
      >
        <div class="mb-2"><fw-icon-cube class="h-14 w-14 text-gray-400" /></div>
        <div class="text-sm font-medium text-gray-500">Ainda não existem edições registadas neste curso.</div>
        <div class="mt-3"><fw-button type="2xlight" @click.native="newEdition()">Criar nova edição</fw-button></div>
      </div>
    </div>

    <fw-panel v-if="!loading && editions.length" title="Edições" class="my-5" :counter="editions.length">
      <template #default>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <CardManageCourseEdition v-for="edition in editions" :key="edition.key" :edition="edition" :course="course" />
          <div class="p-10 rounded-xl flex justify-center items-center border-2 border-dashed border-gray-300">
            <fw-button type="xlight" @click.native="newEdition()">Criar nova edição</fw-button>
          </div>
        </div>
      </template>
    </fw-panel>

    <fw-modal
      v-if="showNewEditionModal"
      :active.sync="showNewEditionModal"
      :can-cancel="true"
      size="min"
      @close="close"
    >
      <template #default>
        <ModalNewEdition @new="createNewEdition($event)" @cancel="close()" />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import CardManageCourseEdition from '@/fw-modules/fw-core-vue/courses/components/cards/manage/CardManageCourseEdition'
import ServicesCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ModalNewEdition from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewEdition'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    CardManageCourseEdition,
    ModalNewEdition,
  },

  props: {
    course: Object,
  },

  data() {
    return {
      editions: [],
      showNewEditionModal: false,
      loading: true,
      results: [],
      pagination: {},
    }
  },

  computed: {
    backgroundBannerImage() {
      return this.course.banner ? ServiceStorage.getImageViewUrl(this.course.banner) : null
    },
    statsParticipants() {
      return this.course.stats ? this.course.stats.students : 0
    },
    statsEditions() {
      return this.course.stats ? this.course.stats.edition : 0
    },
  },

  created() {
    this.getEditions()
  },

  methods: {
    async getEditions() {
      utils.tryAndCatch(
        this,
        async () => {
          const response = await ServicesCourses.getEditions(this.course.key)
          this.editions = response.data.editions
        },
        () => {
          this.loading = false
        }
      )
    },
    async createNewEdition(data) {
      utils.tryAndCatch(this, async () => {
        let result = await ServicesCourses.createEdition(this.course.key, {
          title: data.name,
        })

        console.log('createNewCourseEdition', result)

        this.$router.push({
          name: 'course-manage-edition',
          params: { id: this.course.key, editionId: result.data.key },
        })
      })
    },
    openEdition(edition) {
      this.$router.push({ name: 'course-manage-edition', params: { id: this.course.key, editionId: edition.key } })
    },
    close() {
      this.showNewEditionModal = false
    },
    newEdition() {
      this.showNewEditionModal = true
    },
  },
}
</script>
