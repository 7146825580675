<template>
  <fw-layout :back-to-enable="false" mobile-ready footer>
    <template #main-content>
      <PanelUCIdHero
        :user="user"
        class="mt-5 mb-10"
        version="v2"
        :stats="stats"
        :stats-loading="loading['stats']"
        :stats-hide-if-empty="true"
      />
      <fw-panel title="Acesso rápido">
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 justify-center mb-10 items-stretch">
          <button-quick-access
            v-if="meeting && meeting.key"
            :label="meeting.running ? 'Sala aberta' : 'Sala fechada'"
            title="Sala Pessoal"
            :footer="meeting.key_alias"
            @clicked="$router.push({ name: 'live', params: { key: meeting.key_alias } })"
          />
          <button-quick-access-enter-room />
          <button-quick-access
            label="Lecionação"
            title="Disciplinas e turmas"
            @clicked="$router.push({ name: 'units' })"
          />
          <button-quick-access label="Recursos" title="Ficheiros" @clicked="$router.push({ name: 'buckets' })" />
          <button-quick-access
            label="Versão beta"
            title="Exames"
            tag="NOVO"
            tag-type="primary"
            @clicked="$router.push({ name: 'exams' })"
          />
          <button-quick-access
            v-if="isBetaOrDebugModeActive"
            label="Versão ALPHA"
            title="Cursos digitais"
            tag="TESTING"
            tag-type="primary"
            @clicked="$router.push({ name: 'courses-manage' })"
          />
          <button-quick-access label="Comunicação" title="Grupos" @clicked="$router.push({ name: 'groups' })" />
          <button-quick-access label="Comunicação" title="Canais" @clicked="$router.push({ name: 'chats' })" />
          <button-quick-access label="Ferramentas" title="Gravações" @clicked="$router.push({ name: 'recordings' })" />
          <button-quick-access label="Suporte" title="Precisa de ajuda?" @clicked="$router.push('/support')" />
        </div>
      </fw-panel>
      <PanelNextClasses />
      <PanelCoursesTransitionOldVersion
        v-if="hasCourses || hasNonDegreeCourses"
        :loading="loadingEnrolledCourses || loadingEnrolledNDCourses"
        title="Os meus cursos"
        :courses="enrolledCourses"
        :old-courses="enrolledNonDegreeCourses"
      />
    </template>
  </fw-layout>
</template>

<script>
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import ButtonQuickAccessEnterRoom from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccessEnterRoom'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'

import PanelCoursesTransitionOldVersion from '@/fw-modules/fw-core-vue/courses/components/panels/PanelCoursesTransitionOldVersion'
import PanelNextClasses from '@/fw-modules/fw-core-vue/academic/components/panels/PanelNextClasses'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
export default {
  components: {
    PanelUCIdHero,
    PanelNextClasses,
    PanelCoursesTransitionOldVersion,
    ButtonQuickAccess,
    ButtonQuickAccessEnterRoom,
  },

  data() {
    let debug = Boolean(localStorage.getItem('fw-debug'))
    this.loadEnrolledNonDegreeCourses()
    this.loadEnrolledCourses()

    return {
      debugMode: debug,
      enrolledNonDegreeCourses: [],
      loadingEnrolledNDCourses: false,
      loadingEnrolledCourses: false,
      enrolledCourses: [],
      loading: {
        stats: true,
      },
      stats: [
        {
          key: 'academic-units',
          label: 'Disciplinas',
          value: '0',
          valueSuffix: null,
          featured: false,
        },
        {
          key: 'academic-classes',
          label: 'Turmas',
          value: '0',
          valueSuffix: null,
          featured: false,
        },
        {
          key: 'academic-students',
          label: 'Estudantes',
          value: '0',
          valueSuffix: null,
          featured: false,
        },
        {
          key: 'academic-next_sessions',
          label: 'Aulas previstas',
          value: '0',
          valueSuffix: null,
          featured: false,
          description: null,
        },
        {
          key: 'academic-done_sessions',
          label: 'Aulas dadas',
          value: '0',
          valueSuffix: null,
          featured: false,
        },
        {
          key: 'meetings-meetings',
          label: 'Sessões virtuais',
          value: '0',
          valueSuffix: null,
          featured: false,
          description: null,
        },
        {
          key: 'meetings-hours',
          label: 'Horas virtuais',
          value: '0',
          valueSuffix: 'h',
          featured: false,
          description: null,
        },
      ],
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    meeting() {
      if (this.user && this.user.meeting) return this.user.meeting
      else return null
    },
    hasNonDegreeCourses() {
      return !!this.enrolledNonDegreeCourses.length
    },
    hasCourses() {
      return !!this.enrolledCourses.length
    },
    isBetaOrDebugModeActive() {
      return localStorage.getItem('beta') === 'true' || localStorage.getItem('fw-debug') === 'true'
    },
  },

  mounted() {
    this.$store.dispatch('setUser')
    ServiceSettings.checkAppVersion()
    this.loadStats()
  },

  methods: {
    async loadStats() {
      this.loading['stats'] = true
      const user = this.$store.getters.getUser
      if (!user || !user.key) return

      const stats = {}
      if (user.roles.includes('teacher')) {
        try {
          const academicStats = await ServiceAcademic.getTeacherStats()
          for (let [key, value] of Object.entries(academicStats)) {
            stats[`academic-${key}`] = value
          }
        } catch (error) {
          console.error('Failed to get academic stats', error)
        }
      }

      try {
        const meetingStats = await ServiceMeetings.getStats()
        for (let [key, value] of Object.entries(meetingStats)) {
          if (key == 'attend_seconds') stats['meetings-hours'] = Math.round(value / 60 / 60)
          else stats[`meetings-${key}`] = value
        }
      } catch (error) {
        console.error('Failed to get meetings stats', error)
      }

      for (const stat of this.stats) {
        const value = stats[stat.key]
        if (value) stat.value = String(value)
        if (stat.key == 'academic-done_sessions') {
          const sessions = stats['academic-sessions']
          if (sessions) stat.description = `Total de ${sessions}`
        }
      }

      this.loading['stats'] = false
    },
    async loadEnrolledNonDegreeCourses() {
      try {
        this.loadingEnrolledNDCourses = true
        const enrolledNonDegreeCoursesData = await ServiceAcademic.getUserEnrollments()
        this.enrolledNonDegreeCourses = enrolledNonDegreeCoursesData.courses
        this.loadingEnrolledNDCourses = false
      } catch (error) {
        console.error(error)
        this.loadingEnrolledNDCourses = false
      }
    },
    async loadEnrolledCourses() {
      try {
        this.loadingEnrolledCourses = true
        const enrolledCourses = await ServiceCourses.getUserEnrollments()
        console.log('enrolledCourses', enrolledCourses)
        this.enrolledCourses = enrolledCourses.courses
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingEnrolledCourses = false
      }
    },
  },
}
</script>
