<template>
  <div class="group bg-gray-200 flex-col flex p-4 rounded-xl gap-3">
    <div class="flex gap-3 justify-between items-center">
      <div>
        <fw-icon-group class="w-7 h-7" />
      </div>
      <div class="w-40">
        <ProgressIcon :progress="editionClass.progress" show-percentage percentage-pos="right" />
      </div>
    </div>
    <div class="flex-1 flex flex-col justify-end gap-2">
      <div class="font-semibold flex-1 text-lg">
        <v-clamp autoresize :max-lines="3">
          {{ editionClass.name }}
        </v-clamp>
      </div>
      <div class="opacity-70 font-semibold text-sm">
        {{ editionClass.stats.enrolled || 0 }} / {{ editionClass.stats.total || 0 }}
        {{ editionClass.stats.enrolled == 1 ? 'inscrito' : 'inscritos' }}
      </div>
    </div>
  </div>
</template>

<script>
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'

export default {
  name: 'CardCourseEditionClassProgress',

  components: {
    ProgressIcon,
  },

  props: {
    editionClass: Object,
  },

  data() {
    return {}
  },

  computed: {
    // openEntry() {
    //   return this.$router.resolve({
    //     name: 'manage-map',
    //     params: {
    //       key: this.map.key,
    //     },
    //   }).href
    // },
  },
}
</script>
