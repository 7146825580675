<template>
  <div
    class="flex px-3 cursor-pointer py-3 gap-3 my-1 relative w-full text-left group rounded-lg text-sm items-center"
    :class="{
      'bg-white shadow': data.key == selected,
      'bg-gray-300 bg-opacity-20 hover:text-gray-800': data.key !== selected,
    }"
  >
    <!-- Title -->
    <div class="flex-1">
      <div class="flex text-base gap-2 text-left items-center">
        <fw-icon-group class="text-gray-500 h-5 w-5" />
        <div>
          <v-clamp v-if="data.name != null && data.name.length > 0" autoresize :max-lines="1">
            {{ data.name }}
          </v-clamp>
          <div v-else class="text-gray-500 text-sm">Sem título</div>
        </div>
      </div>
      <div v-if="false && data.created_date" class="text-xs text-gray-500 flex gap-2 ml-7">
        <div>Criada em {{ data.created_date | formatDateTime }}</div>
      </div>
    </div>
    <!-- Stats -->
    <div v-if="data.students && data.students.total" class="flex gap-1">
      <fw-tag type="xlight" class="flex gap-1 items-center flex-shrink-0">
        <fw-icon-people class="w-3 h-3" /> <span>{{ data.students.total }}</span>
      </fw-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordClass',
  //components: { TextStatus },
  props: {
    data: {
      type: Object,
    },
    selected: {
      type: String,
      default: null,
    },
  },
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
