import ViewExams from '@/fw-modules/fw-core-vue/exams/views/ViewExams'
import ViewExamDashboardLive from '@/fw-modules/fw-core-vue/exams/views/ViewExamDashboardLive'
import ViewExamProjectionInfo from '@/fw-modules/fw-core-vue/exams/views/ViewExamProjectionInfo'
import ViewExamsBuckets from '@/fw-modules/fw-core-vue/exams/views/ViewExamsBuckets'
import ViewQuestionsBucket from '@/fw-modules/fw-core-vue/exams/views/ViewQuestionsBucket'
import ViewExamsAnswers from '@/fw-modules/fw-core-vue/exams/views/UserAnswers'
import ViewExamPreview from '@/fw-modules/fw-core-vue/exams/views/ViewExamPreview'
import ViewExamManagement from '@/fw-modules/fw-core-vue/exams/views/ViewExamManagement'

export default [
  {
    path: '/exam/:id',
    name: 'exams-manage',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'dashboard' },
  },
  {
    path: '/exam/:id/live',
    name: 'exams-manage-live',
    component: ViewExamDashboardLive,
    meta: { requiresRole: 'teacher', view: 'dashboardlive' },
  },
  {
    path: '/exam/:id/info',
    name: 'exams-manage-projection-info',
    component: ViewExamProjectionInfo,
    meta: { requiresRole: 'teacher', view: 'projectionInfo' },
  },
  {
    path: '/exam/:id/dashboard',
    name: 'exams-manage-dashboard',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'dashboard' },
  },
  {
    path: '/exam/:id/preview',
    name: 'exams-manage-preview',
    component: ViewExamPreview,
    meta: { requiresRole: 'teacher', view: 'preview' },
  },
  {
    path: '/exam/:id/editor',
    name: 'exams-manage-editor',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'editor' },
  },
  {
    path: '/exam/:id/editor/:versionid',
    name: 'exams-manage-editor-version',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'editor' },
  },
  {
    path: '/exam/:id/people',
    name: 'exams-manage-people',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'people' },
  },
  {
    path: '/exam/:id/calendar',
    name: 'exams-manage-calendar',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'calendar' },
  },
  {
    path: '/exam/:id/evaluation',
    name: 'exams-manage-evaluation',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'evaluation' },
  },
  {
    path: '/exam/:id/activity',
    name: 'exams-manage-activity',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'activity' },
  },
  {
    path: '/exam/:id/notifications',
    name: 'exams-manage-notifications',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'notifications' },
  },
  {
    path: '/exam/:id/settings/:subsettings',
    name: 'exams-manage-settings',
    component: ViewExamManagement,
    meta: { requiresRole: 'teacher', view: 'settings' },
  },
  {
    path: '/exams',
    name: 'exams',
    component: ViewExams,
  },
  {
    path: '/exams/questions/buckets',
    name: 'exams-manage-question-buckets',
    component: ViewExamsBuckets,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/exams/questions/bucket/:id',
    name: 'exams-manage-question-bucket',
    component: ViewQuestionsBucket,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/exams/new/answers/:key',
    name: 'exams-manage-answers',
    component: ViewExamsAnswers,
    meta: { requiresRole: 'teacher' },
  },
]
