<template>
  <fw-panel featured title="Metadados" sticky boxed="sm">
    <template #default>
      <div class="flex flex-col gap-4">
        <div>
          <div class="flex gap-2 items-center mb-2">
            <fw-label>Título da edição</fw-label>
          </div>
          <div>
            <div v-if="editable" class="flex items-start gap-3">
              <b-input v-model="tmpEdition.title" maxlength="150" @input="datachanged['title'] = true" />
              <fw-button :type="datachanged['title'] ? 'primary' : 'xlight'" class="mt-1" @click.native="save('title')">
                Guardar
              </fw-button>
            </div>
            <fw-heading v-else-if="edition.title && edition.title.length > 0" size="h2" muted>{{
              edition.title
            }}</fw-heading>
            <fw-panel-info v-else clean empty left>O título não foi definido.</fw-panel-info>
          </div>
        </div>
        <div>
          <div class="flex gap-2 items-center mb-2">
            <fw-label>Duração (em horas)</fw-label>
          </div>
          <div>
            <div v-if="editable" class="flex items-start gap-3">
              <b-numberinput
                v-model="tmpEdition.duration"
                min="0"
                type="is-light"
                style="max-width: 200px"
                @input="datachanged['duration'] = true"
              />
              <fw-button
                :type="datachanged['duration'] ? 'primary' : 'xlight'"
                class="mt-1"
                @click.native="save('duration')"
              >
                Guardar
              </fw-button>
            </div>
            <div v-else-if="edition.duration && edition.duration > 0">{{ edition.duration }} horas</div>
            <fw-panel-info v-else clean empty left>A duração não foi definida.</fw-panel-info>
          </div>
        </div>
        <div>
          <div class="flex gap-2 items-center mb-2">
            <fw-label>Idiomas</fw-label>
          </div>
          <div v-if="editable" class="flex items-center gap-5">
            <b-field class="mt-3">
              <b-checkbox v-model="languages.pt" @input="datachanged['languages'] = true">
                Português
              </b-checkbox>
              <b-checkbox v-if="false" v-model="languages.en" @input="datachanged['languages'] = true">
                Inglês
              </b-checkbox>
            </b-field>
            <fw-button
              :type="datachanged['languages'] ? 'primary' : 'xlight'"
              class="mt-1"
              @click.native="save('languages')"
            >
              Guardar
            </fw-button>
          </div>
          <div v-else-if="edition.languages != null && edition.languages.length > 0">
            <div v-if="edition.languages.includes('pt')">Português</div>
            <div v-if="edition.languages.includes('en')">Inglês</div>
          </div>
          <fw-panel-info v-else clean empty left>O idioma não foi definido.</fw-panel-info>
        </div>
        <div v-for="meta in metadataFields" :key="meta">
          <div class="flex gap-2 items-center mb-2">
            <fw-label>{{ $t('title.metadata.' + meta) }}</fw-label>
            <fw-tag v-if="!requiredFields.includes(meta)" type="xlight" size="xs">Opcional</fw-tag>
          </div>
          <div v-if="meta == 'files'" class="relative">
            <div v-if="editable">
              <RecordFileEntry
                v-for="(file, i) in tmpEdition.files"
                :key="file.key"
                :file="file"
                :can-edit="true"
                :can-remove="true"
                @save="saveFile(i, $event)"
                @remove="removeFile(i, $event)"
                @download="downloadFile(file)"
              />
              <div>
                <Uploader
                  class="meta-uploader cursor-pointer hover:opacity-100 opacity-70 transition-all"
                  :is-docked="true"
                  :layout="showFullDragZoneUploader ? 'full-dragzone' : 'minimal'"
                  reference-id="upload"
                  allowed="any"
                  :clear-after="true"
                  bucket-code="files"
                  bucket-type="file"
                  input-id="upload"
                  @upload="postUploadFile"
                  @dragleave="dragleave"
                />
              </div>
            </div>
            <div v-else-if="edition.files && edition.files.length > 0">
              <RecordFileEntry
                v-for="file in edition.files"
                :key="file.key"
                :file="file"
                :can-edit="false"
                :can-remove="false"
                @download="downloadFile(file)"
              />
            </div>
            <fw-panel-info v-else clean empty left>Sem ficheiros.</fw-panel-info>
          </div>
          <div v-else-if="meta == 'promoters' || meta == 'scientific_area'" class="flex gap-3">
            <div v-if="editable" class="flex items-start gap-3">
              <b-input v-model="tmpEdition[meta]" maxlength="150" @input="datachanged[meta] = true" />
              <fw-button :type="datachanged[meta] ? 'primary' : 'xlight'" class="mt-1" @click.native="save(meta)">
                Guardar
              </fw-button>
            </div>
            <div
              v-else-if="edition[meta]"
              class="content fixed-content bg-gray-100 px-4 py-3 rounded-lg"
              v-html="edition[meta]"
            />
          </div>
          <div v-else>
            <div v-if="editable">
              <HtmlEditor
                v-model="tmpEdition[meta]"
                :autofocus="false"
                editor-class="min-h-40"
                @input="datachanged[meta] = true"
              />
              <fw-button :type="datachanged[meta] ? 'primary' : 'xlight'" class="mt-1" @click.native="save(meta)">
                {{ $t('save') }}
              </fw-button>
            </div>
            <div
              v-else-if="edition[meta] && edition[meta].length > 0"
              class="content fixed-content bg-gray-100 px-4 py-3 rounded-lg"
              v-html="edition[meta]"
            />
            <fw-panel-info v-else clean empty left>Não definido.</fw-panel-info>
          </div>
        </div>
      </div>
    </template>
  </fw-panel>
</template>

<script>
import HtmlEditor from '@/fw-modules/fw-core-vue/ui/components/form/HtmlEditor'
//import BlockBanner from '@/fw-modules/fw-core-vue/courses/components/blocks/BlockBanner'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
export default {
  name: 'PanelManageCourseEditionSettings',
  components: {
    HtmlEditor,
    //BlockBanner,
    Uploader,
    RecordFileEntry,
  },
  props: {
    course: Object,
    edition: Object,
    units: Array,
    editable: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    settings: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      showFullDragZoneUploader: false,
      editing: false,
      metadataFields: [
        'summary',
        'introduction',
        'goals',
        'professional_goals',
        'prerequisites',
        'methodology',
        'scientific_area',
        'certification',
        'evaluation',
        'bibliography',
        'observations',
        'promoters',
        'files',
      ],
      requiredFields: [
        'summary',
        'goals',
        'methodology',
        'evaluation',
        'certification',
        'introduction',
        'professional_goals',
        'prerequisites',
        'observations',
        'bibliography',
        'scientific_area',
      ],
      tmpEdition: {
        title: '',
        duration: 0,
        summary: '',
        promoters: '',
        introduction: '',
        goals: '',
        professional_goals: '',
        prerequisites: '',
        methodology: '',
        scientific_area: '',
        certification: '',
        evaluation: '',
        bibliography: '',
        observations: '',
        files: [],
        languages: [],
      },
      datachanged: {
        title: false,
        duration: false,
        summary: false,
        promoters: false,
        introduction: false,
        goals: false,
        professional_goals: false,
        prerequisites: false,
        methodology: false,
        scientific_area: false,
        certification: false,
        evaluation: false,
        bibliography: false,
        observations: false,
        files: false,
        languages: false,
      },
      languages: {
        pt: false,
        en: false,
      },
    }
  },

  mounted() {
    this.tmpEdition = JSON.parse(JSON.stringify(this.edition))
    if (this.edition.languages.includes('pt')) this.languages['pt'] = true
    if (this.edition.languages.includes('en')) this.languages['en'] = true
  },

  methods: {
    dragover(event) {
      event.preventDefault()
      if (!this.showFullDragZoneUploader) {
        this.showFullDragZoneUploader = true
      }
    },
    dragleave() {
      this.showFullDragZoneUploader = false
    },
    saveFile(i, file) {
      console.log('saveFile', i, file)
      file.filename = file.title
      this.tmpEdition.files[i] = file
      this.save('files')
    },
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    removeFile(i) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover o ficheiro?',
        onConfirm: () => {
          this.tmpEdition.files.splice(i, 1)
          this.save('files')
        },
      })
    },
    postUploadFile(files) {
      console.log('postUploadFile', files)
      for (let fileEntry of files) {
        const file = fileEntry.response.data.file
        //push if not exists
        if (!this.tmpEdition.files.find(f => f.key == file.key)) {
          this.tmpEdition.files.push(file)
        }
      }
      this.showFullDragZoneUploader = false
      this.save('files')
    },
    edit() {
      this.editing = true
      //create copy of course to tmp
      if (this.edition.title) {
        this.tmpEdition.title = this.edition.title
      }
      if (this.edition.duration) {
        this.tmpEdition.duration = this.edition.duration
      }

      //loop through metadata fields and create tmpEdition
      for (let meta of this.metadataFields) {
        if (this.edition[meta] && this.edition[meta] != null) {
          if (meta == 'files' || meta == 'languages') {
            this.tmpEdition[meta] = this.edition[meta] ? this.edition[meta] : []
          } else {
            this.tmpEdition[meta] = this.edition[meta] ? this.edition[meta] : ''
          }
        }
      }
      if (this.edition.languages) {
        this.languages['pt'] = this.edition.languages.includes('pt')
        this.languages['en'] = this.edition.languages.includes('en')
      }
    },
    save(field) {
      this.editing = false
      //let data = JSON.parse(JSON.stringify(this.tmpEdition))
      let data = JSON.parse(JSON.stringify(this.edition))
      if (field == 'languages') {
        let languages = []
        if (this.languages.pt) {
          languages.push('pt')
        }
        if (this.languages.en) {
          languages.push('en')
        }
        data['languages'] = languages
      } else {
        data[field] = this.tmpEdition[field]
      }
      //save course
      this.$emit('save', data)
      this.datachanged[field] = false
    },
  },
}
</script>

<style lang="scss">
.content.fixed-content p:not(:last-child) {
  margin-bottom: 1rem;
}
.meta-uploader .file-uploads {
  @apply w-full;
}
</style>

<i18n>
{
  "en": {
    "title": {
      "metadata": {
        "summary": "Summary",
        "promoters": "Promoters",
        "introduction": "Introduction",
        "prerequisites": "Prerequisites",
        "goals": "Course Goals",
        "professional_goals": "Professional Goals",
        "certification": "Certification",
        "evaluation": "Evaluation",
        "observations": "Observations",
        "bibliography": "Bibliography",
        "methodology": "Methodology",
        "scientific_area": "Scientific Area",
        "files": "Files"
      }
    },
    "save": "Save",
    "hours": "hours",
    "cancel": "Cancel",
    "edit": "Edit",
    "save_metadata": "Guardar metadados"
  },
  "pt": {
    "title": {
      "metadata": {
        "summary": "Sumário",
        "promoters": "Patrocinadores",
        "introduction": "Introdução",
        "prerequisites": "Pré-Requisitos",
        "goals": "Objetivos do curso",
        "professional_goals": "Competências a desenvolver / Objetivos Profissionais",
        "certification": "Certificação",
        "evaluation": "Avaliação",
        "observations": "Observações",
        "bibliography": "Bibliografia",
        "methodology": "Metodologia",
        "scientific_area": "Área Científica",
        "files": "Ficheiros"
      }
    },
    "save": "Guardar",
    "hours": "horas",
    "cancel": "Cancelar",
    "edit": "Editar",
    "save_metadata": "Guardar metadados"
  }
}
</i18n>

<style>
.control button {
  margin-top: 3px;
}
</style>
