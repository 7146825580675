<template>
  <fw-layout-main-content content-max-width="max-w-xl">
    <template #sidebar>
      <div class="pl-4 py-2 flex items-center">
        <fw-heading size="h3" muted>
          <div class="flex items-center gap-2"># {{ collection ? collection.title : 'Canal' }}</div>
        </fw-heading>
        <div class="flex-1"></div>
        <fw-button type="link" @click.native="newPost()">Nova</fw-button>
      </div>
      <div class="flex flex-1 flex-col gap-2 overflow-y-auto px-3">
        <RecycleScroller
          v-if="posts && posts.length > 0"
          v-slot="{ item, index }"
          :items="posts"
          :item-size="86"
          :buffer="25"
          key-field="key"
        >
          <RecordPost :users="users" :data="item" :selected="postId" @click.native="openPost(item)" />
        </RecycleScroller>
        <div v-else class="py-5 text-center text-gray-400 text-sm">Sem publicações</div>
      </div>
    </template>
    <template #default>
      <div>
        <FwLoadingBlurPanel v-if="loading" />
        <PanelPost
          v-if="!loading && collectionId && postId"
          :id="postId"
          :key="postId"
          :collection-key="collectionId"
          @saved="postSaved($event)"
          @open="openPost($event)"
          @deleted="postDeleted($event)"
          @reset-route="resetRoute"
        />
        <div v-else-if="postId == null" class="py-20 text-center text-gray-400 text-sm">
          Selecione uma publicação
        </div>
      </div>
      <fw-modal
        v-if="showModalEditPost"
        :active.sync="showModalEditPost"
        :can-cancel="true"
        paddingless
        size="min"
        height-fit-screen
        @close="close"
      >
        <template #default>
          <BlockEditPost
            :collection-key="collectionId"
            :context="'post'"
            :post="null"
            :type="type"
            @saved="postSaved($event)"
            @close="close"
          />
        </template>
      </fw-modal>
    </template>
  </fw-layout-main-content>
</template>

<script>
import RecordPost from '@/fw-modules/fw-core-vue/courses/components/records/RecordPost'
import FwLoadingBlurPanel from '@/fw-modules/fw-core-vue/ui/components/loading/FwLoadingBlurPanel'
import PanelPost from '@/fw-modules/fw-core-vue/posts/components/panels/PanelPost'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'
import { RecycleScroller } from 'vue-virtual-scroller'
import BlockEditPost from '../../../../posts/components/blocks/BlockEditPost.vue'

export default {
  name: 'PanelCourseEditionPosts',
  components: {
    PanelPost,
    RecordPost,
    RecycleScroller,
    BlockEditPost,
    FwLoadingBlurPanel,
  },
  props: {
    course: {
      type: Object,
      default: () => ({}),
    },
    edition: {
      type: Object,
      default: () => ({}),
    },
    view: {
      type: String,
      default: 'publications',
    },
    collection: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      posts: [],
      users: {},
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 25,
      },
      showModalEditPost: false,
    }
  },
  computed: {
    type() {
      return this.view === 'publications' ? 'post' : 'discussion'
    },
    collectionName() {
      return this.collection ? ` - ${this.collection.title}` : ''
    },
    collectionId() {
      return this.$route.params.collectionId ?? null
    },
    postId() {
      return this.$route.params.postId ?? null
    },
  },
  mounted() {
    console.log('PanelCourseEditionPosts', this.course, this.edition)
    this.loadPosts()
  },
  methods: {
    resetRoute() {
      this.$router.push({
        name: 'course-manage-edition-post-collection',
        params: { id: this.course.key, editionId: this.edition.key, collectionId: this.collectionId },
      })
    },
    postSaved(post) {
      console.log('postSaved', post)
      this.showModalEditPost = false
      if (this.posts.find(p => p.key === post.key)) {
        this.posts = this.posts.map(p => {
          if (p.key === post.key) {
            return post
          }
          return p
        })
      } else {
        this.posts.unshift(post)
      }
    },
    postDeleted(post) {
      console.log('postDeleted', post)
      this.posts = this.posts.filter(p => p.key !== post.key)
    },
    newPost() {
      this.showModalEditPost = true
    },
    close() {
      this.showModalEditPost = false
    },
    async loadPosts() {
      if (this.collectionId == null) {
        return
      }
      this.loading = true
      try {
        let data = await ServicePosts.getCollectionPosts(
          this.collectionId,
          this.pagination.page,
          this.pagination.limit,
          this.type
        )
        console.log('loadPosts :>> ', data)
        this.posts = data.posts
        this.users = { ...this.users, ...data.users }
        this.pagination = data.pagination
      } finally {
        this.loading = false
      }
    },
    openPost(post) {
      console.log('openPost', post)
      this.$router.push({
        name: 'course-manage-edition-post-collection',
        params: { postId: post.key, id: this.course.key, editionId: this.edition.key, collectionId: this.collectionId },
      })
    },
    openCollection(collection) {
      console.log('openCollection', collection)
      this.$router.push({
        name: 'course-manage-edition-post-collection',
        params: { collectionId: collection.key, id: this.course.key, editionId: this.edition.key },
      })
    },
    async loadCollections() {
      console.log('loadCollections')
      this.loading = true
      try {
        let result = await ServiceCourses.getEditionPostsCollections(this.course.key, this.edition.key)
        console.log('loadCollections', result)
        this.collections = result.data.collections
        this.pagination = result.data.pagination
      } catch (error) {
        console.log('error', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
