<template>
  <fw-layout>
    <template #main-content>
      <fw-panel-info type="orange" boxed icon class="mb-5">
        <div>
          A nova versão da funcionalidade de Formulários está em
          <span class="font-bold">fase de testes</span>. Caso se depare com algum erro ou identifique aspetos passíveis
          de melhoria, por favor, contacte-nos através do nosso canal de suporte
          <a class="underline" href="mailto:support@ucframework.pt">support@ucframework.pt</a>.
        </div>
      </fw-panel-info>
      <fw-panel :title="$t('forms')" featured>
        <template v-if="canCreateForms" #toolbar>
          <fw-button type="link" @click.native="newExam()">Criar novo formulário</fw-button>
        </template>
        <ContextualSearch :loading="loading" :start-value="searchInput" @search="search" />
      </fw-panel>

      <div class="mt-4">
        <LoadingPlaceholder v-if="loading" />
        <div v-else-if="forms.length > 0" class="bg-white rounded-md">
          <RecycleScroller v-slot="{ item }" :items="forms" :item-size="55" :buffer="50" key-field="key">
            <RecordForm :form="item" @click.native="goToForm(item)" />
          </RecycleScroller>
          <BlockPagination
            v-if="pagination && pagination.total_pages > 1 && !loading"
            :per-page="pagination.limit"
            :total="pagination.totalResults"
            :total-pages="pagination.totalPages"
            :current.sync="pagination.page"
            @page-changed="pageChanged($event)"
          />
        </div>
        <div v-else class="bg-white rounded-md py-16 text-center text-gray-400">
          Sem resultados
        </div>
      </div>
    </template>

    <template #modals>
      <fw-modal v-if="showNewFormModal" :active.sync="showNewFormModal" :can-cancel="true" size="min" @close="close">
        <template #default>
          <ModalNewForm @new="createNewForm($event)" @close="close" />
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ModalNewForm from '@/fw-modules/fw-core-vue/forms/components/modals/ModalNewForm'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FormServices from '@/fw-modules/fw-core-vue/forms/services/ServiceForms'
import RecordForm from '@/fw-modules/fw-core-vue/forms/components/records/RecordForm'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import { RecycleScroller } from 'vue-virtual-scroller'
export default {
  name: 'ViewForms',
  components: {
    ModalNewForm,
    ContextualSearch,
    RecordForm,
    LoadingPlaceholder,
    BlockPagination,
    RecycleScroller,
  },
  data: function() {
    return {
      showNewFormModal: false,
      loading: false,
      searchInput: '',
      forms: [],
      pagination: {
        page: 1,
      },
    }
  },
  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    appName() {
      return this.$store.state.appName || 'ucstudent'
    },
    language() {
      return this.$store.state.language || this.$i18n.locale || 'pt'
    },
    hasInstances() {
      return !!(!this.loading && this.forms.length)
    },
    running() {
      return this.exams.filter(exam => exam.status === 'running')
    },
    next() {
      return this.exams.filter(exam => exam.status === 'waiting')
    },
    published() {
      return this.exams.filter(exam => exam.status !== 'running' && exam.status !== 'draft')
    },
    archivedCounter() {
      if (!this.rawStats) return 0
      return this.rawStats.ended + this.rawStats.deleted + this.rawStats.archived
    },
    draft() {
      return this.exams.filter(exam => exam.status === 'draft')
    },

    // Checks
    isSearching() {
      return this.searchInput.length > 0 || this.appliedFilters.length > 0
    },

    // Permissions
    canCreateForms() {
      // TODO: We must have roles or permissions assigned to the user, not using app name to allow them to do things...
      return this.appName === 'ucteacher'
    },

    showStats() {
      return true
    },
  },
  created() {
    this.loadForms()
  },
  methods: {
    getFilterText(key) {
      var split = key.split(':')
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (var j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },
    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.search()
    },
    changePage(page) {
      this.pagination.page = page
      this.loadForms()
    },
    async search(searchCriteria) {
      if (searchCriteria.term) {
        this.searchInput = searchCriteria.term
      } else {
        this.searchInput = ''
      }
      /*
       term: this.searchTerm,
        filters: this.selectedFilters,
        orderBy: this.orderItemsBy != null ? this.orderItemsBy.key : null,
        orderDirection: this.orderItemsBy != null ? this.orderDirection : null,
        user: this.user,
        dates: this.dates,
      */

      console.log('Searching...')
      console.log(searchCriteria)

      this.loading = true
      this.appliedFilters = typeof searchCriteria != 'undefined' ? searchCriteria.filters : []
      try {
        this.loadForms()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    goToForm(instance) {
      if (this.appName != 'ucstudent') {
        this.$router.push({ name: 'form-editor', params: { key: instance.key } })
      } else {
        this.$router.push({ name: 'form-template', params: { key: instance.key } })
      }
    },
    async loadForms() {
      this.loading = true
      try {
        let data = await FormServices.getAllForms({
          page: this.pagination.page,
          query: this.searchInput,
          filters: this.appliedFilters,
        })
        this.forms = data.items
        this.pagination = data.pagination
      } finally {
        this.loading = false
      }
    },
    close() {
      this.showNewFormModal = false
    },
    newExam() {
      this.showNewFormModal = true
    },
    async loadFormPage(pageKey) {
      console.log('loadFormPage', pageKey)
      let pageResponse = await FormServices.getPage(pageKey)
      if (typeof pageResponse.title[this.language] === 'undefined') {
        pageResponse.title[this.language] = ''
      }

      for (let s = 0; s < pageResponse.schema.length; s++) {
        if (typeof pageResponse.schema[s].title[this.language] === 'undefined') {
          pageResponse.schema[s].title[this.language] = ''
        }

        for (let i = 0; i < pageResponse.schema[s].content.length; i++) {
          //score
          if (typeof pageResponse.schema[s].content[i].score === 'undefined') {
            pageResponse.schema[s].content[i].score = null
          }
          //instructions
          if (
            typeof pageResponse.schema[s].content[i].instructions === 'undefined' ||
            typeof pageResponse.schema[s].content[i].instructions[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].instructions === 'undefined') {
              pageResponse.schema[s].content[i].instructions = {}
            }
            pageResponse.schema[s].content[i].instructions[this.language] = ''
          }
          //label
          if (
            typeof pageResponse.schema[s].content[i].label === 'undefined' ||
            typeof pageResponse.schema[s].content[i].label[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].label === 'undefined') {
              pageResponse.schema[s].content[i].label = {}
            }
            pageResponse.schema[s].content[i].label[this.language] = ''
          }
          //message
          if (
            pageResponse.schema[s].content[i].message &&
            typeof pageResponse.schema[s].content[i].message[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].message === 'undefined') {
              pageResponse.schema[s].content[i].message = {}
            }
            pageResponse.schema[s].content[i].message[this.language] = ''
          }
          //text
          if (
            pageResponse.schema[s].content[i].text &&
            typeof pageResponse.schema[s].content[i].text[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].text === 'undefined') {
              pageResponse.schema[s].content[i].text = {}
            }
            pageResponse.schema[s].content[i].text[this.language] = ''
          }
          //placeholder
          if (
            typeof pageResponse.schema[s].content[i].placeholder === 'undefined' ||
            typeof pageResponse.schema[s].content[i].placeholder[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].placeholder === 'undefined') {
              pageResponse.schema[s].content[i].placeholder = {}
            }

            if (pageResponse.schema[s].content[i].type === 'text_area') {
              pageResponse.schema[s].content[i]['placeholder'][this.language] =
                this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
            }

            pageResponse.schema[s].content[i].placeholder[this.language] = ''
          }

          //check options
          if (pageResponse.schema[s].content[i].options && pageResponse.schema[s].content[i].options.length > 0) {
            for (let o = 0; o < pageResponse.schema[s].content[i].options.length; o++) {
              let optiontext = pageResponse.schema[s].content[i].options[o].text
              /*let optionscore = 0
              if (
                this.scores &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
              ) {
                optionscore = Number(
                  this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
                )
              }*/

              //? this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].score
              //: 0

              /*try {
                optiontext = JSON.parse(optiontext)
                console.log('é JSON', optiontext)
              } catch (e) {
                //no json detected
                console.log('NAO é JSON', optiontext)
              }*/

              if (typeof optiontext !== 'string' && typeof optiontext[this.language] === 'undefined') {
                optiontext[this.language] = ''
              }
              pageResponse.schema[s].content[i].options[o].text = optiontext
              //pageResponse.schema[s].content[i].options[o].score = optionscore
            }
          }
        }
      }

      return pageResponse
    },
    async createNewForm(data) {
      let language = data.language || 'pt'

      try {
        let formData = {
          title: '',
          language: language,
        }

        formData['title'] = data.name || ''
        let responseForm = await FormServices.createForm(formData)
        this.$router.push({
          name: 'form-editor',
          params: { key: responseForm.key },
        })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar o formulário',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "forms": "My forms"
    },
    "pt": {
      "forms": "Os meus formulários"
    }
  }
</i18n>
