<template>
  <div>
    <fw-panel v-if="false" title="Directores de turma" :counter="staff.length" class="mb-5" boxed="xs">
      <template #toolbar>
        <b-dropdown aria-role="list" position="is-bottom-left">
          <fw-button slot="trigger" type="link">Adicionar</fw-button>
          <b-dropdown-item aria-role="listitem" @click="peopleModal('chose_people', 'staff')">
            <span>Pessoa individual</span>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="peopleModal('import_bulk', 'staff')">
            <span>Lista de pessoas</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <LoadingPlaceholder v-if="loading.staff" list></LoadingPlaceholder>
      <fw-panel-info v-else-if="staff.length == 0" centered empty>
        Sem directores de turma.
      </fw-panel-info>
      <div v-else class="flex flex-col gap-3">
        <Person
          v-for="(staff_individual, e) in staff"
          :key="'staff' + e"
          :person="users[staff_individual.user_key]"
          :selectable="false"
          :clickable="false"
        >
          <template #options>
            <b-dropdown aria-role="list" position="is-bottom-left">
              <template #trigger="{ }">
                <fw-button type="transparent" size="sm" class="text-gray-400 text-sm">
                  <fw-icon-more class="h-5 w-5" />
                </fw-button>
              </template>

              <b-dropdown-item aria-role="listitem">
                <div
                  class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                  @click="removeStaff(staff_individual.user_key)"
                >
                  Remover
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </Person>
      </div>
    </fw-panel>
    <fw-panel title="Participantes" :counter="pagination.students.total_items" boxed="xs">
      <template #toolbar>
        <b-dropdown aria-role="list" position="is-bottom-left">
          <fw-button slot="trigger" type="link">Adicionar</fw-button>
          <b-dropdown-item aria-role="listitem" @click="peopleModal('chose_people', 'student')">
            <span>Participante individual</span>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="peopleModal('import_bulk', 'student')">
            <span>Lista de participantes</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <LoadingPlaceholder v-if="loading.students" list></LoadingPlaceholder>
      <fw-panel-info v-else-if="students.length == 0" centered empty>
        Sem participantes.
      </fw-panel-info>
      <div v-else class="flex flex-col gap-3">
        <Person
          v-for="(student, e) in students"
          :key="'student' + e"
          :person="users[student.user_key]"
          :selectable="false"
          :clickable="false"
        >
          <template #options>
            <div v-if="student.enrolled_date != null" class="w-20 mr-3">
              <ProgressIcon
                :progress="student.progress"
                show-percentage
                percentage-pos="right"
                :color="
                  student.progress == 100 ? 'bg-gradient-to-r from-teal-300 to-teal-400' : 'bg-gray-500 bg-opacity-80'
                "
              />
            </div>
            <fw-tag v-if="student.enrolled_date != null" type="light-primary">
              Inscrito
            </fw-tag>
            <fw-tag v-else type="xlight">
              Não inscrito
            </fw-tag>
            <b-dropdown v-if="student.enrolled_date == null" aria-role="list" position="is-bottom-left">
              <template #trigger="{ }">
                <fw-button type="transparent" size="sm" class="text-gray-400 text-sm">
                  <fw-icon-more class="h-5 w-5" />
                </fw-button>
              </template>

              <b-dropdown-item aria-role="listitem">
                <div
                  class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                  @click="removeStudent(student.user_key)"
                >
                  Remover
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </Person>
      </div>
      <BlockPagination
        v-if="pagination.students.totalPages > 1"
        :per-page="pagination.students.limit"
        :total="pagination.students.totalResults"
        :total-pages="pagination.students.totalPages"
        :current.sync="pagination.students.page"
        @page-changed="pageChanged"
      />
    </fw-panel>
    <b-modal
      :active="modal != null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="modal == 'chose_people'"
        title="Adicionar participantes"
        instructions="Seleccione os estudantes a adicionar à turma"
        :multiselect="true"
        :not-alowed-users="notAlowedUsers"
        :invite-people="false"
        :endpoint="searchEndpoint"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
      <ModalImportBulkUsers
        v-if="modal == 'import_bulk'"
        :search-engine="bulkSearch"
        @selected="selectedPeople"
        @close="closeModal"
      ></ModalImportBulkUsers>
    </b-modal>
  </div>
</template>

<script>
// import RecordTournament from '@/components/records/RecordTournament'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalImportBulkUsers from '@/fw-modules/fw-core-vue/ui/components/modals/ModalImportBulkUsers'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'

export default {
  components: {
    Person,
    // RecordTournament,
    ChoosePeopleModal,
    LoadingPlaceholder,
    ModalImportBulkUsers,
    ProgressIcon,
  },

  props: {
    course: {
      type: Object,
      default: () => ({}),
    },
    edition: {
      type: Object,
      default: () => ({}),
    },
    classData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: {
        staff: false,
        students: false,
      },
      users: {},
      students: [],
      staff: [],
      pagination: {
        students: {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 50,
        },
        staff: {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 50,
        },
      },
      modal: null,
      modalType: null,
    }
  },

  computed: {
    notAlowedUsers() {
      return this.students.map(el => el.key)
    },
    user() {
      return this.$store.getters.getUser
    },
    editionKey() {
      return this.edition.key
    },
    courseKey() {
      return this.course.key
    },
    classKey() {
      return this.classData.key
    },
  },

  async mounted() {
    this.getStudents()
    this.getStaff()
  },

  methods: {
    pageChanged(page) {
      this.pagination.students.page = page
      this.getStudents()
    },
    peopleModal(modal, type) {
      this.modal = modal
      this.modalType = type
    },
    async selectedPeople(selection) {
      try {
        let result
        if (this.modalType == 'staff') {
          result = await ServiceCourses.addClassStaff(
            this.courseKey,
            this.editionKey,
            this.classKey,
            selection.map(el => el.key)
          )
        } else {
          result = await ServiceCourses.addClassStudent(
            this.courseKey,
            this.editionKey,
            this.classKey,
            selection.map(el => el.key)
          )
        }
        console.log(result)
        this.users = { ...this.users, ...result.data.users }
        if (this.modalType == 'staff') {
          this.staff = this.staff.concat(result.data.staff)
        } else {
          this.students = this.students.concat(result.data.students)
        }
        this.modal = null

        this.$buefy.snackbar.open({
          message: `Participantes adicionados.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 3000,
        })
      } catch (e) {
        this.showError('Ocorreu um erro ao adicionar o(s) participante(s)')
        console.error(e)
      }
    },
    async removeStudent(key) {
      //ask for confirmation
      this.$buefy.dialog.confirm({
        title: 'Remover estudante',
        message: `Tem a certeza que deseja remover o estudante?`,
        confirmText: 'Remover',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            let result = await ServiceCourses.removeClassStudent(this.courseKey, this.editionKey, this.classKey, [key])
            console.log(result)
            this.students = this.students.filter(el => el.user_key != key)

            this.$buefy.snackbar.open({
              message: `Participante removido.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000,
            })
          } catch (e) {
            this.showError('Ocorreu um erro ao remover o participante')
            console.error(e)
          }
        },
      })
    },
    async removeStaff(key) {
      //ask for confirmation
      this.$buefy.dialog.confirm({
        title: 'Remover director(a) de turma',
        message: `Tem a certeza que deseja remover o director(a) de turma?`,
        confirmText: 'Remover',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            let result = await ServiceCourses.removeClassStaff(this.courseKey, this.editionKey, this.classKey, [key])
            console.log(result)
            this.staff = this.staff.filter(el => el.user_key != key)

            this.$buefy.snackbar.open({
              message: `Director(a) de turma removido.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000,
            })
          } catch (e) {
            this.showError('Ocorreu um erro ao remover o/a director(a) de turma')
            console.error(e)
          }
        },
      })
    },
    async searchEndpoint(params) {
      return await ServiceCourses.searchUsers(this.courseKey, this.editionKey, params)
    },
    async bulkSearch(query) {
      query = query.split(',')
      return await ServiceCourses.bulkSearchUsers(this.courseKey, this.editionKey, query)
    },

    async getStudents() {
      this.loading.students = true

      utils.tryAndCatch(
        this,
        // Main code to run
        async () => {
          const response = await ServiceCourses.getClassStudents(this.courseKey, this.editionKey, this.classKey, {
            page: this.pagination.students.page,
            limit: this.pagination.students.limit,
          })
          console.log('getStudents :>> ', response)
          this.students = response.data.students
          this.pagination.students = response.data.pagination
          this.users = { ...this.users, ...response.data.users }
        },
        // Finally
        () => {
          this.loading.students = false
        }
      )
    },

    async getStaff() {
      this.loading.staff = true

      utils.tryAndCatch(
        this,
        // Main code to run
        async () => {
          const response = await ServiceCourses.getClassStaff(this.courseKey, this.editionKey, this.classKey, {
            page: this.pagination.staff.page,
            limit: this.pagination.staff.limit,
          })
          console.log('getStaff :>> ', response)
          this.staff = response.data.staff
          this.pagination.staff = response.data.pagination
          this.users = { ...this.users, ...response.data.users }
        },
        // Finally
        () => {
          this.loading.staff = false
        }
      )
    },

    closeModal() {
      this.modal = null
    },

    goToStudent(studentKey) {
      let routeName = 'course-manage-edition-class-student'
      let routeParams = {
        editionId: this.editionKey,
        id: this.courseKey,
        classId: this.classKey,
        studentId: studentKey,
      }

      this.$router.push({
        name: routeName,
        params: routeParams,
      })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade"
  },
  "en": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade"
  }
}
</i18n>
