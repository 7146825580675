var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SidebarBase',{scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('ButtonSidebar',{attrs:{"label":"Preview","icon":"eye","bordered":""},nativeOn:{"click":function($event){return _vm.openCourse()}}}),_c('ButtonSidebar',{attrs:{"label":"Dashboard","icon":"dashboard","active":_vm.view == 'dashboard',"to":`${_vm.baseUrl}/dashboard`}}),_c('ButtonSidebar',{attrs:{"label":"Metadados","active":_vm.view == 'metadata',"icon":"journal","to":`${_vm.baseUrl}/metadata`}}),_c('ButtonSidebar',{attrs:{"label":"Módulos","active":_vm.view == 'modules',"icon":"book-mark-solid","to":`${_vm.baseUrl}/modules`}}),(_vm.units && _vm.view == 'modules')?_c('ul',{staticClass:"ml-1.5"},_vm._l((_vm.units),function(unit,index){return _c('li',{key:unit.key + '_' + _vm.edition.key,staticClass:"my-2"},[_c('button',{staticClass:"w-full font-medium transition-colors duration-150",class:{
            'text-primary hover:text-primary': _vm.activeUnit && _vm.activeUnit == unit.key,
            'text-gray-500 hover:text-primary': !_vm.activeUnit || _vm.activeUnit != unit.key,
            'mb-2': _vm.activeUnit && _vm.activeUnit == unit.key,
          },on:{"click":function($event){return _vm.openModule(unit)}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"ml-2 mr-2.5 opacity-50"},[_c('fw-icon-list-view',{staticClass:"w-5 h-5"})],1),_c('span',{staticClass:"hidden font-bold w-10 text-sm mr-1"},[_vm._v("M"+_vm._s(index + 1))]),_c('div',{staticClass:"flex flex-1 flex-col"},[_c('v-clamp',{staticClass:"w-full text-sm text-left",attrs:{"autoresize":"","max-lines":2}},[_vm._v(" "+_vm._s(unit.title)+" ")])],1)])])])}),0):_vm._e(),_c('ButtonSidebar',{attrs:{"label":"Turmas","active":_vm.view == 'classes',"icon":"group","to":`${_vm.baseUrl}/classes`}}),_c('ButtonSidebar',{attrs:{"label":"Atualizações","active":_vm.view == 'publications',"icon":"mega-phone","to":`${_vm.baseUrl}/publications`}}),(_vm.channels && _vm.activeChannelKey && _vm.view == 'publications')?_c('ul',{staticClass:"ml-1.5"},_vm._l((_vm.channels),function(channel){return _c('li',{key:'publication_' + channel.key,staticClass:"my-2"},[_c('button',{staticClass:"w-full font-medium transition-colors duration-150",class:{
            'text-primary hover:text-primary': _vm.activeChannelKey && _vm.activeChannelKey == channel.key,
            'text-gray-500 hover:text-primary': !_vm.activeChannelKey || _vm.activeChannelKey != channel.key,
            'mb-2': _vm.activeChannelKey && _vm.activeChannelKey == channel.key,
          },on:{"click":function($event){return _vm.openChannel('publications', channel)}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"ml-2 mr-2.5 opacity-50"},[_c('fw-icon-hashtag',{staticClass:"w-5 h-5"})],1),_c('div',{staticClass:"flex flex-1 flex-col"},[_c('v-clamp',{staticClass:"w-full text-sm text-left",attrs:{"autoresize":"","max-lines":2}},[_vm._v(" "+_vm._s(channel.title)+" ")])],1)])])])}),0):_vm._e(),_c('ButtonSidebar',{attrs:{"label":"Discussão","active":_vm.view == 'discussions',"icon":"chat-thread","to":`${_vm.baseUrl}/discussions`}}),(_vm.channels && _vm.activeChannelKey && _vm.view == 'discussions')?_c('ul',{staticClass:"ml-1.5"},_vm._l((_vm.channels),function(channel){return _c('li',{key:'discussions_' + channel.key,staticClass:"my-2"},[_c('button',{staticClass:"w-full font-medium transition-colors duration-150",class:{
            'text-primary hover:text-primary': _vm.activeChannelKey && _vm.activeChannelKey == channel.key,
            'text-gray-500 hover:text-primary': !_vm.activeChannelKey || _vm.activeChannelKey != channel.key,
            'mb-2': _vm.activeChannelKey && _vm.activeChannelKey == channel.key,
          },on:{"click":function($event){return _vm.openChannel('discussions', channel)}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"ml-2 mr-2.5 opacity-50"},[_c('fw-icon-hashtag',{staticClass:"w-5 h-5"})],1),_c('div',{staticClass:"flex flex-1 flex-col"},[_c('v-clamp',{staticClass:"w-full text-sm text-left",attrs:{"autoresize":"","max-lines":2}},[_vm._v(" "+_vm._s(channel.title)+" ")])],1)])])])}),0):_vm._e(),(false)?_c('ButtonSidebar',{attrs:{"label":"Comunicações","active":_vm.view == 'messages',"icon":"message","to":`${_vm.baseUrl}/messages`}}):_vm._e(),_c('ButtonSidebar',{attrs:{"active":_vm.view == 'settings',"label":"Configurações","icon":"settings","to":`${_vm.baseUrl}/settings`}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }