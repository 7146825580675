<template>
  <div class="pb-5">
    <fw-heading size="xl">Dashboard</fw-heading>
    <DashboardHero
      :title="edition.title"
      :title-label="course.title"
      :background-image-url="editionUrlBanner"
      tag="Curso digital"
      type="cover"
      class="mt-2 mb-5"
    >
      <template #footer>
        <div class="flex gap-5 text-white items-center justify-center">
          <div v-if="edition.working_mode" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-mode class="h-5 hidden md:block"></fw-icon-course-mode>
            {{ edition.working_mode }}
          </div>
          <div v-if="edition.ects" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-ects class="h-5 hidden md:block"></fw-icon-course-ects>
            {{ edition.ects }} ECTS
          </div>
          <div v-if="edition.price && edition.price > 0" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-price class="h-5 hidden md:block"></fw-icon-course-price>
            {{ edition.price }}€
          </div>
          <div v-if="edition.duration" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-time class="h-4 hidden md:block"></fw-icon-course-time>
            {{ edition.duration }} horas
          </div>
        </div>
      </template>
    </DashboardHero>

    <fw-panel title="Números" class="my-5">
      <PanelStats :stats="stats" />
    </fw-panel>

    <fw-panel title="Metadados" boxed="xs" custom-class="bg-white" class="my-5">
      <div class="flex px-4 pb-3 pt-1 w-full gap-12">
        <div v-if="edition && edition.status == 'running'" class="flex-shrink-0">
          <fw-label>Estado</fw-label>
          <div class="font-bold flex gap-2 items-center text-primary">
            <fw-icon-run class="w-5 h-5 animate-pulse" /> Em curso
          </div>
        </div>
        <div>
          <fw-label>Tipo de subscrição</fw-label>
          <div class="font-medium flex gap-2 items-center">Pré-admissão</div>
        </div>
        <div v-if="edition">
          <fw-label>Período de inscrição</fw-label>
          <div v-if="edition.signup_start_date" class="font-medium flex gap-2 items-center">
            <fw-icon-calendar class="w-5 h-5 opacity-50" /> {{ edition.signup_start_date | formatDate }} -
            {{ edition.signup_end_date | formatDate }}
          </div>
          <div v-else class="font-medium flex gap-2 items-center text-gray-500">
            <fw-icon-calendar class="w-5 h-5 opacity-50" /> Não definido.
          </div>
        </div>
        <div v-if="edition">
          <fw-label>Realização do curso</fw-label>
          <div v-if="edition.start_date" class="font-medium flex gap-2 items-center">
            <fw-icon-calendar class="w-5 h-5 opacity-50" /> {{ edition.start_date | formatDate }} -
            {{ edition.end_date | formatDate }}
          </div>
          <div v-else class="font-medium flex gap-2 items-center text-gray-500">
            <fw-icon-calendar class="w-5 h-5 opacity-50" /> Não definido.
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel v-if="units.length" title="Módulos" :counter="units.length" class="my-5">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
        <a v-for="unit in units" :key="unit.index + '_' + edition.key" @click.prevent="goToUnit(unit)">
          <CardCourseEditionUnit :unit="unit" />
        </a>
      </div>
    </fw-panel>

    <fw-panel title="Turmas" :counter="classes.length" class="my-5">
      <div v-if="classes.length" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
        <a v-for="editionClass in classes" :key="editionClass.key" @click.prevent="goToClass(editionClass)">
          <CardCourseEditionClassProgress :edition-class="editionClass" />
        </a>
      </div>
      <fw-panel-info v-else empty clean>Ainda não foram criadas turmas para esta edição.</fw-panel-info>
    </fw-panel>
  </div>
</template>

<script>
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import ServicesCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import CardCourseEditionUnit from '@/fw-modules/fw-core-vue/courses/components/cards/CardCourseEditionUnit'
import CardCourseEditionClassProgress from '@/fw-modules/fw-core-vue/courses/components/cards/CardCourseEditionClassProgress'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    PanelStats,
    DashboardHero,
    CardCourseEditionUnit,
    CardCourseEditionClassProgress,
  },

  props: {
    course: Object,
    edition: Object,
  },

  data() {
    return {
      stats: [],
      units: [],
      classes: [],
      participants: 0,
      enrolled: 0,
      loading: {
        stats: true,
        classes: true,
        units: true,
      },
    }
  },

  computed: {
    editionUrlBanner() {
      return this.edition.banner ? this.getImageViewUrl(this.edition.banner, 'max800') : ''
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
  },

  mounted() {
    this.loadStats()
    this.getUnits()
    this.loadClasses()
  },

  methods: {
    goToUnit(module) {
      this.$router.push({
        name: 'course-manage-edition-module-topic',
        params: {
          id: this.course.key,
          editionId: this.edition.key,
          view: 'modules',
          moduleId: module.key,
        },
      }).href
    },
    goToClass(editionClass) {
      console.log('goToClass', editionClass, this.edition.key, this.course.key)
      this.$router.push({
        name: 'course-manage-edition-class',
        params: {
          id: this.course.key,
          editionId: this.edition.key,
          classKey: editionClass.key,
        },
      }).href
    },
    getImageViewUrl(file, size) {
      return ServiceStorage.getImageViewUrl(file, size)
    },
    loadClasses() {
      utils.tryAndCatch(
        this,
        async () => {
          let response = await ServicesCourses.getClasses(this.course.key, this.edition.key, 1)
          console.log('loadClasses', response)
          this.classes = response.data.classes
        },
        () => {
          this.loading.classes = false
        }
      )
    },
    loadStats() {
      utils.tryAndCatch(
        this,
        async () => {
          let response = await ServicesCourses.getStatistics(this.course.key, this.edition.key)
          console.log('loadStats', response)
          let stats = response.data
          let statsLabels = {
            classes: 'Turmas',
            comments: 'Comentários',
            discussions: 'Tópicos de discussão',
            enrolled_students: 'Inscritos',
            posts: 'Publicações',
            total_students: 'Participantes',
            topics: 'Tópicos',
            units: 'Módulos',
          }
          //loop through stats keys and values
          for (let [key, value] of Object.entries(stats)) {
            if (statsLabels[key]) {
              this.stats.push({
                label: statsLabels[key],
                value: value,
                description: null,
                valueSuffix: null,
              })
              if (key == 'total_students') {
                this.participants = value
              }
              if (key == 'enrolled_students') {
                this.enrolled = value
              }
            }
          }
        },
        () => {
          this.loading.stats = false
        }
      )
    },

    async getUnits() {
      this.loading.units = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await ServicesCourses.getUnits(this.course.key, this.edition.key)
          this.units = response.data.units
        },
        () => {
          this.loading.units = false
        }
      )
    },
  },
}
</script>
