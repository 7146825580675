<template>
  <fw-layout :back-to-enable="false">
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>

    <template #main-content>
      <fw-panel :title="$t('pages')" featured class="mb-5">
        <template v-if="canCreatePage" #toolbar>
          <fw-button type="link" @click.native="newDocument()">{{ $t('new_document') }}</fw-button>
        </template>
        <ContextualSearch
          :loading="loading"
          :filter-options="[]"
          :applied-filters="appliedFilters"
          :start-value="searchInput"
          @search="search"
        >
          <template #tags>
            <FilterTag
              v-for="(filter, f) in appliedFilters"
              :key="'filter_' + f"
              :text="getFilterText(filter)"
              :show-close-button="true"
              @close="deleteFilter(f)"
            ></FilterTag>
          </template>
        </ContextualSearch>
      </fw-panel>

      <fw-panel-info type="orange" boxed icon class="mb-5">
        <div>
          {{ $t('experimental_warning1') }}
          <span class="font-bold">{{ $t('experimental_warning2') }}</span
          >{{ $t('experimental_warning3') }}
          <a class="underline" href="mailto:support@ucframework.pt">support@ucframework.pt</a>.
        </div>
      </fw-panel-info>

      <LoadingPlaceholder v-if="loading" />
      <div v-else-if="pages.length > 0" class="bg-white rounded-md">
        <PageRecord
          v-for="(instance, i) in pages"
          :key="'page_' + i"
          :users="users"
          :instance="instance"
          @click.native="goToDocument(instance)"
        ></PageRecord>
        <BlockPagination
          v-if="pagination && pagination.total_pages > 1"
          :total="pagination.total_items"
          :total-pages="pagination.total_pages"
          :per-page="pagination.active_limit"
          :current.sync="page"
          @page-changed="pageChanged"
        />
      </div>
      <div v-else-if="!loading" class="bg-white rounded-md py-16 text-center text-gray-400">
        {{ $t('no_results') }}
      </div>
    </template>

    <template #modals>
      <fw-modal v-if="showNewPageModal" :active.sync="showNewPageModal" :can-cancel="true" size="min" @close="close">
        <template #default>
          <ModalNewPage context="pages" context-key="pages" @close="close"></ModalNewPage>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag'
import PageRecord from '@/fw-modules/fw-core-vue/pages/components/records/PageRecord'
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import ModalNewPage from '../components/modals/ModalNewPage.vue'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'

export default {
  name: 'ViewPages',
  components: {
    ContextualSearch,
    FilterTag,
    PageRecord,
    LoadingPlaceholder,
    BlockPagination,
    HeaderLanguagePanel,
    ModalNewPage,
  },
  data: function() {
    return {
      showNewPageModal: false,
      loading: false,
      searchInput: '',
      selectedUser: null,
      appliedFilters: [],
      filters: [
        {
          key: 'state',
          label: 'Estado',
          options: [
            {
              key: 'reset',
              label: 'Todos',
            },
            {
              key: 'draft',
              label: 'Em edição',
            },
            {
              key: 'running',
              label: 'Em curso',
            },
            {
              key: 'waiting',
              label: 'Publicados',
            },
            {
              key: 'scheduled',
              label: 'Agendados',
            },
            {
              key: 'ended',
              label: 'Terminados',
            },
          ],
        },
      ],
      sortBy: null,
      sortDirection: 'asc',
      pages: [],
      users: {},
      page: 1,
      pagination: {
        active_limit: 50,
        current_page: 1,
        total_items: 0,
        total_pages: 1,
      },
    }
  },
  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    appName() {
      return this.$store.state.appName || 'ucstudent'
    },
    language() {
      return this.$store.state.language || this.$i18n.locale || 'pt'
    },

    // Checks
    isSearching() {
      return this.searchInput.length > 0 || this.appliedFilters.length > 0
    },

    // Permissions
    canCreatePage() {
      // TODO: We must have roles or permissions assigned to the user, not using app name to allow them to do things...
      return true
    },
  },
  created() {
    this.search()
  },
  methods: {
    pageChanged(page) {
      this.page = page
      this.search()
    },
    goToDocument(instance) {
      this.$router.push({ name: 'content-pages-editor', params: { key: instance.key } })
    },
    seeAll(type) {
      this.search({
        filters: type === 'published' ? ['state:waiting', 'state:scheduled', 'state:ended'] : ['state:' + type],
      })
    },
    seeArchived() {
      this.search({
        filters: ['state:ended'],
      })
    },
    getFilterText(key) {
      var split = key.split(':')
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (var j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },
    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.search()
    },
    async search(searchCriteria) {
      if (!searchCriteria) {
        searchCriteria = {
          term: this.searchInput,
          filters: this.appliedFilters,
        }
      }
      /*
       term: this.searchTerm,
        filters: this.selectedFilters,
        orderBy: this.orderItemsBy != null ? this.orderItemsBy.key : null,
        orderDirection: this.orderItemsBy != null ? this.orderDirection : null,
        user: this.user,
        dates: this.dates,
      */

      console.log('Searching...')
      console.log(searchCriteria)

      this.loading = true
      this.appliedFilters = typeof searchCriteria != 'undefined' ? searchCriteria.filters : []
      try {
        let status = null
        if (typeof searchCriteria != 'undefined') {
          for (let index = 0; index < searchCriteria.filters.length; index++) {
            const filterParts = searchCriteria.filters[index].split(':')
            if (filterParts[0] == 'state' && filterParts[1] != 'reset') {
              status = filterParts[1]
            }
          }
        }

        let result = await ServicePages.getPages({
          filterStatus: status,
          query: searchCriteria.term,
          page: this.page,
          limit: 50,
        })
        console.log('GET PAGES ', result)
        this.pages = result.pages
        this.pagination = result.pagination
        this.users = result.users
        /*let result = await FormServices.getAnswers(
          this.instance.key,
          true,
          this.sortBy,
          this.sortDirection,
          searchCriteria.term.length > 0 ? searchCriteria.term : null,
          searchCriteria.filters
        )
        console.log(result)
        .answers*/
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    openQuestionBuckets() {
      this.$router.push({ name: 'exams-manage-question-buckets' })
    },
    close() {
      this.showNewPageModal = false
    },
    newDocument() {
      this.showNewPageModal = true
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "pages": "Documents",
      "new_document": "New document",
      "experimental_warning1": "The Documents feature is in",
      "experimental_warning2": "testing phase",
      "experimental_warning3": ". If you encounter any errors or identify aspects that can be improved, please contact us at",
      "no_results": "No documents to show"
    },
    "pt": {
      "pages": "Documentos",
      "new_document": "Novo documento",
      "experimental_warning1": "A funcionalidade de Documentos está em",
      "experimental_warning2": "fase de testes",
      "experimental_warning3": ". Caso se depare com algum erro ou identifique aspetos passíveis",
      "no_results": "Não existem documentos para mostrar"
    }
  }
</i18n>
