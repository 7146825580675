var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer-dashboard-card flex flex-col gap-1.5 rounded-xl",class:{
    'bg-normal': _vm.type === 'normal',
    'bg-warning': _vm.type === 'warning',
    'bg-attention': _vm.type === 'attention',
    'flex-shrink-0': _vm.noShrink,
    'w-full': _vm.fullWidth,
  }},[_vm._t("header"),_c('div',{staticClass:"flex gap-3"},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }