<template>
  <fw-panel title="Agendamento de tópico">
    <div class="flex flex-col">
      <b-field label="Data de início">
        <b-datepicker v-model="start_date"></b-datepicker>
      </b-field>
      <b-field label="Data de fim" class="mt-3">
        <b-datepicker v-model="end_date" :min-date="start_date"></b-datepicker>
      </b-field>
      <b-field v-if="edit.type == 'evaluation'" label="Duração da avaliação (em minutos)" class="mt-5">
        <b-input v-model="duration" type="number" min="0" />
      </b-field>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        Cancelar
      </fw-button>
      <fw-button :type="!allowNext ? 'disabled' : 'primary'" :disable="!allowNext" class="w-32" @click.native="next()">
        Guardar
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
export default {
  name: 'ModalNewTopicAgenda',
  props: {
    edit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      start_date: null,
      end_date: null,
      duration: null,
    }
  },
  computed: {
    allowNext() {
      return this.start_date != null
    },
  },
  mounted() {
    if (this.edit && this.edit.session && this.edit.session.start_date && this.edit.session.end_date) {
      this.start_date = new Date(this.edit.session.start_date)
      this.end_date = new Date(this.edit.session.end_date)
      if (this.edit.session.exam && this.edit.session.exam.duration) {
        this.duration = this.edit.session.exam.duration
      }
    }
  },
  methods: {
    next() {
      if (!this.allowNext) {
        return
      }

      this.$emit('save', {
        start_date: this.start_date,
        end_date: this.end_date,
        duration: this.duration,
      })
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
