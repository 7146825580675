import store from '@/store'

import ViewHome from '../views/ViewHome'
import ViewUnits from '../views/academic/ViewUnits'
import ViewUnit from '../views/academic/ViewUnit'
import ViewClass from '../views/academic/ViewClass'

import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import ViewRecordings from '@/fw-modules/fw-meetings-vue/views/ViewRecordings'
//import ViewQuiz from '@/fw-modules/fw-core-vue/exams/views/ViewQuiz'
import ViewEditor from '@/fw-modules/fw-core-vue/pages/views/ViewEditor'

export default [
  {
    path: '/',
    name: 'home',
    component: ViewHome,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/t/:token',
    name: 'nonio_auth',
    meta: {
      requiresNoAuth: true,
      allowPlugin: true,
      redirect: async function(route, isLoggedIn) {
        let data
        try {
          data = await ServiceAuth.checkToken(route.params.token)
        } catch (error) {
          console.error(`Failed to check nonio token ${route.params.token}`, error)
        }

        if (!data) {
          return { name: isLoggedIn ? 'home' : 'login' }
        }

        if (data.token) {
          store.dispatch('setNewLogin', data)
        }

        if (data.context == 'units') {
          return { name: 'units' }
        } else {
          return { name: 'home' }
        }
      },
    },
  },
  {
    path: '/recordings',
    name: 'recordings',
    component: ViewRecordings,
    meta: { requiresRole: 'user' },
  },
  /*{
    path: '/quiz',
    name: 'quiz',
    component: ViewQuiz,
    meta: { requiresRole: 'user' },
  },*/
  {
    path: '/units',
    name: 'units',
    component: ViewUnits,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/unit/:key',
    name: 'unit',
    component: ViewUnit,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/unit/:key/:subpage',
    name: 'unit_subpage',
    component: ViewUnit,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/class/:key',
    name: 'class',
    component: ViewClass,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/class/:key/:subpage',
    name: 'class_subpage',
    component: ViewClass,
    meta: { requiresRole: 'teacher' },
  },

  // Pages editor routes
  {
    path: '/page/:key/:version',
    name: 'page_editor',
    component: ViewEditor,
    meta: { requiresRole: 'teacher' },
  },
]
