<template>
  <fw-layout
    :notfound="!loading.initial && !loading && !course"
    :loading="loading.initial"
    loading-title="Curso"
    loading-icon="cube"
    back-to="/manage/courses"
  >
    <template #header-nav>
      <div class="flex gap-5 items-center">
        <div>
          <div>
            <v-clamp
              v-if="course && course.title && course.title.length > 0"
              autoresize
              :max-lines="1"
              class="font-semibold text-md leading-5 max-w-md"
            >
              {{ course.title }}
            </v-clamp>
            <span v-else class="text-gray-300">Curso sem título</span>
          </div>
          <div class="text-sm font-semibold text-gray-500">Curso digital</div>
        </div>
      </div>
    </template>

    <template v-if="false" #header-toolbar>
      <div class="mx-5 flex items-center">
        <HeaderCourseActions
          v-if="isCreator || isManager"
          :checks.sync="permissions"
          class="z-50"
          @publish="publishCourse"
        />
      </div>
    </template>

    <template #main-sidebar>
      <SidebarManageCourse :course="course" />
    </template>

    <template #main-content>
      <PanelManageCourseDashboard v-if="view === 'dashboard'" :course="course" />
      <PanelManageCoursePeople v-else-if="view === 'people'" :course="course" />
      <PanelManageCourseNotifications v-else-if="view === 'notifications'" :course="course" />
      <PanelManageCourseActivity v-else-if="view === 'activity'" :course="course" />
      <PanelManageCourseSettings
        v-else-if="view === 'settings'"
        :permissions="permissions"
        :course="course"
        :editable="isManager || isCreator"
        @save-metadata="saveCourseMetadata"
        @save="saveCourse"
      />
    </template>

    <template #modals></template>
  </fw-layout>
</template>

<script>
import SidebarManageCourse from '@/fw-modules/fw-core-vue/courses/components/sidebars/manage/SidebarManageCourse'
import PanelManageCourseDashboard from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseDashboard'
import PanelManageCourseSettings from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseSettings'
import HeaderCourseActions from '@/fw-modules/fw-core-vue/courses/components/header/HeaderCourseActions'
import ServicesCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import PanelManageCourseNotifications from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseNotifications'
import PanelManageCoursePeople from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCoursePeople'
import PanelManageCourseActivity from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseActivity'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ViewManageCourse',

  components: {
    SidebarManageCourse,
    PanelManageCourseDashboard,
    HeaderCourseActions,
    PanelManageCourseSettings,
    PanelManageCoursePeople,
    PanelManageCourseNotifications,
    PanelManageCourseActivity,
  },

  data() {
    return {
      edition: null,
      course: null,
      loading: {
        initial: true,
      },
      permissions: {},
    }
  },

  computed: {
    isCreator() {
      return true
    },
    isManager() {
      return true
    },
    courseKey() {
      return this.$route.params.id
    },
    editionKey() {
      return this.$route.params.editionId
    },
    view() {
      return this.$route.params.view || 'dashboard'
    },
  },

  watch: {
    // On route change
    $route() {
      if (this.editionKey) {
        this.loadEdition()
      } else {
        this.edition = null
      }
    },
  },

  created() {
    this.loadCourse()
    if (this.editionKey) {
      this.loadEdition()
    } else {
      this.edition = null
    }
  },

  methods: {
    async loadCourse() {
      this.loading.course = true
      utils.tryAndCatch(
        this,
        async () => {
          let course = await ServicesCourses.getCourse(this.courseKey)
          console.log('course', course)
          this.course = course.data
        },
        () => {
          setTimeout(() => {
            this.loading.course = false
            this.loading.initial = false
          }, 250)
        }
      )
    },

    async loadEdition() {
      if (this.editionKey) {
        utils.tryAndCatch(this, async () => {
          let edition = await ServicesCourses.getEdition(this.courseKey, this.editionKey)
          console.log('edition', edition)
          this.edition = edition.data
        })
      }
    },

    // TODO: We need this?
    async publishCourse() {
      utils.tryAndCatch(this, async () => {
        let course = await ServicesCourses.courseActions(this.courseKey, 'approve')
        console.log('publishCourse', course)
      })
    },

    async saveCourse(data) {
      console.log(data)
      this.course[data.key] = data.value

      let body = JSON.parse(JSON.stringify(this.course))
      delete body.user_key
      utils.tryAndCatch(this, async () => {
        let course = await ServicesCourses.updateCourse(this.courseKey, body)
        console.log('update course', course)
        this.course = course.data

        this.$buefy.snackbar.open({
          message: 'Dados guardados.',
          type: 'is-primary',
        })
      })
    },

    async saveCourseMetadata(metadata) {
      console.log(metadata)
      this.course.duration = metadata.duration
      this.course.mode = metadata.mode
      this.course.title = metadata.title

      utils.tryAndCatch(this, async () => {
        let course = await ServicesCourses.updateCourse(this.courseKey, this.course)
        console.log('update course', course)
        this.course = course.data

        this.$buefy.snackbar.open({
          message: 'Dados guardados.',
          type: 'is-primary',
        })
      })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "courseStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  },
  "en": {
    "courseStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  }
}
</i18n>
