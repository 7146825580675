<template>
  <div class="flex w-screen h-screen min-h-screen gap-5" :class="{ 'bg-dashboard': dark, 'p-5': !paddingless }">
    <slot />
    <slot name="modals"></slot>
  </div>
</template>

<script>
import LayoutBase from './LayoutBase'
export default {
  name: 'DashboardDefault',
  mixins: [LayoutBase],
  props: {
    dark: Boolean,
    paddingless: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.max-h-screen-3-4 {
  max-height: 75vh;
}
.text-alphabet {
  color: #cccccc;
}
.bg-alphabet-ball {
  background-color: #999999;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.color-inactive-tab {
  color: #999999;
}
.color-stats-text {
  color: #d2d2d2;
}
.color-stats-text-secondary {
  color: #777777;
}
.color-icon-panel {
  color: #444444;
}
.bg-stats-background {
  background-color: #444444;
}
.bg-stats-background-2 {
  background-color: #212121;
}
.bg-stats-foreground {
  background-color: #d2d2d2;
}
.bg-dashboard {
  background-color: #0a0a0a;
}
.bg-panel {
  background-color: #202020;
}
.dashboard-overflow-gradient {
  background: rgb(32, 32, 32);
  background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(32, 32, 32, 1) 80%);
}
.bg-card {
  background-color: #464646;
}
.text-title {
  color: #999999;
}
.bg-version {
  background-color: #555555;
}
.bg-examstatus-active {
  background-color: #41a89a;
}

.bg-examstatus-red {
  background-color: #ef6161;
}

.bg-examstatus-orange {
  background-color: #f09000;
}

.bg-examstatus-inactive {
  background-color: #383838;
}

.bg-inner-card {
  background-color: #383838;
}

.square-timer {
  position: relative;
  width: 100%;
}

.square-timer:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.square-timer .timer-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
