<template>
  <div
    class="rounded-xl transition-all hover:shadow-lg hover:bg-white cursor-pointer overflow-hidden relative bg-gray-200 bg-opacity-80 text-center flex-shrink-0"
  >
    <div class="flex flex-col py-3 px-4 text-gray-500 h-full">
      <div class="flex justify-between gap-2">
        <div class="text-sm gap-1 font-semibold flex items-center">
          <fw-icon-cube class="h-5 w-5 opacity-80" />
          <div class="flex-1 opacity-80">
            Curso digital
          </div>
        </div>
      </div>
      <div class="flex-1 flex flex-col justify-center gap-2 text-left py-2">
        <div
          v-if="instance.title != null && instance.title.length > 0"
          class="text-gray-800 text-xl font-semibold leading-6 py-5"
        >
          <v-clamp autoresize :max-lines="2">{{ instance.title }}</v-clamp>
        </div>
        <div v-else class="text-gray-500 text-opacity-50 text-center w-4/5 mx-auto py-5">
          Curso sem título
        </div>
        <div class="flex flex-col gap-2">
          <fw-label>Edições</fw-label>
          <fw-tag :counter="instance.stats.draft || 0" force-counter expanded type="xlight">Em rascunho</fw-tag>
          <fw-tag :counter="instance.stats.published || 0" force-counter expanded type="xlight">Publicadas</fw-tag>
          <fw-tag :counter="instance.stats.closed || 0" force-counter expanded type="xlight">Terminadas</fw-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamDraftCard',
  //components: { TextStatus },
  props: {
    users: {
      type: Object,
    },
    language: {
      type: String,
      default: 'pt',
    },
    instance: {
      type: Object,
    },
  },
  computed: {
    backgroundImage() {
      let bkimgMap = {
        'abstract-01': 'https://static.fw.uc.pt/banners/abstract-01.jpg',
        'abstract-02': 'https://static.fw.uc.pt/banners/abstract-02.jpg',
        'abstract-03': 'https://static.fw.uc.pt/banners/abstract-03.jpg',
      }
      return bkimgMap[this.instance.cover != null ? this.instance.cover : 'abstract-01']
    },
    predictedStartDate() {
      return this.instance != null && this.instance.startDate != null
        ? this.$options.filters.formatDayWithTime(this.instance.startDate)
        : ''
    },
    examDuration() {
      return this.instance && this.instance.duration ? this.instance.duration.toString() : '0'
    },
    examCreator() {
      return this.users != null && this.users[this.instance.user_key] ? this.users[this.instance.user_key] : null
    },
    examDynamizer() {
      return this.users != null && this.instance.managers.length > 0 ? this.users[this.instance.managers[0]] : null
    },
    examName() {
      return this.overrideExamName.length > 0 ? this.overrideExamName : this.form && this.form.title[this.language]
    },
  },
}
</script>

<i18n>
    {
      "en": {
        "metadata": {
          "presential": "Presential exam",
          "remote": "Remote exam",
          "open": "Public",
          "restricted": "Restricted"
        },
        "instructions": {
          "examcreatedby": "Exam created by",
          "examdynamizedby": "Exam dynamized by"
        }
      },
      "pt": {
        "metadata": {
          "presential": "Presencial",
          "remote": "Remoto",
          "open": "Aberto",
          "restricted": "Restrito"
        },
        "instructions": {
          "examcreatedby": "Exame criado por",
          "examdynamizedby": "Exame dinamizado por"
        }
      }
    }
    </i18n>
<style scoped>
.exam-cover-gradient {
  background: rgb(38, 38, 38);
  background: linear-gradient(0deg, rgba(38, 38, 38, 1) 25%, rgba(54, 54, 54, 0.48) 80%);
}
.progress-gradient {
  background: rgb(56, 195, 177);
  background: linear-gradient(90deg, rgba(56, 195, 177, 1) 0%, rgba(116, 246, 230, 1) 85%);
}
</style>
