import ViewFormEditor from './views/ViewFormEditor.vue'
import ViewFormViewer from './views/ViewFormViewer.vue'
import ViewUserAnswers from './views/ViewUserAnswers.vue'
import ViewForms from './views/ViewForms.vue'

export default [
  {
    path: '/form/:key/answers',
    name: 'form-answers',
    component: ViewUserAnswers,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/forms/template/:key',
    name: 'form-template',
    component: ViewFormEditor,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/form/:key',
    name: 'form-viewer',
    component: ViewFormViewer,
  },
  {
    path: '/forms/templates',
    name: 'forms',
    component: ViewForms,
  },
]
