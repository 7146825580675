<template>
  <SidebarBase>
    <template #main>
      <ButtonSidebar label="Preview" icon="eye" bordered @click.native="openCourse()" />
      <ButtonSidebar label="Dashboard" icon="dashboard" :active="view == 'dashboard'" :to="`${baseUrl}/dashboard`" />
      <ButtonSidebar label="Metadados" :active="view == 'metadata'" icon="journal" :to="`${baseUrl}/metadata`" />
      <ButtonSidebar label="Módulos" :active="view == 'modules'" icon="book-mark-solid" :to="`${baseUrl}/modules`" />
      <ul v-if="units && view == 'modules'" class="ml-1.5">
        <li v-for="(unit, index) in units" :key="unit.key + '_' + edition.key" class="my-2">
          <button
            class="w-full font-medium transition-colors duration-150 "
            :class="{
              'text-primary hover:text-primary': activeUnit && activeUnit == unit.key,
              'text-gray-500 hover:text-primary': !activeUnit || activeUnit != unit.key,
              'mb-2': activeUnit && activeUnit == unit.key,
            }"
            @click="openModule(unit)"
          >
            <div class="flex">
              <div class="ml-2 mr-2.5 opacity-50">
                <fw-icon-list-view class="w-5 h-5" />
              </div>
              <span class="hidden font-bold w-10 text-sm mr-1">M{{ index + 1 }}</span>
              <div class="flex flex-1 flex-col">
                <v-clamp class="w-full text-sm text-left" autoresize :max-lines="2">
                  {{ unit.title }}
                </v-clamp>
              </div>
            </div>
          </button>
        </li>
      </ul>
      <ButtonSidebar label="Turmas" :active="view == 'classes'" icon="group" :to="`${baseUrl}/classes`" />
      <ButtonSidebar
        label="Atualizações"
        :active="view == 'publications'"
        icon="mega-phone"
        :to="`${baseUrl}/publications`"
      />
      <ul v-if="channels && activeChannelKey && view == 'publications'" class="ml-1.5">
        <li v-for="channel in channels" :key="'publication_' + channel.key" class="my-2">
          <button
            class="w-full font-medium transition-colors duration-150 "
            :class="{
              'text-primary hover:text-primary': activeChannelKey && activeChannelKey == channel.key,
              'text-gray-500 hover:text-primary': !activeChannelKey || activeChannelKey != channel.key,
              'mb-2': activeChannelKey && activeChannelKey == channel.key,
            }"
            @click="openChannel('publications', channel)"
          >
            <div class="flex">
              <div class="ml-2 mr-2.5 opacity-50">
                <fw-icon-hashtag class="w-5 h-5" />
              </div>
              <div class="flex flex-1 flex-col">
                <v-clamp class="w-full text-sm text-left" autoresize :max-lines="2">
                  {{ channel.title }}
                </v-clamp>
              </div>
            </div>
          </button>
        </li>
      </ul>
      <ButtonSidebar
        label="Discussão"
        :active="view == 'discussions'"
        icon="chat-thread"
        :to="`${baseUrl}/discussions`"
      />
      <ul v-if="channels && activeChannelKey && view == 'discussions'" class="ml-1.5">
        <li v-for="channel in channels" :key="'discussions_' + channel.key" class="my-2">
          <button
            class="w-full font-medium transition-colors duration-150 "
            :class="{
              'text-primary hover:text-primary': activeChannelKey && activeChannelKey == channel.key,
              'text-gray-500 hover:text-primary': !activeChannelKey || activeChannelKey != channel.key,
              'mb-2': activeChannelKey && activeChannelKey == channel.key,
            }"
            @click="openChannel('discussions', channel)"
          >
            <div class="flex">
              <div class="ml-2 mr-2.5 opacity-50">
                <fw-icon-hashtag class="w-5 h-5" />
              </div>
              <div class="flex flex-1 flex-col">
                <v-clamp class="w-full text-sm text-left" autoresize :max-lines="2">
                  {{ channel.title }}
                </v-clamp>
              </div>
            </div>
          </button>
        </li>
      </ul>
      <ButtonSidebar
        v-if="false"
        label="Comunicações"
        :active="view == 'messages'"
        icon="message"
        :to="`${baseUrl}/messages`"
      />
      <ButtonSidebar :active="view == 'settings'" label="Configurações" icon="settings" :to="`${baseUrl}/settings`" />
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  name: 'SidebarManageCourse',
  components: {
    ButtonSidebar,
    SidebarBase,
  },

  props: {
    course: {
      type: Object,
      default: () => ({}),
    },
    edition: {
      type: Object,
      default: () => ({}),
    },
    units: {
      type: Array,
      default: () => [],
    },
    activeUnit: {
      type: String,
      default: null,
    },
    channels: {
      type: Array,
      default: () => [],
    },
    activeChannelKey: {
      type: String,
      default: null,
    },
  },

  computed: {
    baseUrl() {
      return '/manage/course/' + this.course.key + '/edition/' + this.edition.key
    },
    view() {
      return this.$route.meta.view || this.$route.params.view || 'dashboard'
    },
  },

  methods: {
    openChannel(view, channel) {
      this.$router.push({
        name: 'course-manage-edition-post-collection',
        params: { id: this.course.key, editionId: this.edition.key, collectionId: channel.key, view: view },
      })
    },
    openModule(module) {
      this.$router.push({
        name: 'course-manage-edition-module-topic',
        params: {
          id: this.course.key,
          editionId: this.edition.key,
          view: 'modules',
          moduleId: module.key,
        },
      })
    },
    openCourse() {
      this.$router.push(`/course/${this.course.key}/edition/${this.edition.key}`)
    },
  },
}
</script>
