<template>
  <div class="groupflex-col flex p-4 rounded-xl gap-3 bg-teal-200 flex-col">
    <div class="flex gap-3 justify-between items-center">
      <div>
        <fw-icon-book-mark-solid class="w-6 h-6" />
      </div>
    </div>
    <div class="flex-1 flex flex-col justify-end gap-2">
      <div class="font-semibold flex-1 text-lg">
        <v-clamp autoresize :max-lines="3">
          {{ unit.title }}
        </v-clamp>
      </div>
      <div class="opacity-70 font-semibold text-sm">
        {{ unit.stats.total_topics }} tópico{{ unit.topics == 1 ? '' : 's' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardCourseEditionUnit',

  props: {
    unit: Object,
  },

  data() {
    return {}
  },

  computed: {
    // openEntry() {
    //   return this.$router.resolve({
    //     name: 'manage-map',
    //     params: {
    //       key: this.map.key,
    //     },
    //   }).href
    // },
  },
}
</script>
