<template>
  <fw-layout
    :full="isFullLayout"
    :notfound="!initialLoading && !course"
    :loading="initialLoading"
    loading-title="Curso"
    loading-icon="survey"
    :back-to="'/manage/course/' + courseKey + '/dashboard'"
  >
    <template #header-nav>
      <div class="flex gap-5 items-center">
        <div>
          <div>
            <v-clamp
              v-if="edition && edition.title && edition.title.length > 0"
              autoresize
              :max-lines="1"
              class="font-semibold text-md leading-5 max-w-xs"
            >
              {{ edition.title }}
            </v-clamp>
            <span v-else class="text-gray-500 text-sm">Edição sem título definido</span>
          </div>
          <div v-if="course" class="text-sm font-semibold text-gray-500">
            <v-clamp
              v-if="edition && edition.title && edition.title.length > 0"
              autoresize
              :max-lines="1"
              class="font-medium text-sm leading-5 max-w-sm"
            >
              {{ course.title }}
            </v-clamp>
          </div>
        </div>
        <div>
          <fw-tag size="sm" :type="edition.status == 'draft' ? 'orange' : 'light-primary'" custom-class="px-3">
            {{ $t(`editionStatus.${edition.status}`) }}
          </fw-tag>
        </div>
      </div>
    </template>

    <template #main-sidebar>
      <SidebarManageCourseEdition
        :active-channel-key="collectionId"
        :course="course"
        :edition="edition"
        :units="units"
        :channels="channels"
        :active-unit="moduleId"
      />
    </template>

    <template #main-content>
      <fw-message v-if="errors && errors.length > 0" type="error" class="mb-2">
        {{ errors }}
      </fw-message>
      <LoadingPage v-if="course == null" icon="exam" title="Curso" class="my-28"></LoadingPage>
      <PanelManageCourseEditionDashboard v-else-if="view === 'dashboard'" :course="course" :edition="edition" />
      <PanelManageCourseEditionClass v-else-if="view === 'classes' && edition" :course="course" :edition="edition" />
      <PanelManageCourseEditionModules
        v-else-if="view === 'modules' && edition && moduleId"
        :key="moduleId"
        :course="course"
        :edition="edition"
      />
      <PanelManageCourseEditionModulesAll
        v-else-if="view === 'modules' && edition && !moduleId"
        :course="course"
        :edition="edition"
        @update="units = $event"
      />
      <PanelManageCourseEditionPosts
        v-else-if="(view === 'publications' || view === 'discussions') && edition"
        :key="collectionId"
        :course="course"
        :edition="edition"
        :collection="currentCollection"
        :view="view"
      />
      <PanelManageCourseEditionMetadata
        v-else-if="view === 'metadata' && edition"
        :editable="isManager || isCreator"
        :course="course"
        :edition="edition"
        :permissions="permissions"
        @save="saveEdition"
        @save-silent="saveEdition($event, true)"
      />
      <PanelManageCourseEditionSettings
        v-else-if="view === 'settings' && edition"
        :editable="isManager || isCreator"
        :course="course"
        :edition="edition"
        :permissions="permissions"
        @updated="edition = $event"
        @save="saveEdition"
        @save-silent="saveEdition($event, true)"
      />
      <div v-else>NOT FOUND</div>
    </template>

    <template #modals></template>
  </fw-layout>
</template>

<script>
import PanelManageCourseEditionDashboard from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionDashboard.vue'
import PanelManageCourseEditionSettings from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionSettings.vue'
import PanelManageCourseEditionMetadata from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionMetadata.vue'
import ServicesCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import SidebarManageCourseEdition from '@/fw-modules/fw-core-vue/courses/components/sidebars/manage/SidebarManageCourseEdition'
import PanelManageCourseEditionClass from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionClass.vue'
import PanelManageCourseEditionModules from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionModules.vue'
import PanelManageCourseEditionModulesAll from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionModulesAll.vue'
import PanelManageCourseEditionPosts from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionPosts.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ViewCourseManagementEdition',
  components: {
    SidebarManageCourseEdition,
    PanelManageCourseEditionDashboard,
    PanelManageCourseEditionSettings,
    PanelManageCourseEditionClass,
    PanelManageCourseEditionModules,
    PanelManageCourseEditionPosts,
    PanelManageCourseEditionModulesAll,
    PanelManageCourseEditionMetadata,
  },
  data() {
    return {
      course: null,
      initialLoading: true,
      permissions: {},
      edition: null,
      units: [],
      channels: [],
      errors: '',
    }
  },
  computed: {
    isCreator() {
      return true
    },
    isManager() {
      return true
    },
    courseKey() {
      return this.$route.params.id
    },
    editionKey() {
      return this.$route.params.editionId
    },
    view() {
      return this.$route.meta.view || this.$route.params.view || 'dashboard'
    },
    collectionId() {
      return this.$route.params.collectionId
    },
    currentCollection() {
      return this.channels.find(c => c.key === this.collectionId)
    },
    isFullLayout() {
      return (
        (this.view && ['people', 'agenda', 'publications', 'discussions', 'classes'].includes(this.view)) ||
        (this.view == 'modules' && typeof this.moduleId == 'string')
      )
    },
    moduleId() {
      return this.$route.params.moduleId
    },
  },
  watch: {
    view() {
      this.routerReact()
    },
  },
  async created() {
    await this.loadCourse()
    await this.loadEdition()
    this.initialLoading = false
    this.routerReact()
  },
  methods: {
    openCourse() {
      this.$router.push(`/course/${this.courseKey}/edition/${this.editionKey}`)
    },
    async loadChannels() {
      try {
        let channels = await ServicesCourses.getEditionPostsCollections(this.courseKey, this.editionKey)
        console.log('loadChannels', channels)
        this.channels = channels.data.collections
        if (!this.collectionId) {
          this.$router.push({
            name: 'course-manage-edition-post-collection',
            params: { id: this.courseKey, editionId: this.editionKey, collectionId: this.channels[0].key },
          })
        }
      } catch (e) {
        console.error(e)
        this.showError()
      }
    },
    async loadUnits() {
      try {
        let units = await ServicesCourses.getUnits(this.courseKey, this.editionKey)
        console.log('loadUnits', units)
        this.units = units.data.units
      } catch (e) {
        console.error(e)
        this.showError()
      }
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
    async loadCourse() {
      try {
        let course = await ServicesCourses.getCourse(this.courseKey)
        console.log('course', course)
        this.course = course.data
      } catch (e) {
        console.error(e)
        this.showError()
      }
    },
    async publishCourse() {
      try {
        let course = await ServicesCourses.courseActions(this.courseKey, 'approve')
        console.log('publishCourse', course)
      } catch (e) {
        console.error(e)
        this.showError()
      }
    },
    async saveCourse(data) {
      console.log(data)
      this.course[data.key] = data.value
      try {
        let course = await ServicesCourses.updateCourse(this.courseKey, this.course)
        console.log('update course', course)
        this.course = course.data
        this.$buefy.snackbar.open({
          message: 'Dados guardados.',
          type: 'is-success',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      } catch (e) {
        console.error(e)
        this.showError()
      }
    },
    async saveEdition(data, silence = false) {
      console.log('save Edition', data)
      this.errors = ''
      utils.tryAndCatch(this, async () => {
        let edition = await ServicesCourses.updateEdition(this.courseKey, this.editionKey, data)
        console.log('update edition', edition.data)
        this.edition = edition.data.edition
        if (edition.data['__errors__'] && edition.data['__errors__'].length > 0) {
          this.errors = edition.data['__errors__'][0].detail
        } else if (!silence) {
          this.$buefy.snackbar.open({
            message: 'Dados guardados.',
            type: 'is-primary',
          })
        }
      })
    },
    async saveCourseMetadata(metadata) {
      console.log(metadata)

      this.course.duration = metadata.duration
      this.course.mode = metadata.mode
      this.course.title = metadata.title

      utils.tryAndCatch(this, async () => {
        let course = await ServicesCourses.updateCourse(this.courseKey, this.course)
        console.log('update course', course)
        this.course = course.data
        this.$buefy.snackbar.open({
          message: 'Dados guardados.',
          type: 'is-primary',
        })
      })
    },
    async loadEdition() {
      if (this.editionKey) {
        try {
          let edition = await ServicesCourses.getEdition(this.courseKey, this.editionKey)
          console.log('edition', edition)
          this.edition = edition.data
        } catch (e) {
          console.error(e)
          this.showError()
        }
      }
    },
    routerReact() {
      this.errors = ''
      if (this.view === 'publications' || this.view === 'discussions') {
        this.loadChannels()
      } else if (this.view === 'modules') {
        this.loadUnits()
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "edition": "Edição",
    "editionStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  },
  "en": {
    "edition": "Edition",
    "editionStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  }
}
</i18n>
