<template>
  <div>
    <fw-heading size="h2">Adicionar versão a partir de template</fw-heading>
    <LoadingPlaceholder v-if="searching" class="px-2 py-2" />
    <fw-panel v-if="!searching && searchedExams.length" title="Resultados" :counter="searchedExams.length">
      <RecycleScroller
        v-slot="{ item, index }"
        :items="searchedExams"
        :item-size="55"
        :buffer="200"
        :key-field="'key'"
        class="h-full"
      >
        FORM RECORD<br />
        {{ item }}
      </RecycleScroller>
    </fw-panel>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import { RecycleScroller } from 'vue-virtual-scroller'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
export default {
  name: 'ModalAddVersionFromTemplate',
  components: {
    RecycleScroller,
    LoadingPlaceholder,
  },
  data() {
    return {
      searching: false,
      searchedExams: [],
    }
  },
  created() {
    this.search()
  },
  methods: {
    async search() {
      try {
        let result = await FormServices.getAllForms()
        this.searchedExams = result.data
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>
