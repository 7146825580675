<template>
  <div class="w-96 flex-shrink-0 rounded-3xl bg-panel p-5 flex flex-col gap-5">
    <div class="flex items-center">
      <div class="text-sm text-title font-semibold flex-1">Painel de controlo</div>
      <fw-button
        type="black"
        :loading="loading"
        rounded="left"
        size="sm"
        icon="restart"
        @click.native="$emit('refresh')"
      >
        <template #icon>
          <fw-icon-refresh class="w-4 h-4 text-white" />
        </template>
        <template #default>
          Atualizar
        </template>
      </fw-button>
      <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
          <fw-button type="black" rounded="right" class="opacity-75 hover:opacity-100 flex items-center gap-2">
            {{ autoRefresh || 'Off' }} <fw-icon-chevron-down class="h-4 w-4"></fw-icon-chevron-down>
          </fw-button>
        </template>
        <b-dropdown-item paddingless aria-role="listitem">
          <fw-button type="basic-action" size="sm" expanded left label="Off" @click.native="activateAutoRefresh('off')">
            Off
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-for="(option, o) in refreshOptions" :key="o" paddingless aria-role="listitem">
          <fw-button
            type="basic-action"
            size="sm"
            expanded
            left
            :label="option.label"
            @click.native="activateAutoRefresh(option)"
          >
            {{ option.label }}
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      class="text-center items-center text-white rounded-2xl square-timer"
      :class="{
        'bg-examstatus-inactive': instance.status != 'running',
        'bg-examstatus-active': instance.status === 'running' && timeLeft >= 120,
        'bg-examstatus-orange': instance.status === 'running' && timeLeft < 120 && timeLeft > 0,
        'bg-examstatus-red': instance.status === 'running' && timeLeft <= 0,
      }"
    >
      <div class="timer-content flex flex-col justify-between p-5">
        <div class="flex items-center">
          <ExamsLogo class="h-5"></ExamsLogo>
          <div class="flex-1"></div>
          <div v-if="instance.status === 'running'" class="relative font-bold pr-4">
            A decorrer
            <div class="h-3 w-3 absolute bg-white -top-1 -right-1 rounded-full animate-pulse"></div>
          </div>
          <div v-else class="font-bold text-xs text-gray-300">Não está a decorrer</div>
        </div>
        <div>
          <div v-if="instance.mode != 'remote' && instance.small_key" class="font-bold text-xs">
            {{ $t('access_code') }}
          </div>
          <div v-if="instance.mode != 'remote' && instance.small_key" class="font-mono text-sm mb-2">
            {{ instance.small_key | formatSmallKey }}
          </div>
          <div v-else class="font-mono text-sm mb-2">{{ instance.key }}</div>
          <v-clamp autoresize :max-lines="3" class="font-bold text-xl">
            {{ instance.title[language] }}
          </v-clamp>
        </div>

        <div v-if="instance.status === 'running'">
          <div class="font-bold text-xs">{{ $t('time_left') }}</div>
          <div class="text-3xl font-bold">
            <span v-if="timeLeft < 0">-</span><span v-if="hoursLeft > 0">{{ hoursLeft | time }}:</span
            >{{ minutesLeft | time }}:{{ secondsLeft | time }}
          </div>

          <div class="mt-5 text-xs font-semibold">{{ $t('started') }}</div>
          <div class="text-xs font-medium">{{ instance.startedDate | formatDateTime }}</div>
        </div>
        <div v-else>
          <div class="font-bold text-xs">{{ $t('duration') }}</div>
          <div class="text-3xl font-bold">{{ (instance.duration * 60) | prettySeconds }}</div>

          <div class="mt-5 text-xs font-semibold">{{ $t('starts') }}</div>
          <div class="text-xs font-medium">{{ instance.startDate | formatDateTime }}</div>
        </div>
      </div>
    </div>

    <div
      ref="controls"
      class="relative flex-1 flex flex-col gap-5 justify-start select-none overflow-y-auto hide-scrollbar"
    >
      <div class="flex gap-3 justify-between flex-shrink-0">
        <div class="flex-shrink-0">
          <div class="text-title text-sm mb-0.5">{{ $t('startdate') }}</div>
          <div class="text-md font-bold text-white">{{ instance.startDate | formatDate }}</div>
        </div>
        <div class="flex-shrink-0">
          <div class="text-title text-sm mb-0.5">{{ $t('starttime') }}</div>
          <div class="text-md font-bold text-white">{{ instance.startDate | formatTime }}</div>
        </div>
        <div class="flex-shrink-0">
          <div class="text-title text-sm mb-0.5">{{ $t('duration') }}</div>
          <div class="text-md font-bold text-white">{{ (instance.duration * 60) | prettySeconds }}</div>
        </div>
      </div>
      <div class="flex flex-col gap-3 flex-shrink-0">
        <div class="text-title text-sm flex-shrink-0">{{ $t('versions') }}</div>
        <div
          v-for="(version, v) in versions"
          :key="'version_' + v"
          class="flex gap-3 justify-between text-white items-center flex-shrink-0"
        >
          <div class="bg-version w-9 h-9 leading-9 text-center font-bold text-lg rounded-lg">
            {{ version.letter }}
          </div>
          <div class="flex-shrink-0 font-semibold">
            {{ version.numSections }} {{ version.numSections === 1 ? $t('section') : $t('sections') }}
          </div>
          <div class="flex-shrink-0 font-semibold">
            {{ version.numQuestions }} {{ version.numQuestions === 1 ? $t('question') : $t('questions') }}
          </div>
          <div class="flex gap-1.5">
            <div
              v-for="(lang, l) in version.availableLanguages"
              :key="'lang_' + v + '_' + l"
              class="border border-white uppercase text-xs font-semibold px-1.5 py-0.5 rounded"
            >
              {{ lang }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-3 flex-shrink-0">
        <div class="text-title text-sm">{{ $t('numbers') }}</div>
        <div class="flex gap-3 w-full">
          <div class="bg-inner-card p-3 rounded-lg flex flex-col justify-between text-center w-1/2 h-28">
            <div class="text-title text-sm">{{ $t('present') }}</div>
            <div class="text-primary font-bold text-lg">{{ presences }} / {{ totalPeople }}</div>
            <div class="bg-stats-background-2 w-full h-3 overflow-hidden rounded-full">
              <div class="bg-stats-foreground h-3" :style="{ width: (presences / totalPeople) * 100 + '%' }"></div>
            </div>
          </div>
          <div class="bg-inner-card p-3 rounded-lg flex flex-col justify-between text-center w-1/2 h-28">
            <div class="text-title text-sm">{{ $t('submited') }}</div>

            <div class="text-primary font-bold text-lg">{{ submited }} / {{ presences }}</div>
            <div class="bg-stats-background-2 w-full h-3 overflow-hidden rounded-full">
              <div class="bg-primary h-3" :style="{ width: (submited / totalPeople) * 100 + '%' }"></div>
            </div>
          </div>
        </div>

        <div class="bg-inner-card p-3 rounded-lg">
          <div class="flex gap-3 mb-3">
            <div
              v-for="(version, v) in versions"
              :key="'version_selector_' + v"
              class="h-7 w-7 leading-7 text-center font-bold text-lg rounded-md text-white items-center bg-version cursor-pointer opacity-50 hover:opacity-80"
              :class="{
                'opacity-100': activeVersion === v,
              }"
              @click="activeVersion = v"
            >
              {{ version.letter }}
            </div>
            <div class="flex-1"></div>
            <div
              v-if="statsOverflow"
              class="h-7 w-7 leading-7 text-center font-bold text-lg flex justify-center rounded-md text-white items-center bg-version cursor-pointer opacity-50 hover:opacity-80"
              :class="{
                'opacity-100': statsCanGoBack,
              }"
              @click="prevStats()"
            >
              <fw-icon-arrow-left class="h-5 w-5"></fw-icon-arrow-left>
            </div>
            <div
              v-if="statsOverflow"
              class="h-7 w-7 leading-7 text-center flex justify-center items-center font-bold text-lg rounded-md text-white bg-version cursor-pointer opacity-50 hover:opacity-80"
              :class="{
                'opacity-100': statsCanGoForward,
              }"
              @click="nextStats()"
            >
              <fw-icon-arrow-right class="h-5 w-5"></fw-icon-arrow-right>
            </div>
          </div>
          <div class="text-center text-xs text-white text-opacity-30 mb-0.5">{{ $t('questionanswers') }}</div>
          <div
            v-if="activeVersionData"
            ref="statsContainer"
            class="flex h-32 gap-2 hide-scrollbar"
            :class="{ 'overflow-x-auto justify-start': statsOverflow, 'justify-around': !statsOverflow }"
          >
            <div
              v-for="(stat, n) in activeVersionData.statsQuestiosn"
              :key="activeVersion + '_' + activeVersionData.statsType + '_' + n"
              class="flex w-6 flex-col h-full items-center flex-shrink-0 text-sm font-bold gap-2"
            >
              <div class="flex-shrink-0 color-stats-text text-center">{{ stat.count }}</div>
              <div class="flex flex-col flex-1 justify-end bg-stats-background w-2">
                <div class="bg-stats-foreground w-full" :style="{ height: (stat.count / presences) * 100 + '%' }"></div>
              </div>
              <div class="flex-shrink-0 color-stats-text-secondary text-center">
                {{ stat.question }}
              </div>
            </div>
          </div>
          <div class="text-center text-xs color-stats-text-secondary mt-0.5">{{ $t('questionnumber') }}</div>
        </div>
      </div>
    </div>

    <button class="text-gray-400 font-semibold" @click="$router.back()">Sair do dashboard</button>

    <button
      v-if="instance.status === 'running'"
      class="bg-examstatus-red text-center text-white font-bold py-3 rounded-full"
      @click="startExam('stop', false)"
    >
      {{ $t('stopexam') }}
    </button>
    <button
      v-else
      class="bg-examstatus-active text-center text-white font-bold py-3 rounded-full"
      @click="startExam('start', false)"
    >
      {{ $t('startexam') }}
    </button>
  </div>
</template>
<script>
import ExamsLogo from '@/fw-modules/fw-core-vue/exams/assets/ExamsLogo'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import Vue from 'vue'
export default {
  name: 'PanelFashboardControls',
  components: {
    ExamsLogo,
  },
  filters: {
    time: function(value) {
      if (!value) return '00'
      if (value < 10) {
        return '0' + value
      }
      return value
    },
    formatSmallKey: function(value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(0, 3) + '-' + value.substring(3, 6)
    },
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
    versions: {
      type: Array,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    remainingTime: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      refreshOptions: [
        {
          label: '15s',
          value: 15000,
        },
        {
          label: '30s',
          value: 30000,
        },
        {
          label: '1m',
          value: 60000,
        },
        {
          label: '5m',
          value: 300000,
        },
        {
          label: '10m',
          value: 600000,
        },
        {
          label: '15m',
          value: 900000,
        },
        {
          label: '30m',
          value: 1800000,
        },
      ],
      autoRefreshTimer: null,
      autoRefresh: '',
      activeVersion: 0,
      statsOverflow: false,
      statsCanGoBack: false,
      statsCanGoForward: false,
      timeLeft: 500, //in seconds
      timer: null,
      hoursLeft: 0,
      minutesLeft: 0,
      secondsLeft: 0,
      controlsOveflow: false,
    }
  },
  computed: {
    instanceStatus() {
      return this.instance.status
    },
    submited() {
      return this.answers.filter(a => a.state === 'closed').length
    },
    presences() {
      return this.totalPeople - this.numNoAnswer
    },
    numNoAnswer() {
      return this.answers.filter(a => a.state === 'noAnswer').length
    },
    totalPeople() {
      return this.answers.length
    },
    activeVersionData() {
      return this.versions[this.activeVersion]
    },
  },
  watch: {
    instanceStatus() {
      console.log('Instance status changed')
      this.chectActivateTimer()
    },
    remainingTime(val) {
      if (this.timeLeft != val) {
        console.log('Remaining time syncronized with backend')
        //sincronize remaining time with backend
        this.timeLeft = val
      }
    },
  },
  created() {
    this.chectActivateTimer()
  },
  mounted() {
    Vue.nextTick(() => {
      this.verifyStatsOverflow()
      this.verifyControlsOverflow()
    })
  },
  methods: {
    activateAutoRefresh(option) {
      if (option === 'off') {
        this.autoRefresh = ''
        clearInterval(this.autoRefreshTimer)
        return
      }

      this.autoRefresh = option.label
      if (this.autoRefreshTimer != null) {
        clearInterval(this.autoRefreshTimer)
      }
      this.autoRefreshTimer = setInterval(() => {
        this.$emit('refresh')
      }, option.value)
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
    chectActivateTimer() {
      if (this.instance.status == 'running') {
        this.startTimer()
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    async startExam(status = 'start', forceAction = false) {
      if (this.instance.status === 'draft') {
        this.$buefy.dialog.confirm({
          title: this.$t('startnotpossible'),
          message: this.$t('examneedstobepublished'),
          confirmText: 'Ok',
          type: 'is-success',
        })
        return
      }

      if (this.instance.status === 'running') {
        if (!forceAction) {
          this.$buefy.dialog.confirm({
            title: this.$t('endexam'),
            message: this.$t('endexamconfirmation'),
            confirmText: this.$t('endexambutton'),
            type: 'is-danger',
            onConfirm: () => this.startExam(status, true),
          })
          return
        }
        status = 'stop'
      }

      if (status === 'start' && !forceAction) {
        this.$buefy.dialog.confirm({
          title: 'Iniciar exame',
          message: 'Tem a certeza que pretende iniciar o exame?',
          confirmText: 'Iniciar',
          type: 'is-primary',
          onConfirm: () => this.startExam(status, true),
        })
        return
      }

      console.log('startExam', status)
      //start | stop
      try {
        this.publishing = true
        let response = await FormServices.publishInstance(this.instance.key, status)
        console.log(response)
        this.instance.status = status === 'start' ? 'running' : 'ended'
        if (status === 'start') {
          this.startTimer()
        } else {
          this.stopTimer()
        }
        this.permissions = response.permissions
        //this.$emit('update-permissions', response.permissions)
        this.publishing = false
      } catch (e) {
        console.error(e)
        this.showError(
          this.$t('therewasaproblem') +
            ' ' +
            (status === 'start' ? this.$t('problemstarting') : this.$t('problemending'))
        )
      }
    },
    toClipboardExamLink() {
      this.$copyText(this.examLink).then(() => {
        this.copied = true

        if (this.clipboard_timer !== null) {
          clearTimeout(this.clipboard_timer)
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: `Link copiado para a área de transferência.`,
            position: 'is-top',
          })
        }

        this.clipboard_timer = setTimeout(() => {
          this.copied = false
          this.clipboard_timer = null
        }, 3000)
      })
    },
    startTimer() {
      if (this.timer != null) {
        clearInterval(this.timer)
      }
      let timeLeft = this.instance.duration * 60
      let now = new Date()
      if (this.instance.startedDate) {
        let startedDate = new Date(this.instance.startedDate)
        let diff = now.getTime() - startedDate.getTime()
        let diffSeconds = Math.round(diff / 1000)
        timeLeft = timeLeft - diffSeconds
      }

      this.timeLeft = timeLeft

      this.timer = setInterval(() => {
        this.timeLeft = this.timeLeft - 1
        let diff = Math.abs(this.timeLeft)
        let hours = Math.floor(diff / 3600)
        let minutes = Math.floor((diff % 3600) / 60)
        let seconds = Math.floor((diff % 3600) % 60)
        this.hoursLeft = hours
        this.minutesLeft = minutes
        this.secondsLeft = seconds
      }, 1000)
    },
    verifyStatsOverflow() {
      console.log('REFS', this.$refs)
      this.statsOverflow = this.$refs.statsContainer.scrollWidth > this.$refs.statsContainer.clientWidth
      this.statsCanGoForward = true
    },
    verifyControlsOverflow() {
      let container = this.$refs.controls
      if (container) {
        this.controlsOveflow = container.scrollHeight > container.clientHeight
        if (this.controlsOveflow) {
          container.addEventListener('scroll', () => {
            if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
              this.controlsOveflow = false
            } else {
              this.controlsOveflow = true
            }
          })
        }
      } else {
        this.controlsOveflow = false
      }
    },
    prevStats() {
      this.$refs.statsContainer.scrollBy({
        left: -100,
        behavior: 'smooth',
      })
      this.statsCanGoBack = this.$refs.statsContainer.scrollLeft > 0
    },
    nextStats() {
      this.$refs.statsContainer.scrollBy({
        left: 100,
        behavior: 'smooth',
      })
      this.statsCanGoForward = this.$refs.statsContainer.scrollLeft < this.$refs.statsContainer.scrollWidth
    },
  },
}
</script>
<i18n>
    {
      "pt": {
        "time_left": "Tempo restante",
        "access_code": "Código de acesso",
        "endexam": "Terminar o exame",
        "endexamconfirmation": "Tem a certeza que deseja terminar o exame?",
        "endexambutton": "Terminar",
        "startnotpossible":"Não é possível iniciar o exame",
        "examneedstobepublished": "O exame precisa de ser publicado antes de iniciar.",
        "therewasaproblem": "Ocorreu um problema a",
        "problemstarting": "começar o exame",
        "problemending": "terminar o exame",
        "running": "A decorrer",
        "started": "Iniciou às",
        "duration": "Duração prevista",
        "starts": "Previsto iniciar",
        "startdate": "Data prevista",
        "starttime": "Início previsto",
        "versions": "Versões",
        "section": "Secção",
        "sections": "Secções",
        "question": "Questão",
        "questions": "Questões",
        "numbers": "Números",
        "present": "Presentes",
        "submited": "Submetidos",
        "questionnumber": "Nº da pergunta",
        "stopexam": "Terminar exame",
        "startexam": "Iniciar exame",
        "questionanswers": "Nº de respostas"
      },
      "en": {
        "time_left": "Time left",
        "access_code": "Access code",
        "endexam": "End exam",
        "endexamconfirmation": "Are you sure you want to end the exam?",
        "endexambutton": "End exam",
        "startnotpossible":"It is not possible to start the exam",
        "examneedstobepublished": "The exam needs to be published before starting.",
        "therewasaproblem": "The was a problem",
        "problemstarting": "starting the exam",
        "problemending": "ending the exam",
        "running": "Running",
        "started": "Started at",
        "duration": "Predicted duration",
        "starts": "Is predicted to start at",
        "startdate": "Predicted date",
        "starttime": "Predicted start",
        "versions": "Versions",
        "section": "Section",
        "sections": "Sections",
        "question": "Question",
        "questions": "Questions",
        "numbers": "Numbers",
        "present": "Present",
        "submited": "Submited",
        "questionnumber": "Question number",
        "stopexam": "End exam",
        "startexam": "Start exam",
        "questionanswers": "Number of questions"
      }
    }
</i18n>

<style>
.bg-examstatus-active {
  background-color: #41a89a;
}

.bg-examstatus-red {
  background-color: #ef6161;
}

.bg-examstatus-orange {
  background-color: #f09000;
}

.bg-examstatus-inactive {
  background-color: #383838;
}
</style>
