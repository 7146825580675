import ViewEditor from './views/ViewEditor'
import ViewPages from './views/ViewPages'
import ViewInvite from './views/ViewInvite'
export default [
  {
    path: '/pages/edit/:key/:version',
    name: 'content-pages-editor-with-version',
    component: ViewEditor,
    meta: { edit: true },
  },
  {
    path: '/pages/translate/:key/:version',
    name: 'content-pages-translator',
    component: ViewEditor,
    meta: { edit: true, translator: true },
  },
  {
    path: '/pages/edit/:key',
    name: 'content-pages-editor',
    component: ViewEditor,
    meta: { edit: true },
  },
  {
    path: '/pages/:key/:version',
    name: 'content-pages-viewer-with-version',
    component: ViewEditor,
    meta: { edit: false },
  },
  {
    path: '/pages/:key',
    name: 'content-pages-viewer',
    component: ViewEditor,
    meta: { edit: false },
  },
  {
    path: '/pages',
    name: 'content-pages',
    component: ViewPages,
    meta: { edit: false },
  },
  {
    path: '/pages/:key/invite/:token',
    name: 'invite-view',
    component: ViewInvite,
    meta: { edit: false, requiresAuth: false },
  },
]
