<template>
  <fw-panel :title="isExam ? 'Novo exame' : 'Nova versão'">
    <div v-if="page == 0" class="flex flex-col pb-5">
      <fw-label>De que forma quer começar?</fw-label>
      <div class="flex gap-5 text-sm flex-1 mt-2">
        <button
          :class="{
            'border-primary text-primary opacity-80': type == 'blank',
            'hover:text-primary border-gray-100 hover:border-gray-200 opacity-50 hover:opacity-90': type != 'blank',
          }"
          class="pt-5 border rounded-lg text-center justify-center px-4 py-5 w-full gap-3 flex flex-col items-center group transition duration-150 font-semibold"
          @click="selectType('blank')"
        >
          <div>
            <svg
              class="h-12 w-12 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M15 4H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992zM11 11V8h2v3h3v2h-3v3h-2v-3H8v-2h3z"
              />
            </svg>
          </div>
          <h3>{{ isExam ? 'Exame em branco' : 'Nova versão em branco' }}</h3>
        </button>
        <button
          :class="{
            'border-primary text-primary opacity-80': type == 'template',
            'hover:text-primary border-gray-100 hover:border-gray-200 opacity-50 hover:opacity-90': type != 'template',
          }"
          class="pt-5 border rounded-lg text-center justify-center px-4 py-5 w-full gap-3 flex flex-col items-center group transition duration-150 font-semibold"
          @click="selectType('template')"
        >
          <div>
            <svg
              class="h-12 w-12 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M14.997 2L21 8l.001 4.26a5.471 5.471 0 0 0-2-1.053L19 9h-5V4H5v16h5.06a4.73 4.73 0 0 0 .817 2H3.993a.993.993 0 0 1-.986-.876L3 21.008V2.992c0-.498.387-.927.885-.985L4.002 2h10.995zM17.5 13a3.5 3.5 0 0 1 3.5 3.5l-.001.103a2.75 2.75 0 0 1-.581 5.392L20.25 22h-5.5l-.168-.005a2.75 2.75 0 0 1-.579-5.392L14 16.5a3.5 3.5 0 0 1 3.5-3.5zm0 2a1.5 1.5 0 0 0-1.473 1.215l-.02.14L16 16.5v1.62l-1.444.406a.75.75 0 0 0 .08 1.466l.109.008h5.51a.75.75 0 0 0 .19-1.474l-1.013-.283L19 18.12V16.5l-.007-.144A1.5 1.5 0 0 0 17.5 15z"
              />
            </svg>
          </div>
          <h3>{{ isExam ? 'A partir de exame anterior' : 'A partir de template' }}</h3>
        </button>
      </div>

      <b-field v-if="isExam" label="Nome do exame" class="mt-5">
        <b-input v-model="examName"></b-input>
      </b-field>
      <b-field v-if="isExam && false" label="Idioma principal" class="mt-1">
        <div>
          <b-radio v-model="language" name="language" native-value="pt">
            Português
          </b-radio>
          <b-radio v-model="language" name="language" native-value="en">
            Inglês
          </b-radio>
        </div>
      </b-field>
      <div v-if="isExam && false" class="text-sm">
        Poderá acrescentar um idioma adicional nas definições do exame após criação.
      </div>
    </div>
    <div v-else class="flex flex-col">
      <LoadingPlaceholder v-if="searching" class="px-2 py-2" />
      <fw-panel
        v-if="!searching && searchedExams.length"
        class="flex-1 overflow-y-auto"
        featured
        title="Templates existentes"
        subtitle="Selecione um template para criar o novo exame:"
        :counter="totalResults"
      >
        <div style="max-height: 400px;">
          <RecycleScroller
            v-slot="{ item, index }"
            :items="searchedExams"
            :item-size="40"
            :buffer="200"
            :key-field="'key'"
            class="h-full"
          >
            <div
              :class="{
                'text-primary bg-teal-500 bg-opacity-10': selectedTemplate != null && selectedTemplate.key == item.key,
              }"
              class="flex gap-3 items-center rounded-md px-3 py-1 h-10 hover:bg-gray-100 cursor-pointer border-b border-gray-100"
              @click="selectedTemplate = item"
            >
              <div class="flex-1 flex items-center gap-2" :class="{ 'text-gray-500': item.title == null }">
                <fw-icon-checkbox-circle-solid
                  v-if="selectedTemplate != null && selectedTemplate.key == item.key"
                  class="w-5 h-5 text-primary"
                />
                <fw-icon-checkbox-circle-blank v-else class="w-5 h-5 text-gray-400" />
                <v-clamp autoresize :max-lines="1" class="font-semibold">
                  {{ item.title != null ? item.title : 'Sem título' }}
                </v-clamp>
              </div>
              <div class="text-sm text-gray-500 font-medium">
                {{ item.createdDate | formatDateTime }}
              </div>
            </div>
          </RecycleScroller>
        </div>
      </fw-panel>
      <fw-panel-info v-else centered clean empty>
        Não tem exames anteriores publicados ou agendados para importar.
      </fw-panel-info>
      <BlockPagination
        v-if="totalPages > 1"
        :per-page="limit"
        :total="totalResults"
        :total-pages="totalPages"
        :current.sync="pageResult"
        @page-changed="pageChanged"
      />
    </div>
    <div class="flex flex-shrink-0 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="previous()">
        {{ page == 1 ? 'Anterior' : 'Cancelar' }}
      </fw-button>
      <fw-button :type="!allowNext ? 'disabled' : 'primary'" :disable="!allowNext" class="w-32" @click.native="next()">
        {{ type != 'blank' && page == 1 ? 'Usar template' : type == 'blank' ? 'Criar' : 'Seguinte' }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import { RecycleScroller } from 'vue-virtual-scroller'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
export default {
  name: 'ModalNewVersion',
  components: {
    RecycleScroller,
    LoadingPlaceholder,
    BlockPagination,
  },
  props: {
    isExam: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      examName: '',
      language: 'pt',
      page: 0,
      pageResult: 1,
      totalPages: 0,
      totalResults: 0,
      limit: 30,
      type: 'blank',
      selectedTemplate: null,
      searchedExams: [],
      searching: false,
    }
  },
  computed: {
    allowNext() {
      return (
        (this.isExam && this.examName.length > 0 && this.page == 0) ||
        (this.isExam && this.examName.length > 0 && this.page == 1 && this.selectedTemplate != null) ||
        !this.isExam
      )
    },
    havePreviousInstances() {
      return !this.searching && this.searchedExams
    },
  },
  methods: {
    selectType(type) {
      this.type = type
    },
    pageChanged(page) {
      this.pageResult = page
      this.search()
    },
    async search() {
      try {
        this.searching = true
        let result = await FormServices.getAllForms(this.pageResult, this.limit)
        console.log(result)
        this.searchedExams = result.forms
        this.totalResults = result.pagination.total_items
        this.totalPages = result.pagination.total_pages
      } catch (e) {
        console.error(e)
      } finally {
        this.searching = false
      }
    },
    next() {
      if (this.isExam && this.page == 0) {
        //verify name
        if (this.examName.length == 0) {
          return
        }
      }

      if (this.type == 'blank') {
        this.$emit('new-from-blank', {
          key: null,
          name: this.examName,
          language: this.language,
        })
      } else if (this.type != 'blank' && this.page == 0) {
        this.page++
        this.search()
      } else if (this.type != 'blank' && this.page == 1) {
        if (this.selectedTemplate != null) {
          let template = this.selectedTemplate
          this.$emit('new-from-template', {
            key: template.key,
            name: this.examName,
            language: this.language,
          })
        }
      }
    },
    previous() {
      if (this.page == 0) {
        this.$emit('close')
      } else {
        this.page = 0
      }
    },
  },
}
</script>
