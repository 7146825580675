<template>
  <div class="placeholder-input flex items-center gap-3">
    <fw-icon-calendar class="h-5 w-5 text-gray-400" /> {{ placeholder[language] }}
    <div class="h-2.5 w-2.5 bg-gray-200 rounded-full"></div>
    00:00
  </div>
</template>

<script>
export default {
  name: 'PlaceholderDateTime',
  props: {
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Escolha uma data e hora',
          en: 'Choose a date and time',
        }
      },
    },
    language: {
      type: String,
      default: 'pt',
    },
  },
}
</script>
