<template>
  <div
    class="bg-white cursor-not-allowed flex gap-2 rounded-md items-center flex-col p-4 border border-dashed border-gray-300 text-gray-600"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      class="fill-current w-8 h-8 opacity-50"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path
        d="M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z"
      ></path>
    </svg>
    <span class="text-sm opacity-50">Carregar ficheiros</span
    ><span class="text-xs opacity-50">ou arraste para esta zona</span>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderFileUpload',
  props: {},
}
</script>
