import ViewManageCourses from './views/manage/ViewManageCourses'
import ViewManageCourse from './views/manage/ViewManageCourse'
//import ViewManageCourseEditionClass from './views/manage/ViewManageCourseEditionClass'
import ViewManageCourseEdition from './views/manage/ViewManageCourseEdition'
//import ViewManageCourseEditionParticipants from './views/manage/ViewManageCourseEditionParticipants'

export default [
  {
    path: '/manage/courses',
    name: 'courses-manage',
    component: ViewManageCourses,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/manage/course/:id/edition/:editionId/classes/:classKey/:subview?',
    name: 'course-manage-edition-class',
    component: ViewManageCourseEdition,
    meta: { requiresRole: 'teacher', view: 'classes' },
  },
  {
    path: '/manage/course/:id/edition/:editionId/modules/:moduleId/:topicId?',
    name: 'course-manage-edition-module-topic',
    component: ViewManageCourseEdition,
    meta: { requiresRole: 'teacher', view: 'modules' },
  },
  {
    path: '/manage/course/:id/edition/:editionId/:view/collection/:collectionId/:postId?',
    name: 'course-manage-edition-post-collection',
    component: ViewManageCourseEdition,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/manage/course/:id/edition/:editionId/:view?',
    name: 'course-manage-edition',
    component: ViewManageCourseEdition,
    meta: { requiresRole: 'teacher' },
  },
  {
    path: '/manage/course/:id/:view?',
    name: 'course-manage',
    component: ViewManageCourse,
    meta: { requiresRole: 'teacher' },
  },
]
