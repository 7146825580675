<template>
  <div class="placeholder-input">
    {{ placeholder[language] }}
  </div>
</template>

<script>
export default {
  name: 'PlaceholderTextInput',
  props: {
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Insira aqui o seu número de telefone',
          en: 'Enter here your phone number',
        }
      },
    },
    language: {
      type: String,
      default: 'pt',
    },
  },
}
</script>
