<template>
  <DashboardCard class="p-2 device-card">
    <div class="flex flex-1 items-center gap-2">
      <div class="text-left leading-5 flex-1 text-white">
        <div class="flex gap-1 pl-1 py-0.5 flex-col justify-start">
          <v-clamp autoresize :max-lines="1" class="font-semibold">
            {{ device.device.reference_key }}
          </v-clamp>
          <div v-if="device.description" class="text-xs secondary-text">
            {{ device.device.description }}
          </div>
          <div class="flex justify-start gap-4 text-xs text-white font-semibold text-opacity-60 items-center">
            <span v-if="device.device.checkout_date">Recolhido às {{ device.device.checkout_date | formatTime }}</span>
            <span v-else class="text-red-500">Não recolhido</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center gap-3">
      <div class="leading-5 text-right flex-1 flex flex-col items-end text-white">
        <div class="flex gap-2 mb-0.5">
          <v-clamp autoresize :max-lines="1" class="font-semibold">
            {{ getPersonName() }}
          </v-clamp>
          <div v-if="user.number" class="text-xs secondary-text">
            {{ user.number }}
          </div>
        </div>
        <div class="text-sm secondary-text font-normal flex gap-2 items-center">
          <div>{{ user.email }}</div>
        </div>
      </div>
      <fw-avatar class="mr-1.5 flex-shrink-0" size="sm" custom-class="force-rounded'" :user="user" />
    </div>
  </DashboardCard>
</template>
<script>
import DashboardCard from './DashboardCard.vue'
export default {
  name: 'DeviceCard',
  components: {
    DashboardCard,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inactiveStatusColor() {
      return this.device.state == 'withdraw'
        ? '#EF4444'
        : this.device.state == 'closed' || this.device.state == 'close'
        ? '#30a898'
        : 'rgba(255, 255, 255, 0.6)'
    },
    statusMessage() {
      return this.device.state == 'noAnswer'
        ? 'Sem resposta'
        : this.device.state == 'closed' || this.device.state == 'close'
        ? 'Entregue'
        : this.device.state == 'withdraw'
        ? 'Desistiu'
        : this.device.state == 'answering'
        ? 'Em curso'
        : this.device.state ?? ''
    },
    activityType() {
      return 'Estado'
    },
    user() {
      return this.device.user
    },
  },
  methods: {
    getPersonName() {
      return this.user.display_name || this.user.full_name
    },
  },
}
</script>

<style>
.force-rounded {
  border-radius: 0.45rem !important;
}
.device-card .secondary-text {
  color: #cccccc;
}
</style>
