<template>
  <div class="flex gap-5 w-full flex-wrap">
    <PlaceholderBase class="w-44" :label="document_type[language]">
      <div class="placeholder-input">
        {{ document_type_placeholder[language] }}
        <div class="bg-white absolute top-1 right-0 bottom-1 py-1 pr-1.5 pl-2 flex flex-col items-center">
          <fw-icon-chevron-up class="h-5 w-5" />
          <fw-icon-chevron-down class="h-5 w-5" />
        </div>
      </div>
    </PlaceholderBase>
    <PlaceholderBase class="flex-1" :label="document_number[language]">
      <div class="placeholder-input">
        {{ placeholder[language] }}
      </div>
    </PlaceholderBase>
  </div>
</template>

<script>
import PlaceholderBase from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderBase'

export default {
  name: 'PlaceholderTextInput',
  components: {
    PlaceholderBase,
  },
  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Insira o número do documento',
          en: 'Enter here the document number',
        }
      },
    },
    language: {
      type: String,
      default: 'pt',
    },
  },
  data() {
    return {
      document_type: {
        pt: 'Tipo de documento',
        en: 'Document type',
      },
      document_number: {
        pt: 'Número do documento',
        en: 'Document number',
      },
      document_type_placeholder: {
        pt: 'Selecione o tipo de documento',
        en: 'Select the document type',
      },
    }
  },
}
</script>
