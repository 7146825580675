<template>
  <fw-layout back-to="/units" mobile-ready>
    <template #main-sidebar>
      <SidebarUnit :unit="unitEdition" :view="view" @go-to-view="goToView" />
    </template>
    <template #main-content>
      <fw-panel v-if="view == 'classes'" title="Turmas" featured>
        <PanelUnitClassesList :unit="unitEdition" :classes="classEditions" />
      </fw-panel>
      <fw-panel v-if="view == 'buckets'" title="Materiais de apoio" featured>
        <PanelBucketList
          paddingless
          :application-context="{
            application: 'academic',
            item_type: 'unit_edition', //, 'unit_edition'],
            include_unit_buckets: unitEdition.unit_key_for_bucket, //UNIT BUCKET KEY
            item_key: unitEdition.key,
          }"
        />
      </fw-panel>
    </template>
    <template #tapbar>
      <TapbarUnit :view="view" @go-to-view="goToView" />
    </template>
  </fw-layout>
</template>

<script>
import TapbarUnit from '@/fw-modules/fw-core-vue/academic/components/tapbars/TapbarUnit'
import SidebarUnit from '@/fw-modules/fw-core-vue/academic/components/sidebars/SidebarUnit'
import PanelUnitClassesList from '@/fw-modules/fw-core-vue/academic/components/panels/PanelUnitClassesList'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import PanelBucketList from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelBucketList'

export default {
  components: {
    SidebarUnit,
    TapbarUnit,
    PanelUnitClassesList,
    PanelBucketList,
  },

  data() {
    this.loadClasses()
    return {
      loading: false,
      unitEdition: null,
      classEditions: [],
      view: 'classes',
    }
  },

  watch: {
    $route() {
      if (this.$route.params.subpage && this.view !== this.$route.params.subpage) {
        this.view = this.$route.params.subpage
      }
    },
  },

  methods: {
    goToView(name) {
      this.view = name
      let url = '/unit/' + this.$route.params.key + '/' + name
      this.$router.push({ path: url })
    },
    async loadClasses() {
      try {
        this.loading = true
        let response = await ServiceAcademic.getTeacherUnitClassEditions(this.$route.params.key)
        this.unitEdition = response.unit_edition
        this.classEditions = response.editions
      } finally {
        this.loading = false
      }
      if (this.$route.params.subpage) {
        this.view = this.$route.params.subpage
      }
    },
  },
}
</script>
