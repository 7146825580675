<template>
  <div class="flex gap-5 p-3 items-center exam-record hover:bg-gray-100" :class="{ 'border-b border-gray-100': true }">
    <!-- Title -->
    <div class="flex-1 cursor-pointer" @click="$emit('open')">
      <div class="flex gap-2 text-left items-center">
        <fw-icon-class-unit class="text-gray-400 h-5 w-5 item-arrow" />
        <div>
          <v-clamp
            v-if="data.title != null && data.title.length > 0"
            autoresize
            :max-lines="1"
            class="text-black font-semibold "
          >
            {{ data.title }}
          </v-clamp>
          <div v-else class="text-gray-400">Sem título</div>
        </div>
      </div>
      <div class="text-xs text-gray-500 flex gap-2 ml-7">
        <div>Criado em {{ data.created_date | formatDateTime }}</div>
      </div>
    </div>
    <!-- Stats -->
    <div class="flex gap-1 items-center">
      <!-- Type -->
      <div v-if="!agenda" class="flex items-center gap-1 bg-gray-200 rounded-full px-2 py-0.5">
        <div class="text-xs text-gray-500">Tópico {{ labels[data.type] }}</div>
      </div>
      <div
        v-if="agenda && agendaMode == 'scheduled'"
        class="flex items-center gap-1 bg-gray-200 rounded-full px-2 py-0.5"
      >
        <div v-if="startDate && endDate" class="text-xs text-gray-500">
          {{ startDate | formatDate }} - {{ endDate | formatDate }}
        </div>
        <div v-else class="text-xs text-gray-500">
          Não agendado
        </div>
        <fw-icon-clock v-if="duration && duration > 0" class="h-3 w-3 text-gray-500" />
        <div v-if="duration && duration > 0" class="text-xs text-gray-500">{{ duration }} min</div>
      </div>
      <div v-if="agenda && agendaMode == 'manual'" class="flex items-center gap-1  rounded-full px-2 py-0.5">
        <div>
          <fw-tag :type="startDate != null ? 'light-primary' : 'light-danger'">{{
            startDate != null ? 'Disponível' : 'Indisponível'
          }}</fw-tag>
        </div>
        <fw-icon-clock v-if="duration && duration > 0" class="h-3 w-3 text-gray-500" />
        <div v-if="duration && duration > 0" class="text-xs text-gray-500">{{ duration }} min</div>
      </div>
      <b-dropdown v-if="!agenda || agendaMode == 'manual'" aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
          <fw-button size="sm" class="opacity-50" :class="{ 'opacity-100': active }">
            <fw-icon-more class="w-5 h-5" />
          </fw-button>
        </template>
        <b-dropdown-item v-if="!agenda" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center gap-2"
            label="Editar título"
            @click.native="$emit('edit')"
          >
            <fw-icon-edit class="w-5 h-5" /> Editar título
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="!agenda" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center justify-start gap-2"
            label="Eliminar"
            @click.native="$emit('delete')"
          >
            <fw-icon-trash class="w-5 h-5" /> Eliminar
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="agendaMode == 'manual'" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center justify-start gap-2"
            label="Tornar visível"
            @click.native="$emit('visibility', startDate == null)"
          >
            {{ startDate == null ? 'Disponibilizar tópico' : 'Indisponibilizar tópico' }}
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
      <fw-icon-chevron-right
        v-if="data.page_key != null && !agenda"
        class="text-gray-400 h-6 w-6 item-arrow"
        @click="$emit('open')"
      />
      <div v-else-if="!agenda" class="w-6"></div>
      <fw-button v-if="agenda && agendaMode == 'scheduled'" type="link" @click.native="$emit('open')">
        Agendar
      </fw-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordTopic',
  //components: { TextStatus },
  props: {
    data: {
      type: Object,
    },
    agenda: {
      type: Boolean,
      default: false,
    },
    agendaMode: {
      type: String,
      default: 'scheduled',
    },
    sessions: {
      type: Object,
    },
    unitKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      labels: {
        normal: 'normal',
        evaluation: 'avaliação',
        quality_evaluation: 'questionário de qualidade',
      },
    }
  },
  computed: {
    currentSession() {
      if (this.sessions && this.sessions[this.unitKey] && this.sessions[this.unitKey][this.data.key]) {
        return this.sessions[this.unitKey][this.data.key]
      }
      return null
    },
    startDate() {
      if (this.currentSession && this.currentSession.start_date) {
        return this.currentSession.start_date
      }
      return null
    },
    endDate() {
      if (this.currentSession && this.currentSession.end_date) {
        return this.currentSession.end_date
      }
      return null
    },
    duration() {
      if (this.currentSession && this.currentSession.exam && this.currentSession.exam.duration) {
        return this.currentSession.exam.duration
      }
      return null
    },
  },
  watch: {
    sessions: {
      handler() {
        console.log('sessions changed!!', this.sessions)
        this.$forceUpdate()
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
