<template>
  <div>
    <TextHeadingDual :main-text="'Configurações'" :secondary-text="secondaryText" class="mb-2"></TextHeadingDual>
    <fw-panel
      title="Metadados do exame"
      boxed="sm"
      class="my-5"
      :loading="updating.includes('exam') && saving"
      :after-loading-checked="updating.includes('exam') && savingSuccess"
    >
      <template v-if="canEdit" #toolbar>
        <div class="flex gap-3">
          <fw-button v-if="!editing.includes('exam')" :type="'link'" @click.native="edit('exam')">Editar</fw-button>
          <fw-button v-else :type="'link-light'" @click.native="cancel('exam')">Cancelar</fw-button>
          <fw-button v-if="editing.includes('exam')" :type="'regular'" @click.native="save('exam')">Guardar</fw-button>
        </div>
      </template>
      <div class="flex flex-col gap-5">
        <div>
          <fw-label custom-class="mt-0">Título do exame</fw-label>
          <TranslatedInput
            v-if="editing.includes('exam')"
            :key="'title_editing_' + tmp_instance.languages.length"
            :value.sync="tmp_instance.title"
            :multiline="false"
            :placeholder="{
              pt: 'Nome do exame em Português',
              en: 'Nome do exame em Inglês',
            }"
            @input="changeInstance('title', $event)"
          ></TranslatedInput>
          <div
            v-else
            :class="{
              'text-base font-medium': title.pt && title.pt.length > 0,
              'text-sm text-gray-400': typeof title.pt == 'undefined' || title.pt.length == 0,
            }"
          >
            {{ examTitle.length > 0 ? examTitle : 'Sem título' }}
          </div>
        </div>

        <div>
          <div><fw-label>Modo de acesso ao exame</fw-label></div>
          <b-dropdown v-if="editing.includes('exam')" aria-role="list" :disabled="!canEdit">
            <fw-button-dropdown slot="trigger">
              {{ tmp_instance && tmp_instance.options && tmp_instance.options.isPublic ? 'Aberto' : 'Restrito' }}
            </fw-button-dropdown>

            <b-dropdown-item aria-role="listitem" @click="changeInstanceOptions('isPublic', false)"
              >Restrito</b-dropdown-item
            >
            <b-dropdown-item aria-role="listitem" @click="changeInstanceOptions('isPublic', true)"
              >Aberto</b-dropdown-item
            >
          </b-dropdown>
          <div v-else class="text-base font-medium">
            {{ tmp_instance && tmp_instance.options && tmp_instance.options.isPublic ? 'Aberto' : 'Restrito' }}
          </div>
          <div
            v-if="tmp_instance && tmp_instance.options && tmp_instance.options.isPublic"
            class="mt-2 text-sm text-gray-500"
          >
            No modo <span class="font-bold">Aberto</span> qualquer pessoa com o link podem entrar no exame.
          </div>
          <div v-else class="mt-2 text-sm text-gray-500">
            No modo <span class="font-bold">Restrito</span> apenas os utilizadores definidos na lista de participantes
            podem entrar no exame.
          </div>
        </div>

        <div>
          <div><fw-label>Tipo de exame</fw-label></div>
          <div class="flex items-center gap-5">
            <div>
              <b-dropdown v-if="editing.includes('exam')" aria-role="list" :disabled="!canEdit">
                <fw-button-dropdown
                  slot="trigger"
                  :type="tmp_instance && tmp_instance.mode === 'remote' ? 'light' : 'primary'"
                >
                  {{ tmp_instance && tmp_instance.mode === 'remote' ? 'Remoto' : 'Presencial' }}
                </fw-button-dropdown>

                <b-dropdown-item aria-role="listitem" @click="changeInstance('mode', 'presential')">
                  Presencial</b-dropdown-item
                >
                <b-dropdown-item aria-role="listitem" @click="changeInstance('mode', 'remote')">Remoto</b-dropdown-item>
              </b-dropdown>
              <div v-else class="text-base font-medium">
                {{ tmp_instance && tmp_instance.mode === 'remote' ? 'Remoto' : 'Presencial' }}
              </div>
            </div>
            <div v-if="tmp_instance && tmp_instance.mode !== 'remote'" class="text-primary font-medium">
              Exclusivo na <span class="font-bold">UC DigitalPad</span>
            </div>
          </div>

          <div
            v-if="tmp_instance && tmp_instance.mode && tmp_instance.mode == 'remote'"
            class="mt-2 text-sm text-gray-500"
          >
            Um exame do tipo <span class="font-bold">Remoto</span> pode ser realizado através da UC Teacher ou UC
            Student.<br />Para exames onde seja necessário garantir o máximo controlo da participação dos utilizadores,
            esta opção pode não ser a mais adequada.
          </div>
          <div v-else class="mt-2 text-sm text-gray-500">
            <p>
              No modo <span class="font-bold">Presencial</span> o exame apenas poderá ser realizado exclusivamente com
              recurso à solução <b class="text-primary">UC DigitalPad</b>, através da utilização de tablets no local do
              exame.<br />Esta opção garante o máximo controlo da participação dos utilizadores.
            </p>
            <p>
              Se pretender realizar um exame nestas condições, por favor, contacte a nossa equipa de suporte, através do
              endereço <a href="mailto:support@ucframework.pt">support@ucframework.pt</a>.
            </p>
          </div>
        </div>
        <div v-if="tmp_instance && tmp_instance.mode && tmp_instance.mode != 'remote'">
          <div><fw-label>Unidade Orgânica</fw-label></div>
          <div class="flex items-center gap-5">
            <div>
              <b-dropdown v-if="editing.includes('exam')" aria-role="list" :disabled="!canEdit">
                <fw-button-dropdown slot="trigger" :type="'light'">
                  {{ currentOrganicUnit }}
                </fw-button-dropdown>

                <b-dropdown-item
                  v-for="organic_unit in organicUnits"
                  :key="'org_' + organic_unit.key"
                  aria-role="listitem"
                  @click="changeOrganitUnit(organic_unit)"
                >
                  {{ organic_unit.title }}</b-dropdown-item
                >
              </b-dropdown>
              <div v-else class="text-base font-medium">
                {{ currentOrganicUnit }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="false">
          <fw-label>Idiomas disponíveis</fw-label>
          <div v-if="editing.includes('exam')">
            <div class="flex gap-3 items-center">
              <fw-tag
                v-for="lang in tmp_instance.languages"
                :key="'lang_editing_' + lang"
                :type="'light-border-box'"
                size="sm"
                class="flex gap-1 uppercase "
                :class="{
                  'cursor-pointer': tmp_instance.languages.length == 2,
                }"
                @click.native="deactivateLanguage(lang)"
              >
                {{ lang }} <fw-icon-close v-if="tmp_instance.languages.length == 2" class="h-5 w-5"></fw-icon-close>
              </fw-tag>
              <fw-button
                v-if="tmp_instance.languages.length == 1"
                type="xlight"
                @click.native="activateLanguage(tmp_instance.languages[0] === 'pt' ? 'en' : 'pt')"
              >
                Ativar versão em {{ tmp_instance.languages[0] === 'pt' ? 'Inglês' : 'Português' }}
              </fw-button>
            </div>
          </div>
          <div v-else-if="tmp_instance" class="flex gap-3">
            <fw-tag
              v-for="lang in tmp_instance.languages"
              :key="'lang_' + lang"
              :type="'light-border-box'"
              size="sm"
              class="flex gap-1 uppercase"
            >
              {{ lang }}
            </fw-tag>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel
      title="Calendarização"
      boxed="sm"
      class="my-5"
      :loading="updating.includes('calendar') && saving"
      :after-loading-checked="updating.includes('calendar') && savingSuccess"
    >
      <template v-if="canEdit" #toolbar>
        <div class="flex gap-3">
          <fw-button v-if="!editing.includes('calendar')" :type="'link'" @click.native="edit('calendar')"
            >Editar</fw-button
          >
          <fw-button v-else :type="'link-light'" @click.native="cancel('calendar')">Cancelar</fw-button>
          <fw-button v-if="editing.includes('calendar')" :type="'regular'" @click.native="save('calendar')"
            >Guardar</fw-button
          >
        </div>
      </template>
      <div class="flex flex-col gap-5">
        <div>
          <fw-label>Data e hora de início prevista</fw-label>
          <div>
            <div v-if="editing.includes('calendar')">
              <b-datetimepicker
                v-model="startDate"
                placeholder="Insira a data & hora de início"
                :locale="'pt-BR'"
                :timepicker="{
                  incrementMinutes: 5,
                }"
                :min-datetime="minDatetime"
                editable
                @input="changeInstanceOptions('startDate', $event)"
              >
              </b-datetimepicker>
            </div>
            <div
              v-else
              :class="{ 'text-base font-medium': startDate != null, 'text-sm text-yellow-500': startDate == null }"
            >
              {{ startDate != null ? startDateString : 'Sem data e hora de início definida.' }}
            </div>
          </div>
        </div>
        <div>
          <fw-label>Duração prevista</fw-label>
          <div>
            <div v-if="editing.includes('calendar')">
              <b-input
                v-model="tmp_instance.duration"
                type="number"
                placeholder="Insira a duração do exame em minutos"
                @input="changeInstanceOptions('duration', $event)"
              >
              </b-input>
            </div>
            <div v-else class="text-base font-medium">
              {{ tmp_instance && tmp_instance.duration ? tmp_instance.duration : '60' }} minutos
            </div>
          </div>
          <div
            v-if="false && tmp_instance && tmp_instance.mode && tmp_instance.mode !== 'remote'"
            class="mt-2 text-sm text-gray-500"
          >
            Os exames presenciais são <b>terminados manualmente</b> pelo responsável pelo exame e a duração prevista é
            apenas indicativa.<br />Durante o exame presencial, caso ultrapassada a duração, o tempo irá continuar a
            contar em negativo.
          </div>
        </div>
        <div>
          <fw-label>Local</fw-label>
          <div>
            <div v-if="editing.includes('calendar')">
              <b-input
                v-model="tmp_instance.location"
                type="text"
                placeholder="Insira o local do exame"
                @input="changeInstanceOptions('location', $event)"
              ></b-input>
            </div>
            <div
              v-else
              :class="{
                'text-sm text-gray-500':
                  tmp_instance == null || tmp_instance.location == null || tmp_instance.location.length == 0,
                'text-base font-medium': tmp_instance && tmp_instance.location && tmp_instance.location.length > 0,
              }"
            >
              {{ tmp_instance && tmp_instance.location ? tmp_instance.location : 'Sem local definido.' }}
            </div>
          </div>
        </div>

        <div>
          <fw-label>Automatizações</fw-label>

          <div v-if="editing.includes('calendar')">
            <b-checkbox v-model="tmp_instance.options.autoStart" :disabled="!canEdit" @input="setDirty()">
              Início do exame automático
            </b-checkbox>
          </div>
          <div v-else-if="tmp_instance" class="flex gap-2 items-center">
            <faicon
              style="width: 20px"
              :class="{
                'text-gray-200': !tmp_instance.options.autoStart,
                'has-text-primary': tmp_instance.options.autoStart,
              }"
              class="is-size-4 has-margin-right-small"
              :icon="'check'"
            ></faicon>
            {{
              tmp_instance.options.autoStart
                ? 'O exame irá iniciar automaticamente'
                : 'O exame tem de ser iniciado manualmente'
            }}
          </div>

          <div v-if="editing.includes('calendar')">
            <b-checkbox v-model="tmp_instance.options.autoEnd" :disabled="!canEdit" @input="setDirty()">
              Fim do exame automático
            </b-checkbox>
          </div>
          <div v-else-if="tmp_instance" class="flex gap-2 items-center">
            <faicon
              style="width: 20px"
              :class="{
                'text-gray-200': !tmp_instance.options.autoEnd,
                'has-text-primary': tmp_instance.options.autoEnd,
              }"
              class="is-size-4 has-margin-right-small"
              :icon="'check'"
            ></faicon>
            {{
              tmp_instance.options.autoEnd
                ? 'O exame irá terminar automaticamente'
                : 'O exame tem de ser terminado manualmente'
            }}
          </div>
          <div class="text-sm text-gray-500 mt-2">
            Nota: Ao terminar automaticamente o exame é dada uma tolerância até 1 minuto no fim do mesmo. Antes de
            ativar as automatizações do exame verifique que a data de início e duração estão correctas antes de guardar.
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel
      title="Avaliação"
      boxed="sm"
      class="my-5"
      :loading="updating.includes('evaluation') && saving"
      :after-loading-checked="updating.includes('evaluation') && savingSuccess"
    >
      <template v-if="canEdit" #toolbar>
        <div class="flex gap-3">
          <fw-button v-if="!editing.includes('evaluation')" :type="'link'" @click.native="edit('evaluation')"
            >Editar</fw-button
          >
          <fw-button v-else :type="'link-light'" @click.native="cancel('evaluation')">Cancelar</fw-button>
          <fw-button v-if="editing.includes('evaluation')" :type="'regular'" @click.native="save('evaluation')"
            >Guardar</fw-button
          >
        </div>
      </template>
      <div class="flex flex-col gap-5">
        <div>
          <fw-heading size="h4">Pontuação</fw-heading>
          <fw-label>Escala do exame:</fw-label>
          <div>
            <b-dropdown v-if="editing.includes('evaluation')" aria-role="list" :disabled="!canEdit">
              <fw-button-dropdown slot="trigger">
                <span> {{ tmp_formOptions.scale === 100 ? '0 - 100' : '0 - 20' }}</span>
              </fw-button-dropdown>
              <b-dropdown-item aria-role="listitem" @click="changeFormOptions('scale', 100)"> 0 - 100</b-dropdown-item>
              <b-dropdown-item aria-role="listitem" @click="changeFormOptions('scale', 20)">0 - 20</b-dropdown-item>
            </b-dropdown>
            <div v-else class="text-base font-medium">
              0 - {{ tmp_formOptions && tmp_formOptions.scale ? tmp_formOptions.scale : '100' }}
            </div>
          </div>

          <fw-label>Cada opção certa nas escolhas múltiplas vale:</fw-label>
          <TextInput
            v-if="editing.includes('evaluation')"
            v-model="tmp_formOptions.pointsCorrectOption"
            @change="changeFormOptions('pointsCorrectOption', $event)"
          >
            <template #prefix>+</template>
            <template #suffix>valores</template>
          </TextInput>
          <div v-else class="text-base font-medium">
            +{{ tmp_formOptions && tmp_formOptions.pointsCorrectOption ? tmp_formOptions.pointsCorrectOption : '1' }}
            valores
          </div>
        </div>

        <div v-if="false">
          <b-checkbox
            v-if="false && editing.includes('evaluation')"
            v-model="tmp_formOptions.negativePoints"
            :disabled="!canEdit"
            @input="setDirty()"
          >
            Escolhas múltiplas com opções erradas a descontar
          </b-checkbox>
          <div v-else-if="tmp_formOptions" class="flex items-center gap-3">
            <fw-icon-error-warning-solid
              v-if="tmp_formOptions.negativePoints"
              class="h-6 text-red-500 "
            ></fw-icon-error-warning-solid>
            <faicon
              v-else
              style="width: 20px"
              class="is-size-4 has-margin-right-small text-gray-200"
              :icon="'times'"
            ></faicon>
            {{ tmp_formOptions.negativePoints ? 'As opções erradas descontam' : 'As opções erradas não descontam' }}
          </div>

          <div v-if="tmp_formOptions && tmp_formOptions.negativePoints">
            <fw-label>Cada opção errada desconta:</fw-label>
            <TextInput
              v-if="editing.includes('evaluation')"
              v-model="tmp_formOptions.pointsWrongOption"
              @change="changeFormOptions('pointsWrongOption', $event)"
            >
              <template #prefix>-</template>
              <template #suffix>valores</template>
            </TextInput>
            <div v-else class="text-base font-medium">
              -{{ tmp_formOptions && tmp_formOptions.pointsWrongOption ? tmp_formOptions.pointsWrongOption : '1' }}
              valores
            </div>
          </div>

          <b-checkbox
            v-if="tmp_formOptions && tmp_formOptions.negativePoints && editing.includes('evaluation')"
            v-model="tmp_formOptions.limitNegativePoints"
            :disabled="!canEdit"
            @input="setDirty()"
          >
            Limitar pontuação negativa a cada pergunta
          </b-checkbox>
          <div
            v-else-if="
              !editing.includes('evaluation') &&
                tmp_formOptions &&
                tmp_formOptions.negativePoints &&
                tmp_formOptions.limitNegativePoints
            "
          >
            O mínimo de pontos que uma pergunta pode dar é 0
          </div>
          <div
            v-else-if="
              !editing.includes('evaluation') &&
                tmp_formOptions &&
                tmp_formOptions.negativePoints &&
                !tmp_formOptions.limitNegativePoints
            "
            class="flex items-center gap-3"
          >
            <fw-icon-error-warning-solid class="w-6 text-red-500"></fw-icon-error-warning-solid>
            Os pontos negativos de uma pergunta passam para a nota do exame, pelo que um participante poderá ter uma
            nota inferior a 0.
          </div>
        </div>

        <div>
          <fw-heading size="h4" class="mb-2">Aleatoriedade</fw-heading>
          <div v-if="editing.includes('evaluation')">
            <b-checkbox v-model="tmp_formOptions.randomMultiplechoiceOptions" :disabled="!canEdit" @input="setDirty()">
              Opções das escolhas múltiplas aleatórias
            </b-checkbox>
          </div>
          <div v-else-if="tmp_formOptions" class="flex gap-2 items-center">
            <faicon
              style="width: 20px"
              :class="{
                'text-gray-200': !tmp_formOptions.randomMultiplechoiceOptions,
                'has-text-primary': tmp_formOptions.randomMultiplechoiceOptions,
              }"
              class="is-size-4 has-margin-right-small"
              :icon="'check'"
            ></faicon>
            {{
              tmp_formOptions.randomMultiplechoiceOptions
                ? 'As opções das escolhas múltiplas são aleatórias'
                : 'As opções das escolhas múltiplas não são aleatórias'
            }}
          </div>

          <b-checkbox
            v-if="editing.includes('evaluation')"
            v-model="tmp_formOptions.randomSections"
            :disabled="!canEdit"
            @input="setDirty()"
          >
            Perguntas aleatórias
          </b-checkbox>
          <div v-else-if="tmp_formOptions" class="flex gap-2 items-center">
            <faicon
              style="width: 20px"
              :class="{
                'text-gray-200': !tmp_formOptions.randomSections,
                'has-text-primary': tmp_formOptions.randomSections,
              }"
              class="is-size-4 has-margin-right-small"
              :icon="'check'"
            ></faicon>
            {{
              tmp_formOptions.randomSections
                ? 'As perguntas do exame são aleatórias'
                : 'As perguntas do exame não são aleatórias'
            }}
          </div>
        </div>

        <div v-if="tmp_instance && tmp_instance.mode && tmp_instance.mode !== 'remote'">
          <fw-heading size="h4" class="mb-2">Opções adicionais</fw-heading>
          <div v-if="editing.includes('evaluation')">
            <b-checkbox v-model="tmp_instance.options.validateIdentity" :disabled="!canEdit" @input="setDirty()">
              Tirar foto do participante a realizar o exame
            </b-checkbox>
          </div>
          <div v-else-if="tmp_formOptions" class="flex gap-2 items-center">
            <faicon
              style="width: 20px"
              :class="{
                'text-gray-200': !tmp_instance.options.validateIdentity,
                'has-text-primary': tmp_instance.options.validateIdentity,
              }"
              class="is-size-4 has-margin-right-small"
              :icon="'check'"
            ></faicon>
            {{
              tmp_instance.options.validateIdentity
                ? 'Registo fotogrático do participante'
                : 'Sem registo fotogrático do participante'
            }}
          </div>
        </div>

        <!--
        <b-checkbox
          v-if="editing == 'evaluation'"
          v-model="tmp_instance.options.logInteractions"
          :disabled="!canEdit"
          @input="setDirty()"
        >
          Ecrã inteiro obrigatório
        </b-checkbox>
        <div v-else-if="tmp_formOptions" class="flex gap-2 ">
          <faicon
            style="width: 25px"
            :class="{
              'text-gray-200': !tmp_instance.options.logInteractions,
              'has-text-primary': tmp_instance.options.logInteractions,
            }"
            class="is-size-3 has-margin-right-small"
            :icon="tmp_instance.options.logInteractions ? 'check' : 'times'"
          ></faicon>
          {{
            tmp_instance.options.logInteractions
              ? 'Ecrã inteiro obrigatório'
              : 'Não é necessário realizar o exame em ecrã inteiro'
          }}
        </div>
        <b-checkbox v-if="editing == 'evaluation' " v-model="tmp_formOptions.disableRightClick" :disabled="!canEdit">
          Proteger exame contra burlas
        </b-checkbox>
        <div v-else-if="tmp_formOptions" class="flex gap-2">
          <faicon
            style="width: 25px"
            :class="{
              'text-gray-200': !tmp_formOptions.disableRightClick,
              'has-text-primary': tmp_formOptions.disableRightClick,
            }"
            class="is-size-3 has-margin-right-small"
            :icon="tmp_formOptions.disableRightClick ? 'check' : 'times'"
          ></faicon>
          {{
            tmp_formOptions.disableRightClick ? 'Proteção contra burlas ativada' : 'Proteção contra burlas desativada'
          }}
        </div>-->
      </div>
    </fw-panel>

    <fw-panel
      v-if="false"
      title="Notificações"
      boxed="sm"
      class="my-5"
      :loading="updating.includes('notifications') && saving"
      :after-loading-checked="updating.includes('notifications') && savingSuccess"
    >
      <template v-if="canEdit" #toolbar>
        <div class="flex gap-3">
          <fw-button v-if="!editing.includes('notifications')" :type="'link'" @click.native="edit('notifications')"
            >Editar</fw-button
          >
          <fw-button v-else :type="'link-light'" @click.native="cancel('notifications')">Cancelar</fw-button>
          <fw-button v-if="editing.includes('notifications')" :type="'regular'" @click.native="save('notifications')"
            >Guardar</fw-button
          >
        </div>
      </template>
      <div class="flex flex-col gap-5">
        <b-checkbox
          v-if="editing.includes('notifications') && tmp_instance"
          v-model="tmp_instance.options.sendNotification24h"
          :disabled="!canEdit"
          @input="setDirty()"
        >
          Enviar notificação aos participantes no dia anterior à data prevista para realização do exame
        </b-checkbox>
        <div v-else class="flex gap-2">
          <faicon
            style="width: 25px"
            :class="{
              'text-gray-200': !tmp_instance.options.sendNotification24h,
              'has-text-primary': tmp_instance.options.sendNotification24h,
            }"
            class="is-size-3 has-margin-right-small"
            :icon="'check'"
          ></faicon>
          Enviar notificação aos participantes no dia anterior à data prevista para realização do exame
        </div>

        <b-checkbox
          v-if="editing.includes('notifications')"
          v-model="tmp_instance.options.sendNotification1h"
          :disabled="!canEdit"
          @input="setDirty()"
        >
          Enviar notificação aos participantes 1h antes do exame
        </b-checkbox>

        <div v-else class="flex gap-2">
          <faicon
            style="width: 25px"
            :class="{
              'text-gray-200': !tmp_instance.options.sendNotification1h,
              'has-text-primary': tmp_instance.options.sendNotification1h,
            }"
            class="is-size-3 has-margin-right-small"
            :icon="'check'"
          ></faicon>
          Enviar notificação aos participantes 1h antes do exame
        </div>
      </div>
    </fw-panel>

    <fw-panel v-if="permissions && permissions.delete" title="Gestão do exame" boxed="sm" class="my-5">
      <div class="border border-red-500 rounded-lg p-4">
        <h2 class="text-red-500">Zona de perigo</h2>
        <fw-label :color="'text-red-500'">Eliminar exame e dados</fw-label>
        <p class="mb-2 text-sm">Esta ação é irreversível e irá apagar todos os dados associados ao exame.</p>
        <fw-button type="danger" :loading="deleting" @click.native="deleteInstance">
          Apagar exame
        </fw-button>
      </div>
    </fw-panel>
  </div>
</template>
<script>
import TextHeadingDual from '../text/TextHeadingDual.vue'
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import Vue from 'vue'
import ServiceExams from '../../services/ServiceExams'
import ServiceContent from '@/fw-modules/fw-core-vue/content/services/ServiceContent'

export default {
  name: 'PanelExamSettings',
  components: {
    TextHeadingDual,
    TranslatedInput,
    TextInput,
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },

    saving: {
      type: Boolean,
      default: false,
    },
    savingSuccess: {
      type: Boolean,
      default: false,
    },
    subsection: {
      type: String,
      default: null,
    },
  },
  data: function() {
    return {
      startDate: null,
      editing: [],
      tmp_instance: null,
      tmp_formOptions: null,
      updating: [],
      deleting: false,
      organicUnits: [],
    }
  },
  computed: {
    currentOrganicUnit() {
      return this.organicUnits.find(unit => unit.key === this.tmp_instance.organic_unit_key)?.title ?? 'Não definida'
    },
    minDatetime() {
      const date = new Date()
      //set midnight
      date.setHours(0, 0, 0, 0)
      return date
    },
    examTitle() {
      return this.tmp_instance &&
        this.tmp_instance.languages.includes('pt') &&
        this.tmp_instance.title.pt &&
        this.tmp_instance.title.pt.length > 0
        ? this.tmp_instance.title.pt
        : this.tmp_instance?.title.en ?? ''
    },
    dirtyData() {
      return this.$store.state.dirtyData
    },
    allowDeleteLanguage() {
      return this.processedLanguages.length > 1
    },
    processedLanguages: {
      get() {
        return this.tmp_instance.languages.map(lang => {
          return {
            value: lang,
            text: lang === 'pt' ? 'Português' : 'Inglês',
          }
        })
      },
      set(newValue) {
        this.tmp_instance.languages = newValue.map(el => el.value)
      },
    },
    secondaryText() {
      return '' /* this.subsection == 'exam'
        ? 'Exame'
        : this.subsection == 'evaluation'
        ? 'Avaliação'
        : this.subsection == 'calendar'
        ? 'Calendarização'
        : 'Notificações' */
    },
    canEdit() {
      return this.permissions != null && this.permissions.edit
    },
    startDateString() {
      return this.startDate ? this.$options.filters.formatDateTime(this.startDate) : ''
    },
    title() {
      let title = { pt: '' }
      if (this.availableLanguages.length > 1) {
        title['en'] = ''
      }
      if (this.instance.title['pt']) {
        title.pt = this.instance.title['pt']
      }
      if (this.instance.title['en']) {
        title.en = this.instance.title['en']
      }

      return title
    },
    availableLanguages() {
      return this.instance.languages
    },
    creator() {
      return this.users[this.instance.user_key] ?? null
    },
    managers() {
      return Object.keys(this.instance.managers).map(manager => this.users[manager])
    },
  },
  watch: {
    savingSuccess(newVal) {
      if (newVal) {
        this.editing = []
        Vue.nextTick(() => {
          this.updating = []
          this.$store.commit('setDirtyData', false)
        })
      }
    },
    form(newVal) {
      this.tmp_formOptions = newVal.options
    },
    instance(newVal) {
      this.tmp_instance = this.validateInstance(newVal)
    },
    subsection() {
      this.resetSettings()
    },
  },
  mounted() {
    this.resetSettings()
    this.loadOrganitUnits()
  },
  methods: {
    changeOrganitUnit(organic_unit) {
      Vue.set(this.tmp_instance, 'organic_unit_key', organic_unit.key)
      this.setDirty()
    },
    async loadOrganitUnits() {
      try {
        let result = await ServiceContent.getDevicesOrganicUnits()
        console.log('Organic Units', result)
        this.organicUnits = result.data
      } catch (error) {
        console.error('Error loading organic units', error)
      }
    },
    deleteInstance() {
      this.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Apagar',
        title: 'Apagar exame',
        type: 'is-danger',
        message:
          'Tem a certeza que pretende apagar este exame? Esta ação é irreversível e irá apagar todos os dados associados ao exame.',
        onConfirm: async () => {
          try {
            await ServiceExams.deleteInstance(this.instance.key)
            this.$buefy.toast.open({
              message: 'Exame apagado com sucesso',
              type: 'is-success',
              position: 'is-bottom-right',
            })
            this.$router.push({ name: 'exams' })
          } catch (error) {
            console.error('Error deleting instance', error)
            this.$buefy.toast.open({
              message: 'Erro ao apagar exame',
              type: 'is-danger',
              position: 'is-bottom-right',
            })
          }
        },
      })
    },
    resetSettings() {
      this.$store.commit('setDirtyData', false)
      this.editing = []
      this.startDate = this.instance.startDate ? new Date(this.instance.startDate) : null
      this.tmp_instance = this.validateInstance(this.instance)
      this.tmp_formOptions = this.form.options
    },
    activateLanguage(language, force = false) {
      if (!force) {
        this.$buefy.dialog.confirm({
          cancelText: 'Cancelar',
          confirmText: 'Confirmar',
          title: 'Ativar idioma',
          message:
            'Tem a certeza que pretende ativar este idioma? Terá de introduzir todo o conteúdo desse idioma em todas as versões para publicar o exame.',
          onConfirm: () => {
            this.activateLanguage(language, true)
          },
        })
        return
      }
      if (!this.tmp_instance.languages.includes(language)) {
        if (!this.tmp_instance.title[language]) {
          this.tmp_instance.title[language] = ''
        }
        this.tmp_instance.languages.push(language)
        this.setDirty()
      }
    },
    deactivateLanguage(language, force = false) {
      if (!force) {
        this.$buefy.dialog.confirm({
          cancelText: 'Cancelar',
          confirmText: 'Confirmar',
          title: 'Desativar idioma',
          message:
            'Tem a certeza que pretende desativar este idioma? Todo o conteúdo desse idioma será eliminado em todas as versões.',
          onConfirm: () => {
            this.deactivateLanguage(language, true)
          },
        })
        return
      }
      if (this.tmp_instance.languages.includes(language)) {
        if (this.tmp_instance.title[language]) {
          Vue.delete(this.tmp_instance.title, language)
        }
        this.tmp_instance.languages = this.tmp_instance.languages.filter(lang => lang != language)
        this.setDirty()
      }
    },
    save(type) {
      this.updating.push(type)
      if (type == 'exam' || type == 'calendar' || type == 'notifications') {
        if (type == 'calendar' && this.startDate != null) {
          this.tmp_instance['startDate'] = this.startDate.toISOString()
        }
        this.$emit('save-instance', this.tmp_instance)
      } else if (type == 'evaluation') {
        this.$emit('save-instance', this.tmp_instance)
        this.$emit('save-form', this.tmp_formOptions)
      } else {
        console.error('type not defined')
      }
    },
    cancel(type) {
      //restore data from instance
      if (type == 'exam' || type == 'calendar' || type == 'notifications') {
        this.tmp_instance = JSON.parse(JSON.stringify(this.instance))
      } else if (type == 'evaluation') {
        this.tmp_instance = JSON.parse(JSON.stringify(this.instance))
        this.tmp_formOptions = JSON.parse(JSON.stringify(this.form.options))
      } else if (type == 'all') {
        this.tmp_instance = JSON.parse(JSON.stringify(this.instance))
        this.tmp_formOptions = JSON.parse(JSON.stringify(this.form.options))
      } else {
        console.error('type not defined')
      }
      //remove type from the editing list
      this.editing = this.editing.filter(el => el != type)
    },
    edit(type) {
      //chech if we are already editing something
      //if (this.editing.includes(type)) {
      //  this.cancel('all')
      //}
      if (!this.editing.includes(type)) {
        this.editing.push(type)
      }
      //this.editing[type] = !this.editing[type]
    },
    setDirty() {
      this.$store.commit('setDirtyData', true)
    },
    changeInstance(input, value) {
      console.log('CHANGING INSTANCE', input, value)
      this.$store.commit('setDirtyData', true)
      //this.$emit('change-instance', [input, value])
      this.tmp_instance[input] = input === 'language' ? value.map(el => el.value) : value
    },
    changeInstanceOptions(input, value) {
      this.$store.commit('setDirtyData', true)
      //console.log('inline - changeInstanceOptions', input, value)
      //this.$emit('change-instance-options', [input, value])
      this.tmp_instance.options[input] = value
    },
    toogleInstanceOptions(input) {
      this.$store.commit('setDirtyData', true)
      //this.$emit('toogle-instance-options', input)
      this.tmp_instance.options[input] = !this.tmp_instance.options[input]
    },
    changeFormOptions(input, value) {
      this.$store.commit('setDirtyData', true)
      //this.$emit('change-form-options', [input, value])
      this.tmp_formOptions[input] = value
    },
    toogleFormOptions(input) {
      this.$store.commit('setDirtyData', true)
      //this.$emit('toogle-form-options', input)
      this.tmp_formOptions[input] = !this.tmp_formOptions[input]
    },
    validateInstance(instance) {
      if (instance != null) {
        for (let index = 0; index < instance.languages.length; index++) {
          const language = instance.languages[index]
          if (typeof instance.title[language] == 'undefined') {
            instance.title[language] = ''
          }
        }
      }
      return instance
    },
  },
}
</script>
