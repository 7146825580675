<template>
  <PanelNotifications
    :get-notifications-api-function="getNotificationsApi"
    :get-notification-api-function="getNotificationApi"
    :show-export-toolbar="false"
  />
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'
import CoursesServices from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'

export default {
  components: {
    PanelNotifications,
  },

  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    courseKey() {
      return this.course.key
    },
  },
  methods: {
    async getNotificationApi(key) {
      return await CoursesServices.getNotification(this.courseKey, key)
    },
    async getNotificationsApi(params) {
      return await CoursesServices.getNotifications(this.courseKey, params)
    },
  },
}
</script>
