import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlContent
    return api
  },
  getDevicesOrganicUnits() {
    return this.base().get('/v1/devices/organic-units')
  },
  //get user groups
  getGroups(params) {
    return this.base().get('/v1/groups', { params })
  },
  //search groups
  searchGroups(params) {
    return this.base().get('/v1/groups/search', { params })
  },
  createGroup(data) {
    /* 
    data:
      title: string
      description: string
      public: boolean
      auto_accept_join: boolean
    */
    return this.base().post('/v1/groups/create', data)
  },
  getGroup(key) {
    return this.base().get(`/v1/groups/${key}`)
  },
  updateGroup(key, data) {
    /* 
    data:
      title: string
      description: string
      public: boolean
      auto_accept_join: boolean
      cover_file_key: string
    */
    return this.base().put(`/v1/groups/${key}`, data)
  },
  deleteGroup(key) {
    return this.base().delete(`/v1/groups/${key}`)
  },
  joinGroup(key) {
    //{{contentHost}}/v1/groups/{{contentGroupKey}}/join
    return this.base().post(`/v1/groups/${key}/join`)
  },
  leaveGroup(key) {
    //{{contentHost}}/v1/groups/{{contentGroupKey}}/leave
    return this.base().delete(`/v1/groups/${key}/join`)
  },
  getPendingUsers(key, page = 1) {
    //{{contentHost}}/v1/groups/{{contentGroupKey}}/pending_join
    return this.base().get(`/v1/groups/${key}/pending_join`, { params: { page } })
  },
  //get group users
  getGroupUsers(key, page = 1) {
    //{{contentHost}}/v1/groups/{{contentGroupKey}}/users
    return this.base().get(`/v1/groups/${key}/users`, { params: { page } })
  },
  //search users in a group
  searchGroupUsers(key, params) {
    ///v1/groups/{{contentGroupKey}}/users/search?q=Tomás&exclude[]=0zj4ts&exclude[]=0r3asx&limit=10
    return this.base().get(`/v1/groups/${key}/users/search`, { params })
  },
  //add user to group
  addUsersToGroup(key, user_keys = []) {
    ///v1/groups/{{contentGroupKey}}/users
    //data: {user_key: string}
    return this.base().post(`/v1/groups/${key}/users`, user_keys)
  },
  removeUserFromGroup(key, user_key) {
    ///v1/groups/{{contentGroupKey}}/users/{{userKey}}
    return this.base().delete(`/v1/groups/${key}/users/${user_key}`)
  },
  approveUserInGroup(key, user_keys = []) {
    //{{contentHost}}/v1/groups/{{contentGroupKey}}/pending_join
    // body array of user_keys
    return this.base().post(`/v1/groups/${key}/pending_join`, user_keys)
  },
  disapproveUserInGroup(key, user_keys = []) {
    //{{contentHost}}/v1/groups/{{contentGroupKey}}/pending_join/{{userKey}}
    return this.base().delete(`/v1/groups/${key}/pending_join/`, user_keys)
  },
  changeGroupOwner(key, user_key) {
    ///v1/groups/{{contentGroupKey}}/owner
    //data: {user_key: string}
    return this.base().put(`/v1/groups/${key}`, {
      user_key: user_key,
    })
  },
  subscribeCommunity(connectionId, action = 'subscribe_community', communityKey) {
    // POST {{contentHost}}
    // action: subscribe_community | unsubscribe_community
    return this.base().post(`/v1/ws`, {
      code: action,
      connectionId: connectionId,
      body: {
        group_key: communityKey,
      },
    })
  },
}
