<template>
  <DashboardCard class="p-2 answer-card" :data-letter="userLetter">
    <div class="flex flex-1 items-center gap-2">
      <fw-avatar
        class="mr-1.5 flex-shrink-0"
        size="md"
        custom-class=" force-rounded force-h-14 force-w-14 '"
        :user="user"
      />
      <div class="text-left leading-5 flex-1 text-white">
        <div class="flex gap-2 mb-0.5">
          <v-clamp autoresize :max-lines="1" class="font-semibold">
            {{ getPersonName() }}
          </v-clamp>
          <div v-if="user.number" class="text-xs secondary-text">
            {{ user.number }}
          </div>
        </div>
        <div class="text-sm secondary-text font-normal flex gap-2 items-center">
          <div>{{ user.email }}</div>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-between p-1 gap-2">
      <div class="flex justify-end gap-4 text-xs text-white text-opacity-60 items-center">
        <span v-if="answer.closed_date && answer.state == 'closed'"
          >Terminou às {{ answer.closed_date | formatTime }}</span
        >
        <span v-else-if="answer.started_date">Iniciou às {{ answer.started_date | formatTime }}</span>
        <span v-else>Sem data de início</span>
        <div class="w-7 flex justify-center text-opacity-100">
          <b-tooltip :active="answer.alarm" :label="alarmReason" type="is-dark" position="is-left">
            <fw-icon-alarm
              class="h-5 w-5"
              :style="{
                color: answer.alarm ? '#F09000' : '#777777',
              }"
            ></fw-icon-alarm>
          </b-tooltip>
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <TextStatus
          class="text-sm"
          :active-color="'#F09000'"
          :inactive-color="inactiveStatusColor"
          :active="answer.state == 'answering'"
          :add-padding-right="true"
          >{{ statusMessage }}</TextStatus
        >
        <div class="text-white text-lg">
          <span class=" font-bold ">{{ answeredQuestions }}</span
          ><span> / {{ totalQuestions }}</span>
        </div>
        <div class="w-7 h-7 leading-7 text-center rounded-md font-bold bg-white bg-opacity-30 text-white ">
          {{ versionLetter }}
        </div>
      </div>
    </div>
  </DashboardCard>
</template>
<script>
import DashboardCard from './DashboardCard.vue'
import TextStatus from '../text/TextStatus.vue'
export default {
  name: 'AnswerCard',
  components: {
    DashboardCard,
    TextStatus,
  },
  props: {
    answer: {
      type: Object,
      required: true,
    },
    versions: {
      type: Array,
      required: true,
    },
    versionsMap: {
      type: Object,
      required: true,
    },
  },
  computed: {
    alarmReason() {
      return this.answer.alarm_reason ?? 'Sem alarme'
    },
    userLetter() {
      return this.user.name.substring(0, 1).toUpperCase()
    },
    version() {
      return this.versions[this.versionsMap[this.answer.formKey]]
    },
    answeredQuestions() {
      return this.answer.summary.answers ?? 0
    },
    totalQuestions() {
      return this.version ? this.version.numQuestions : 0
    },
    versionLetter() {
      return this.version ? this.version.letter : ''
    },
    inactiveStatusColor() {
      return this.answer.state == 'withdraw'
        ? '#EF4444'
        : this.answer.state == 'closed' || this.answer.state == 'close'
        ? '#30a898'
        : 'rgba(255, 255, 255, 0.6)'
    },
    statusMessage() {
      return this.answer.state == 'noAnswer'
        ? 'Sem resposta'
        : this.answer.state == 'closed' || this.answer.state == 'close'
        ? 'Entregue'
        : this.answer.state == 'withdraw'
        ? 'Desistiu'
        : this.answer.state == 'answering'
        ? 'Em curso'
        : this.answer.state ?? ''
    },
    activityType() {
      return 'Estado'
    },
    user() {
      return this.answer.user
    },
  },
  methods: {
    getPersonName() {
      return this.user.display_name || this.user.full_name
    },
  },
}
</script>

<style>
.force-rounded {
  border-radius: 0.45rem !important;
}
.force-h-14 {
  height: 3.5rem !important;
}
.force-w-14 {
  width: 3.5rem !important;
}
.answer-card .secondary-text {
  color: #cccccc;
}
</style>
