<template>
  <fw-panel title="Modificar ordem">
    <div class="flex flex-col">
      <Container
        group-name="topics"
        drop-class="card-ghost-drop"
        :drop-placeholder="dropPlaceholderOptions"
        :should-animate-drop="() => true"
        :get-child-payload="index => getChildPayload(index)"
        drag-handle-selector=".drag-handle-new"
        class="flex flex-col gap-1"
        @drop="onDropSection($event)"
      >
        <Draggable
          v-for="input in tmpContent"
          :key="'input_' + input.key"
          class=" bg-gray-100 rounded-lg px-5 py-2.5 relative box-question"
        >
          <div class="drag-handle-new top-2.5">
            <svg
              class="fill-current h-3 w-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 16"
              width="10"
              height="16"
            >
              <path
                fill-opacity="0.13"
                d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
              />
            </svg>
          </div>
          {{ input.title }}
        </Draggable>
      </Container>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button :type="'primary'" class="w-32" @click.native="close()">
        Ok
      </fw-button>
    </div>
  </fw-panel>
</template>
<script>
import { Container, Draggable } from 'vue-dndrop'
export default {
  name: 'ModalChangeOrder',
  components: {
    Container,
    Draggable,
  },
  props: {
    endpoint: {
      type: Function,
    },
    content: {
      type: Array,
    },
  },
  data() {
    return {
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
      tmpContent: [],
    }
  },
  created() {
    this.tmpContent = JSON.parse(JSON.stringify(this.content))
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getChildPayload(index) {
      //console.log('getChildPayload', p, s, index)
      return this.tmpContent[index]
    },
    onDropSection(dropResult) {
      console.log('onDropSection', dropResult)
      this.endpoint(dropResult.payload.key, dropResult.addedIndex)
      let result = this.applyDrag(this.tmpContent, dropResult)
      console.log('applyDrag', result)
      this.tmpContent = result
      this.$emit('saved', result)
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
  },
}
</script>

<style scoped>
.drag-handle-new {
  @apply h-6 w-5 shadow-sm bg-white rounded border border-gray-200 items-center flex justify-center text-center absolute -left-2;
  cursor: grab;
}
</style>
