<template>
  <div
    class="flex bg-white border relative border-gray-100 px-3 pt-1 pb-2 rounded-lg flex-nowrap gap-4 overflow-hidden"
  >
    <div v-for="(option, o) in options" :key="o">
      <fw-label><span class="text-xs" v-html="option.label[language]"></span></fw-label>
      <div v-if="option.type == 'date'" class="text-sm">
        {{ option.value | formatDate }}
      </div>
      <div v-else-if="option.type == 'datetime'" class="text-sm">
        {{ option.value | formatDateTime }}
      </div>
      <div v-else-if="option.type == 'bool'" class="text-sm">
        {{ option.value ? 'Sim' : 'Não' }}
      </div>
      <div v-else class="text-sm" v-html="option.value"></div>
    </div>
    <div class="absolute top-0 bottom-0 right-0 flex items-center px-2 bg-white/90">
      <slot name="options"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    schema: {
      type: Array,
      required: true,
    },
    language: {
      type: String,
      default: 'pt',
    },
  },
  computed: {
    options() {
      return this.schema
        .map(el => {
          return {
            type: el.type,
            value: this.data ? this.data[el.key] : null,
            label: el.label,
          }
        })
        .filter(el => typeof el.value == 'string' || el.value instanceof Date || typeof el.value == 'boolean')
    },
  },
}
</script>
