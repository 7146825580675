<template>
  <div>
    <div>
      <LoadingPlaceholder v-if="loading" />
      <div v-else>
        <div v-if="results.length > 0">
          <PanelModule
            v-for="moduleData in results"
            :key="moduleData.key"
            :ref="moduleData.key"
            :unit="moduleData"
            :edition-key="editionKey"
            :course-key="courseKey"
            :class-key="classKey"
            :agenda="true"
            :agenda-mode="edition.options.topics_visibility_mode"
            :sessions="sessions"
            class="mb-5"
            @open-topic="openTopic(moduleData.key, $event)"
            @session-updated="sessionUpdated"
          ></PanelModule>
        </div>
        <div v-else-if="!loading" class="bg-white rounded-md py-16 text-center text-gray-400">
          Edição sem módulos
        </div>
        <BlockPagination
          v-if="pagination && pagination.total_pages > 1"
          :total="pagination.total_results"
          :total-pages="pagination.total_pages"
          :current.sync="page"
          @page-changed="pageChanged"
        />
      </div>
    </div>
    <fw-modal v-if="showAgendaModal" :active.sync="showAgendaModal" :can-cancel="true" size="min" @close="close">
      <template #default>
        <ModalNewTopicAgenda :edit="editingTopics[0]" @save="saveAgenda($event)" @cancel="close()" />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CoursesServices from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ModalNewTopicAgenda from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewTopicAgenda'
import PanelModule from '../PanelModule.vue'
import Vue from 'vue'

export default {
  name: 'PanelClassAgenda',
  components: {
    LoadingPlaceholder,
    ModalNewTopicAgenda,
    PanelModule,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    edition: {
      type: Object,
      required: true,
    },
    classKey: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {
      showAgendaModal: false,
      loading: false,
      results: [],
      sessions: {},
      pagination: {},
      page: 1,
      editingTopics: [],
    }
  },
  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    canCreateModules() {
      return true
    },
    courseKey() {
      return this.course.key
    },
    editionKey() {
      return this.edition.key
    },
  },
  async created() {
    await this.loadModules()
    this.loadSessions()
  },
  methods: {
    sessionUpdated(data) {
      console.log('sessionUpdated', data)
      if (!this.sessions[data.unitKey]) {
        this.sessions[data.unitKey] = {}
      }
      if (Array.isArray(data.data)) {
        //this.sessions[data.unitKey][data.topicKey] = data.data[0]
        Vue.set(this.sessions[data.unitKey], data.topicKey, data.data[0])
      } else {
        //this.sessions[data.unitKey][data.topicKey] = data.data[data.unitKey][data.topicKey]
        Vue.set(this.sessions[data.unitKey], data.topicKey, data.data[data.unitKey][data.topicKey])
      }
      this.$nextTick(() => {
        this.$refs[data.unitKey][0].refresh()
      })
    },
    async saveAgenda(data) {
      console.log('saveAgenda', data)
      try {
        if (this.editingTopics[0].session && this.editingTopics[0].session.key) {
          console.log('update', this.editingTopics[0].session)
          let body = {}
          body[this.editingTopics[0].session.key] = {
            start_date: data.start_date,
            end_date: data.end_date,
            duration: data.duration ?? null,
          }
          //update
          let result = await CoursesServices.updateClassSession(this.courseKey, this.editionKey, this.classKey, body)
          console.log('saveAgendaTopic', result.data)
          //TODO: this.sessions = result.data
          let moduleKey = this.editingTopics[0].unit_key
          let topicKey = this.editingTopics[0].key
          this.sessions[moduleKey][topicKey].start_date = data.start_date
          this.sessions[moduleKey][topicKey].end_date = data.end_date
          if (this.sessions[moduleKey][topicKey].exam) {
            this.sessions[moduleKey][topicKey].exam.duration = data.duration ?? null
          }
          this.close()
        } else {
          let body = {}
          let moduleKey = this.editingTopics[0].unit_key
          body[moduleKey] = {}
          body[moduleKey][this.editingTopics[0].key] = {
            start_date: data.start_date,
            end_date: data.end_date,
            duration: data.duration ?? null,
          }
          let result = await CoursesServices.createClassSession(this.courseKey, this.editionKey, this.classKey, body)
          console.log('saveAgendaTopic', result.data)
          this.sessions = result.data
          this.close()
        }
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao guardar a calendarização do tópico.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      } finally {
        this.close()
      }
    },
    openTopic(moduleKey, topic) {
      if (this.edition.options.topics_visibility_mode != 'manual') {
        console.log('openTopic', moduleKey, topic)
        topic['unit_key'] = moduleKey
        this.editingTopics = [topic]
        this.showAgendaModal = true
      }
    },
    async createNewModule(data) {
      try {
        let result = await CoursesServices.createUnit(this.courseKey, this.editionKey, {
          title: data.name,
        })
        console.log('createNewModule', result)
        this.results.push(result.data)
        this.close()
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar o módulo.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },
    async loadModules() {
      let data = await CoursesServices.getUnits(this.courseKey, this.editionKey)
      console.log('load modules', data)
      this.results = data.data.units
      this.pagination = data.data.pagination
    },
    async loadSessions() {
      this.loading = true
      try {
        let data = await CoursesServices.getClassSessions(this.courseKey, this.editionKey, this.classKey)
        console.log('load sessions', data)
        this.sessions = data.data
      } finally {
        this.loading = false
      }
    },
    close() {
      this.showAgendaModal = false
    },
    pageChanged(page) {
      this.page = page
      this.loadModules()
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "agenda": "Agenda"
    },
    "pt": {
      "agenda": "Agendamentos"
    }
  }
</i18n>
