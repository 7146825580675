<template>
  <div class="w-full max-w-screen-lg mx-auto p-5 flex items-center justify-center gap-5">
    <div
      class="text-center items-center text-white rounded-3xl min-w-3xl"
      :class="{
        'bg-examstatus-inactive': instance.status != 'running',
        'bg-examstatus-active': instance.status === 'running' && timeLeft >= 120,
        'bg-examstatus-orange': instance.status === 'running' && timeLeft > 0 && timeLeft < 120,
        'bg-examstatus-red': instance.status === 'running' && timeLeft <= 0,
      }"
    >
      <div class="timer-content flex flex-col justify-between p-14 gap-20">
        <div class="flex items-center text-2xl mb-5">
          <div class="flex-1">
            <ExamsLogo class="w-28" />
          </div>
          <div v-if="instance.status === 'running'" class="relative font-bold pr-4 text-3xl">
            A decorrer
            <div class="h-4 w-4 absolute bg-white -top-1 -right-1 rounded-full animate-pulse"></div>
          </div>
          <div v-else class="font-bold opacity-70">Não está a decorrer</div>
        </div>
        <div>
          <div class="mb-5">
            <div v-if="instance.mode != 'remote' && instance.small_key" class="font-bold mb-2 text-lg opacity-70">
              {{ $t('access_code') }}
            </div>
            <div v-if="instance.mode != 'remote' && instance.small_key" class="font-mono text-7xl mb-7">
              {{ instance.small_key | formatSmallKey }}
            </div>
            <div v-else class="font-mono text-2xl mb-2">{{ instance.key }}</div>
          </div>
          <div class="mx-auto max-w-4xl px-10">
            <v-clamp autoresize :max-lines="3" class="font-bold text-3xl">
              {{ instance.title[language] }}
            </v-clamp>
          </div>
        </div>
        <div v-if="instance.status === 'running'">
          <div v-if="timeLeft > 0" class="font-bold">{{ $t('time_left') }}</div>
          <div v-else class="font-bold text-sm">{{ $t('time_over') }}</div>
          <div class="text-9xl font-extrabold flex gap-0 items-center justify-center">
            <span v-if="timeLeft < 0">-</span>
            <span v-if="hoursLeft > 0" style="width:160px">{{ hoursLeft | time }}</span>
            <span v-if="hoursLeft > 0" class="mb-5">:</span>
            <span style="width:160px">{{ minutesLeft | time }}</span>
            <span class="opacity-50 mb-5">:</span>
            <span class="opacity-50 text-left" style="width:160px">{{ secondsLeft | time }}</span>
          </div>
          <div class="mt-5 mb-1 font-medium text-base">{{ $t('started') }}</div>
          <div class="font-medium text-xl">{{ instance.startedDate | formatDateTime }}</div>
        </div>
        <div v-else>
          <div class="font-bold mb-2 opacity-70">{{ $t('duration') }}</div>
          <div class="text-5xl font-extrabold">{{ (instance.duration * 60) | prettySeconds }}</div>
          <div v-if="instance.startDate" class="mt-5">
            <div class="font-bold opacity-70">{{ $t('starts') }}</div>
            <div class="font-semibold text-lg">{{ instance.startDate | formatDateTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExamsLogo from '@/fw-modules/fw-core-vue/exams/assets/ExamsLogo'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'

export default {
  name: 'PanelExamInfo',
  components: {
    ExamsLogo,
  },
  filters: {
    time: function(value) {
      if (!value) return '00'
      if (value < 10) {
        return '0' + value
      }
      return value
    },
    formatSmallKey: function(value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(0, 3) + '-' + value.substring(3, 6)
    },
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
    versions: {
      type: Array,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    remainingTime: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      activeVersion: 0,
      statsOverflow: false,
      statsCanGoBack: false,
      statsCanGoForward: false,
      timeLeft: 500, //in seconds
      timer: null,
      hoursLeft: 0,
      minutesLeft: 0,
      secondsLeft: 0,
      controlsOveflow: false,
    }
  },
  computed: {
    instanceStatus() {
      return this.instance.status
    },
    submited() {
      return this.answers.filter(a => a.state === 'closed').length
    },
    presences() {
      return this.totalPeople - this.numNoAnswer
    },
    numNoAnswer() {
      return this.answers.filter(a => a.state === 'noAnswer').length
    },
    totalPeople() {
      return this.answers.length
    },
    activeVersionData() {
      return this.versions[this.activeVersion]
    },
  },
  watch: {
    instanceStatus() {
      console.log('Instance status changed')
      this.chectActivateTimer()
    },
    remainingTime(val) {
      if (val) {
        console.log('Remaining time syncronized with backend', val)
        //sincronize remaining time with backend
        this.timeLeft = val
        this.startTimer(val)
      }
    },
  },
  created() {
    this.chectActivateTimer()
  },
  methods: {
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
    chectActivateTimer() {
      if (this.instance.status == 'running') {
        this.startTimer()
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    async startExam(status = 'start', forceAction = false) {
      if (this.instance.status === 'draft') {
        this.$buefy.dialog.confirm({
          title: this.$t('startnotpossible'),
          message: this.$t('examneedstobepublished'),
          confirmText: 'Ok',
          type: 'is-success',
        })
        return
      }

      if (this.instance.status === 'running') {
        if (!forceAction) {
          this.$buefy.dialog.confirm({
            title: this.$t('endexam'),
            message: this.$t('endexamconfirmation'),
            confirmText: this.$t('endexambutton'),
            type: 'is-danger',
            onConfirm: () => this.startExam(status, true),
          })
          return
        }
        status = 'stop'
      }

      if (status === 'start' && !forceAction) {
        this.$buefy.dialog.confirm({
          title: 'Iniciar exame',
          message: 'Tem a certeza que pretende iniciar o exame?',
          confirmText: 'Iniciar',
          type: 'is-primary',
          onConfirm: () => this.startExam(status, true),
        })
        return
      }

      console.log('startExam', status)
      //start | stop
      try {
        this.publishing = true
        let response = await FormServices.publishInstance(this.instance.key, status)
        console.log(response)
        this.instance.status = status === 'start' ? 'running' : 'ended'
        if (status === 'start') {
          this.startTimer()
        } else {
          this.stopTimer()
        }
        this.permissions = response.permissions
        //this.$emit('update-permissions', response.permissions)
        this.publishing = false
      } catch (e) {
        console.error(e)
        this.showError(
          this.$t('therewasaproblem') +
            ' ' +
            (status === 'start' ? this.$t('problemstarting') : this.$t('problemending'))
        )
      }
    },
    toClipboardExamLink() {
      this.$copyText(this.examLink).then(() => {
        this.copied = true

        if (this.clipboard_timer !== null) {
          clearTimeout(this.clipboard_timer)
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: `Link copiado para a área de transferência.`,
            position: 'is-top',
          })
        }

        this.clipboard_timer = setTimeout(() => {
          this.copied = false
          this.clipboard_timer = null
        }, 3000)
      })
    },
    startTimer(val = null) {
      if (this.timer != null) {
        clearInterval(this.timer)
      }
      let timeLeft = this.instance.duration * 60
      let now = new Date()
      if (this.instance.startedDate) {
        let startedDate = new Date(this.instance.startedDate)
        let diff = now.getTime() - startedDate.getTime()
        let diffSeconds = Math.round(diff / 1000)
        timeLeft = timeLeft - diffSeconds
      }
      if (val != null) {
        timeLeft = val
      }
      this.timeLeft = timeLeft

      this.timer = setInterval(() => {
        this.timeLeft = this.timeLeft - 1
        let diff = Math.abs(this.timeLeft)
        let hours = Math.floor(diff / 3600)
        let minutes = Math.floor((diff % 3600) / 60)
        let seconds = Math.floor((diff % 3600) % 60)
        this.hoursLeft = hours
        this.minutesLeft = minutes
        this.secondsLeft = seconds
      }, 1000)
    },
    prevStats() {
      this.$refs.statsContainer.scrollBy({
        left: -100,
        behavior: 'smooth',
      })
      this.statsCanGoBack = this.$refs.statsContainer.scrollLeft > 0
    },
    nextStats() {
      this.$refs.statsContainer.scrollBy({
        left: 100,
        behavior: 'smooth',
      })
      this.statsCanGoForward = this.$refs.statsContainer.scrollLeft < this.$refs.statsContainer.scrollWidth
    },
  },
}
</script>
<i18n>
    {
      "pt": {
        "time_left": "Tempo restante",
        "time_over": "A duração estimada foi ultrapassada",
        "access_code": "Código de acesso",
        "endexam": "Terminar o exame",
        "endexamconfirmation": "Tem a certeza que deseja terminar o exame?",
        "endexambutton": "Terminar",
        "startnotpossible":"Não é possível iniciar o exame",
        "examneedstobepublished": "O exame precisa de ser publicado antes de iniciar.",
        "therewasaproblem": "Ocorreu um problema a",
        "problemstarting": "começar o exame",
        "problemending": "terminar o exame",
        "running": "A decorrer",
        "started": "Iniciou às",
        "duration": "Duração prevista",
        "starts": "Previsto iniciar",
        "startdate": "Data prevista",
        "starttime": "Início previsto",
        "versions": "Versões",
        "section": "Secção",
        "sections": "Secções",
        "question": "Questão",
        "questions": "Questões",
        "numbers": "Números",
        "present": "Presentes",
        "submited": "Submetidos",
        "questionnumber": "Nº da pergunta",
        "stopexam": "Terminar exame",
        "startexam": "Iniciar exame",
        "questionanswers": "Nº de respostas"
      },
      "en": {
        "time_left": "Time left",
        "time_over": "The estimated duration has been exceeded",
        "access_code": "Access code",
        "endexam": "End exam",
        "endexamconfirmation": "Are you sure you want to end the exam?",
        "endexambutton": "End exam",
        "startnotpossible":"It is not possible to start the exam",
        "examneedstobepublished": "The exam needs to be published before starting.",
        "therewasaproblem": "The was a problem",
        "problemstarting": "starting the exam",
        "problemending": "ending the exam",
        "running": "Running",
        "started": "Started at",
        "duration": "Predicted duration",
        "starts": "Is predicted to start at",
        "startdate": "Predicted date",
        "starttime": "Predicted start",
        "versions": "Versions",
        "section": "Section",
        "sections": "Sections",
        "question": "Question",
        "questions": "Questions",
        "numbers": "Numbers",
        "present": "Present",
        "submited": "Submited",
        "questionnumber": "Question number",
        "stopexam": "End exam",
        "startexam": "Start exam",
        "questionanswers": "Number of questions"
      }
    }
</i18n>

<style>
.bg-examstatus-active {
  background-color: #41a89a;
}

.bg-examstatus-red {
  background-color: #ef6161;
}

.bg-examstatus-orange {
  background-color: #f09000;
}

.bg-examstatus-inactive {
  background-color: #383838;
}
</style>
