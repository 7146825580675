<template>
  <fw-layout
    :wide="showWideLayout"
    :notfound="!initialLoading && !instance"
    :loading="initialLoading"
    loading-title="Exame"
    loading-icon="survey"
    back-to="/exams"
  >
    <template #header-nav>
      <div class="flex gap-5 items-center">
        <div>
          <div>
            <v-clamp
              v-if="examTitle.length > 0"
              autoresize
              :max-lines="1"
              class="font-semibold text-md leading-5 max-w-prose"
            >
              {{ examTitle }}
            </v-clamp>
            <span v-else class="text-gray-200">Exame sem título</span>
          </div>
          <div
            class="font-semibold leading-5 text-sm"
            :class="{ 'text-gray-500': instance.status !== 'running', 'text-primary': instance.status === 'running' }"
          >
            {{ $t(`examStatus.${instance.status}`) }}
          </div>
        </div>
      </div>
    </template>

    <template v-if="isCreator || isManager" #header-toolbar>
      <div class="mx-5 flex items-center">
        <UsersConnected v-if="connectedUsers.length" :users="connectedUsers" class="mr-3" />
        <div v-if="instance.status === 'running'" class="mr-5">
          <TimeCounter :remaining-seconds="remainingSeconds" type="management"></TimeCounter>
        </div>
        <div v-else-if="!['draft', 'closed'].includes(instance.status)" class="mr-5">
          <TimeCounter :remaining-seconds="instance.duration * 60"></TimeCounter>
        </div>
        <div v-if="instance.status != 'draft'" class="flex items-center gap-2 mr-3">
          <fw-button type="transparent" @click.native="goToView('live')">
            <fw-icon-chart-box
              class="w-5 h-5 inline-flex mr-2"
              :class="{ 'text-primary': instance.status === 'running' }"
            />
            <span :class="{ 'text-primary': instance.status === 'running' }">Live</span>
            <fw-dot v-if="instance.status === 'running'" class="w-2 h-2 text-primary inline-flex ml-1 -mt-2" inline />
          </fw-button>
          <fw-button type="transparent" @click.native="openExternalView('info')">
            <fw-icon-tv class="w-5 h-5 inline-flex" />
            <fw-icon-arrow-right-up class="w-4 h-4 text-gray-500 inline-flex -mr-2 -mt-1" />
          </fw-button>
        </div>
        <HeaderExamActions
          v-if="isCreator || isManager"
          :exam-locked="instance && instance.answersLocked"
          :checks.sync="permissions"
          :missing.sync="missingInfo"
          :missing-info-to="missingInfoTo"
          class="z-50"
          @unpublish="unpublish"
          @publish="publishExam"
          @lock="lockExam(true)"
          @unlock="lockExam(false)"
          @start="startExam('start')"
          @stop="startExam('stop')"
        />
      </div>
    </template>

    <template #main-sidebar>
      <SidebarExamManage
        :version="versionID"
        :subsettings="settingsSubsection"
        :versions="versions"
        :exam="instance"
        :exam-id="instanceID != null ? instanceID : ''"
      />
    </template>

    <template #main-content>
      <fw-message v-if="instance && instance['__errors__']" type="error" class="mb-2">
        {{ instance['__errors__'] }}
      </fw-message>
      <LoadingPage v-if="instance == null" icon="exam" title="Exams 2.0" class="my-28"></LoadingPage>
      <PanelExamDashboard v-else-if="view === 'dashboard'" :language="language" :instance="instance" :users="users" />
      <PanelExamEvaluation
        v-else-if="view === 'evaluation'"
        :language="language"
        :instance="instance"
        :users="users"
        :permissions.sync="permissions"
        @start-exam="startExam"
        @open-user="setUserModal($event, true)"
      ></PanelExamEvaluation>
      <PanelVersionSelection
        v-else-if="view === 'editor' && versionID == null"
        :versions.sync="versions"
        :editable="isEditable"
        :can-ignore-questions="canIgnoreQuestions"
        :can-update-options="canUpdateOptions"
        @new-version="newVersion"
        @select-version="selectVersion($event, false)"
        @edit-version="selectVersion($event, true)"
      />
      <PanelExamSettings
        v-else-if="view === 'settings'"
        :language="language"
        :permissions="permissions"
        :instance="instance"
        :users="users"
        :form="form"
        :saving-success="settingsSavingSuccess"
        :saving="saving"
        :subsection="settingsSubsection"
        @save-instance="saveInstanceSettings"
        @save-form="saveFormSettings"
      ></PanelExamSettings>
      <PanelExamViewer
        v-else-if="view === 'editor' && versionID !== null && !editing && answersid === null"
        :instance="instance"
        :permissions="permissions"
        :form-options="formOptions"
        :form-id="versionID"
        @edit-form="editForm"
        @delete-form="loadExamInstance()"
      />
      <PanelExamEditor
        v-else-if="view === 'editor' && versionID !== null && (editing || answersid !== null)"
        :original-instance="instance"
        :permissions="permissions"
        :answersid="answersid"
        @update-permissions="updatePermissions"
        @saved="formWasSaved()"
        @cancel="cancelExamEditor()"
      />
      <PanelUserAnswers v-else-if="view === 'answers'" :instance="instance" />
      <PanelExamPeople
        v-else-if="view === 'people'"
        :users="users"
        :instance="instance"
        @update-instance="updateLocalInstance"
        @open-user="setUserModal"
      />
      <PanelActivity
        v-else-if="view === 'activity'"
        :inject-payload="{
          instanceKey: instance.key,
        }"
        :api-service="formServices"
      />
      <div v-else>NOT FOUND</div>
    </template>

    <template #modals>
      <ModalDashboardUser
        :show-user-modal="showUserModal && !isAnswer"
        :user-object="userModalData"
        :user-answer="userModalAnswer"
        :versions="versions"
        :versions-map="versionMap"
        :user-activity="activity"
        :users="users"
        @close="closeUserModal"
      ></ModalDashboardUser>
      <fw-modal
        v-if="showUserModal && isAnswer"
        :active.sync="showUserModal"
        :can-cancel="true"
        :width="null"
        size="6xl"
        @close="closeUserModal"
      >
        <PanelExamEditor
          :show-back-button="false"
          :original-instance="instance"
          :permissions="permissions"
          :answersid="userModalData.key"
        />
      </fw-modal>
      <fw-modal :active.sync="showOptions" :can-cancel="true" size="min" @close="closeOptionsModal">
        <div
          v-if="currentModal === 'permissions'"
          class="rounded text-sm p-5 font-semibold flex flex-col gap-3 mx-auto"
        >
          <fw-heading size="h2">Dar permissões</fw-heading>

          <input
            v-model="permissions_email"
            class="w-full border rounded-md bg-white border-gray-200 p-5 pr-14 h-12 text-base outline-none"
            type="text"
            placeholder="Insira e-mail do utilizador"
          />

          <fw-button
            v-for="[key, value] in Object.entries(permissionsSettings)"
            :key="'option_' + key + '_' + value"
            type="dropdown"
            :check-box="true"
            :expanded="true"
            :checked="value"
            :custom-class="'py-1'"
            @click.native="changeBool('permissions', key)"
          >
            {{ permissionsMapping(key) }}
          </fw-button>
          <fw-button
            :type="saving || !isPermissionEmailValid ? 'disabled' : 'primary'"
            size="xl"
            class="mt-2"
            :class="{ 'cursor-not-allowed': saving || !isPermissionEmailValid }"
            @click.native="save('permissions')"
          >
            <InnerLoader v-if="saving" class="mx-auto my-1 w-16" />
            <span v-else>Guardar</span>
          </fw-button>

          <div v-if="usersWithPermissions.length" class="mt-4 overflow-y-scroll" style="max-height: 300px">
            <fw-label class="mb-4">Utilizadores com permissões associadas</fw-label>
            <Person
              v-for="(user, u) in usersWithPermissions"
              :key="user.key"
              :person="user"
              :selectable="false"
              :clickable="false"
            >
              <template #options>
                <b-tooltip label="Remover permissões" position="is-right" type="is-light">
                  <fw-button type="dropdown" :custom-class="'py-1'" @click.native="removeAllPermissions(user.key, u)">
                    <svg
                      class="text-gray-500 h-6 w-6 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M20 7v13a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7H2V5h20v2h-2zM6 7v13h12V7H6zm5 2h2v2h-2V9zm0 3h2v2h-2v-2zm0 3h2v2h-2v-2zM7 2h10v2H7V2z"
                      />
                    </svg>
                  </fw-button>
                </b-tooltip>
              </template>
            </Person>
          </div>
        </div>
        <div
          v-else-if="currentModal === 'share'"
          class="rounded text-sm font-semibold flex flex-col gap-3 mx-auto text-gray-600 w-96"
        >
          <fw-heading size="h2">Link para responder ao exame</fw-heading>
          <div class="relative">
            <b-tooltip class="absolute top-4 right-3" label="Copiar link do exame" type="is-light">
              <fw-button
                :type="copied ? 'light-primary' : 'link'"
                size="xs"
                class="ml-2"
                @click.native="toClipboardExamLink"
              >
                <fw-icon-check v-if="copied"></fw-icon-check>
                <fw-icon-copy v-else></fw-icon-copy>
              </fw-button>
            </b-tooltip>
          </div>
          <input
            class="w-full border rounded-md bg-gray-50 border-gray-200 p-5 pr-14 h-12 text-base outline-none"
            type="text"
            disabled
            :value="examLink"
          />
          <fw-button
            v-if="false && permissions.start"
            :type="publishing ? 'disabled' : 'primary'"
            size="xl"
            class="mt-2"
            :class="{ 'cursor-not-allowed': publishing }"
            @click.native="startExam()"
          >
            <InnerLoader v-if="publishing" class="mx-auto my-1 w-16" />
            <span v-else>Iniciar Exame</span>
          </fw-button>
          <fw-button
            v-if="false && permissions.stop"
            :type="publishing ? 'disabled' : 'primary'"
            size="xl"
            class="mt-2"
            :class="{ 'cursor-not-allowed': publishing }"
            @click.native="startExam('stop')"
          >
            <InnerLoader v-if="publishing" class="mx-auto my-1 w-16" />
            <span v-else>Terminar Exame</span>
          </fw-button>
        </div>
        <ModalNewVersion
          v-else-if="currentModal === 'newVersion'"
          :is-exam="false"
          :show-close-button="false"
          @close="closeOptionsModal"
          @new-from-blank="createNewExam($event)"
          @new-from-template="createNewExam($event)"
        ></ModalNewVersion>
        <ModalVersionFromTemplate
          v-else-if="currentModal === 'versionFromTemplate'"
          @close="closeOptionsModal"
        ></ModalVersionFromTemplate>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import ModalNewVersion from '@/fw-modules/fw-core-vue/exams/components/modals/ModalNewVersion'
import SidebarExamManage from '@/fw-modules/fw-core-vue/exams/components/sidebars/SidebarExamManage'
import PanelExamPeople from '../components/panels/PanelExamPeople.vue'
import PanelExamEditor from '../components/panels/PanelExamEditor.vue'
import PanelExamViewer from '../components/panels/PanelExamViewer.vue'
import PanelVersionSelection from '../components/panels/PanelVersionSelection.vue'
import PanelExamDashboard from '../components/panels/PanelExamDashboard.vue'
import PanelUserAnswers from '../components/panels/PanelUserAnswers.vue'
import PanelExamEvaluation from '../components/panels/PanelExamEvaluation.vue'
import PanelExamSettings from '../components/panels/PanelExamSettings.vue'
import HeaderExamActions from '@/fw-modules/fw-core-vue/exams/components/header/HeaderExamActions'
import utils from '@/fw-modules/fw-core-vue/utilities/utils.js'
import Vue from 'vue'
import InnerLoader from '@/fw-modules/fw-core-vue/ui/components/animation/InnerLoader'
import ModalVersionFromTemplate from '../components/modals/ModalVersionFromTemplate.vue'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import FwMessage from '@/fw-modules/fw-core-vue/ui/components/text/FwMessage'
import UsersConnected from '@/fw-modules/fw-core-vue/ui/components/users/UsersConnected'
import ModalDashboardUser from '../components/modals/ModalDashboardUser'
import PanelActivity from '@/fw-modules/fw-core-vue/ui/components/panels/PanelActivity'
import TimeCounter from '@/fw-modules/fw-core-vue/exams/components/text/TimeCounter'

const defaultFormStructure = {
  instructions: {
    //pt: '',
  },
  title: {
    //pt: '',
  },
  options: {
    disableRightClick: true,
    availableLanguages: ['pt'],
    anonymous: false,
    //new
    pointsCorrectOption: 1,
    pointsWrongOption: 0,
    //new new
    scale: 20,
    pointsCorrectInput: 1,

    randomMultiplechoiceOptions: false,
    randomSections: false,
    negativePoints: false,
    limitNegativePoints: true,
    logInteractions: false,
  },
}

export default {
  name: 'ViewExamManagement',
  components: {
    TimeCounter,
    SidebarExamManage,
    PanelExamPeople,
    PanelUserAnswers,
    PanelExamEditor,
    HeaderExamActions,
    InnerLoader,
    PanelVersionSelection,
    PanelExamDashboard,
    PanelExamViewer,
    PanelExamEvaluation,
    PanelExamSettings,
    ModalVersionFromTemplate,
    ModalNewVersion,
    PanelActivity,
    FwMessage,
    UsersConnected,
    ModalDashboardUser,
  },
  data() {
    return {
      connectedUsers: [],
      canCloseAllForms: false,
      permissions_email: '',
      instance: null,
      users: {},
      permissions: {},
      permissionsSettings: {
        canSeeAnswers: true,
        canStartStopInstance: true,
      },
      currentModal: 'options',
      showOptions: false,
      allowPublish: false,
      publishOptions: {
        allow: 'now',
        //min: '00',
        //hour: '00',
        duration: 60,
        location: '',
        startDate: null,
      },
      form: null, //data first form
      formOptions: {},
      formTitle: '',
      //Exam url
      clipboard_timer: null,
      copied: false,
      publishing: false,
      saving: false,
      modal: null,
      settingsSavingSuccess: false,
      language: 'pt',
      initialLoading: true,
      wideViews: ['evaluation', 'activity'],
      // User modal
      userModalData: null,
      userModalAnswer: null,
      isAnswer: false,
      activity: [], // activity to be used in the user modal
      answers: [], // answers to be used in the user modal
      versionMap: {}, //map from form id to version index
      realtimeDebouncer: null, //for debouncing realtime updates
      remainingSeconds: 0,
      timerInterval: null,
    }
  },
  computed: {
    userActivity() {
      return this.userModalData != null
        ? this.activity.filter(activity => activity.by_user_key == this.userModalData.key)
        : []
    },
    isWebSocketReady() {
      return !!(this.$store.state.socket && this.$store.state.socket.connectionId)
    },
    examWSMessages() {
      return this.$store.state.session.unreadExamWsMessages
    },
    socketId() {
      return this.$store.state.socket.connectionId
    },
    examTitle() {
      return this.instance.languages.includes('pt') && this.instance.title.pt && this.instance.title.pt.length > 0
        ? this.instance.title.pt
        : this.instance.title.en ?? ''
    },
    settingsSubsection() {
      return this.$route.params.subsettings
    },
    missingInfoTo() {
      if (this.instance.status === 'draft' && this.isCreator) {
        return 'publish'
      } else if (this.instance.status === 'ended' && this.isCreator) {
        return 'lock'
      } else {
        return null
      }
    },
    missingInfo() {
      if (this.instance.status === 'draft') {
        return {
          title: this.instance.title['pt'] === '',
          location: this.instance.location === null || this.instance.location === '',
          start: this.instance.startDate === null || this.instance.startDate === '',
          duration: this.instance.duration === 0,
          versions:
            !this.permissions.publish &&
            this.instance.duration > 0 &&
            this.instance.title['pt'] !== '' &&
            !(this.instance.location === null || this.instance.location === '') &&
            !(this.instance.startDate === null || this.instance.startDate === ''), //if user can't publish, then there must be at least one version
        }
      } else if (this.instance.status === 'ended') {
        return {
          correction: !this.permissions.lockAnswers,
        }
      } else {
        return {}
      }
    },
    // For the user modal
    showUserModal() {
      return this.userModalData !== null
    },

    // Make sure exam is not allowed in small devices
    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },
    showWideLayout() {
      return this.wideViews.includes(this.view)
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    formServices() {
      return FormServices
    },
    editing() {
      return this.$route.query.edit ?? false
    },
    answersid() {
      return this.$route.query.answers ?? null
    },
    examLink() {
      return process.env.VUE_APP_IS_PRODUCTION
        ? `https://ucstudent.uc.pt/ex/${this.instance.key}`
        : `https://ucstudent.dev.ucframework.pt/ex/${this.instance.key}`
    },
    versions() {
      if (this.instance == null) return []
      //let versionCharCode = 65
      return this.instance.forms.map(v => {
        //this.versionsMap[v.key] = f
        let sections = 0
        let questions = 0
        for (let index = 0; index < v.pages.length; index++) {
          const element = v.pages[index]
          sections += element.sections.length
          for (let s = 0; s < element.sections.length; s++) {
            questions += element.sections[s].questionCount
          }
        }

        return {
          text: 'Versão ' + v.letter,
          value: v.key,
          letter: v.letter,
          status: 'letter',
          questions: questions,
          sections: sections,
          availableLanguages: v.availableLanguages,
        }
      })
    },
    /*availableActions() {
      let result = []
      if (this.instance && this.instance.status === 'draft') {
        result.push('showPublishExam')
      } else {
        result.push('showUnpublishExam')
      }

      if (this.instance && this.instance.status === 'running') {
        result.push('showCloseExam')
      } else {
        result.push('showOpenExam')
      }

      /*if (
        this.instance &&
        (this.instance.status === 'draft' || this.instance.status === 'published' || this.instance.status === 'running')
      ) {
        result.push('showNotifyStudents')
      }

      if (
        this.instance &&
        this.instance.status === 'ended' &&
        this.instance.answersLocked === false &&
        this.isCreator
      ) {
        result.push('showLockExam')
      } else if (
        this.instance &&
        this.instance.status === 'ended' &&
        this.instance.answersLocked === true &&
        this.isCreator
      ) {
        result.push('showUnlockExam')
      }

      return result
    },*/
    type() {
      return 'exam'
    },
    isPermissionEmailValid() {
      return utils.isValidEmail(this.permissions_email)
    },
    instanceID() {
      return this.$route.params.id ? this.$route.params.id : null
    },
    versionID() {
      return this.$route.params.versionid ? this.$route.params.versionid : null
    },
    view() {
      return this.$route.meta.view ?? 'dashboard'
    },
    isCreator() {
      return this.instance != null && this.loggedUser != null && this.loggedUser.key == this.instance.user_key
    },
    isManager() {
      return (
        this.instance != null &&
        this.loggedUser != null &&
        Object.keys(this.instance.managers).includes(this.loggedUser.key)
      )
    },
    publishingMin() {
      return this.publishOptions.min
    },
    publishingHour() {
      return this.publishOptions.hour
    },
    //Permissions check
    isEditable() {
      return this.permissions != null && this.permissions.edit
    },
    isPublishable() {
      return this.permissions != null && this.permissions.publish
    },
    isUnpublishable() {
      return this.permissions != null && this.permissions.unpublish
    },
    isDeletable() {
      return this.permissions != null && this.permissions.delete
    },
    isStartable() {
      return this.permissions != null && this.permissions.start
    },
    isStopable() {
      return this.permissions != null && this.permissions.stop
    },
    isEndable() {
      return this.permissions != null && this.permissions.end
    },
    canIgnoreQuestions() {
      return this.permissions != null && this.permissions.canIgnoreQuestions
    },
    canUpdateOptions() {
      return this.permissions != null && this.permissions.canUpdateOptions
    },
    baseUrl() {
      return '/exam/' + this.instance.key
    },
  },
  watch: {
    examWSMessages(newMessages) {
      if (this.realtimeDebouncer == null && newMessages.length > 0) {
        this.realtimeDebouncer = setTimeout(() => {
          console.log('examWSMessages changed', newMessages)
          //change user state or number of answers
          //if not found, load answers again
          let userfound = true
          for (let index = 0; index < newMessages.length; index++) {
            const message = newMessages[index]
            console.log('newMessage', message)
            if (message.type == 'instanceOnlineManagers' && this.instance.key === message.instance_key) {
              this.connectedUsers = message.users
            } else if (message.type == 'instanceStatus' || message.type == 'instanceUpdate') {
              if (message.remaining_time) {
                console.log('Update remaining time from websocket', message.remaining_time)
                this.remainingSeconds = message.remaining_time
              }
              if (message.type == 'instanceUpdate' && message.status == 'start') {
                console.log('WS exam starting')
                this.instance.status = 'running'
              } else if (message.type == 'instanceUpdate' && message.status == 'stop') {
                console.log('WS exam ending')
                this.instance.status = 'ended'
                this.stopTime()
              }
              //make it run?
              // Make sure we start timer after user starts to work
              if (this.timerInterval == null && this.instance.status == 'running') {
                console.log('Start time tick after loading ws messages')
                this.startTime()
              }
            }
          }
          this.$store.commit('removeFromExamMessageQueue', newMessages.length)
          if (!userfound) {
            //this.loadAnswers()
          }
          this.realtimeDebouncer = null
        }, 1000)
      }
    },
    // whenever question changes, this function will run
    socketId(newSocketId) {
      if (newSocketId != null) {
        this.subscribeExam()
      }
    },
  },
  created() {
    this.loadExamInstance(true)
    this.subscribeExam()
  },
  beforeDestroy() {
    this.unsubscribeExam()
  },
  methods: {
    async loadActivity() {
      let result = await FormServices.getActivityLogs({
        instanceKey: this.instance.key,
        sort: 'created_date',
        direction: 'desc',
      })
      this.activity = result.logs
    },
    async loadAnswers() {
      console.log('loading answers')
      let result = await FormServices.getAnswers(this.instance.key, true, null, 'asc', null, [])
      this.answers = result.answers
    },
    goToView(view) {
      this.$router.push(this.baseUrl + '/' + view)
    },
    openExternalView(view = 'live') {
      window.open('https://' + window.location.host + '/exam/' + this.instance.key + '/' + view, '_blank')
    },
    stopTime() {
      if (this.timerInterval !== null) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
    },
    startTime() {
      let self = this
      this.timeTick()
      if (this.timerInterval != null) {
        clearInterval(this.timerInterval)
      }
      this.timerInterval = setInterval(() => {
        self.timeTick()
      }, 1000)
    },
    timeTick() {
      this.remainingSeconds -= 1
    },
    async setUserModal(userObject, isAnswer = false) {
      await this.loadAnswers()
      await this.loadActivity()
      this.userModalAnswer = this.answers.find(a => a.user.key === userObject.key)
      this.isAnswer = isAnswer
      this.userModalData = userObject
    },
    closeUserModal() {
      this.userModalData = null
      this.userModalAnswer = null
    },
    async subscribeExam() {
      if (this.$store.state.socket && this.$store.state.socket.connectionId) {
        let connectionId = this.$store.state.socket.connectionId
        let subscriptionData = await FormServices.subscribeInstance(connectionId, this.instanceID)
        console.log('WS exam subscription data', subscriptionData)
      } else {
        console.error('No socket connection')
      }
    },
    async unsubscribeExam() {
      if (this.$store.state.socket && this.$store.state.socket.connectionId) {
        let connectionId = this.$store.state.socket.connectionId
        let subscriptionData = await FormServices.unsubscribeInstance(connectionId, this.instanceID)
        console.log('WS exam unsubscription data', subscriptionData)
      } else {
        console.error('No socket connection')
      }
    },
    async formWasSaved() {
      await this.checkFormClosureAbility()
      //this.selectVersion(this.versionID, false)
    },
    async loadFormPage(pageKey) {
      console.log('loadFormPage', pageKey)
      let pageResponse = await FormServices.getPage(pageKey)
      if (typeof pageResponse.title[this.language] === 'undefined') {
        pageResponse.title[this.language] = ''
      }

      for (let s = 0; s < pageResponse.schema.length; s++) {
        if (typeof pageResponse.schema[s].title[this.language] === 'undefined') {
          pageResponse.schema[s].title[this.language] = ''
        }

        for (let i = 0; i < pageResponse.schema[s].content.length; i++) {
          //score
          if (typeof pageResponse.schema[s].content[i].score === 'undefined') {
            pageResponse.schema[s].content[i].score = null
          }
          //instructions
          if (
            typeof pageResponse.schema[s].content[i].instructions === 'undefined' ||
            typeof pageResponse.schema[s].content[i].instructions[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].instructions === 'undefined') {
              pageResponse.schema[s].content[i].instructions = {}
            }
            pageResponse.schema[s].content[i].instructions[this.language] = ''
          }
          //label
          if (
            typeof pageResponse.schema[s].content[i].label === 'undefined' ||
            typeof pageResponse.schema[s].content[i].label[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].label === 'undefined') {
              pageResponse.schema[s].content[i].label = {}
            }
            pageResponse.schema[s].content[i].label[this.language] = ''
          }
          //message
          if (
            pageResponse.schema[s].content[i].message &&
            typeof pageResponse.schema[s].content[i].message[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].message === 'undefined') {
              pageResponse.schema[s].content[i].message = {}
            }
            pageResponse.schema[s].content[i].message[this.language] = ''
          }
          //text
          if (
            pageResponse.schema[s].content[i].text &&
            typeof pageResponse.schema[s].content[i].text[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].text === 'undefined') {
              pageResponse.schema[s].content[i].text = {}
            }
            pageResponse.schema[s].content[i].text[this.language] = ''
          }
          //placeholder
          if (
            typeof pageResponse.schema[s].content[i].placeholder === 'undefined' ||
            typeof pageResponse.schema[s].content[i].placeholder[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].placeholder === 'undefined') {
              pageResponse.schema[s].content[i].placeholder = {}
            }

            if (pageResponse.schema[s].content[i].type === 'text_area') {
              pageResponse.schema[s].content[i]['placeholder'][this.language] =
                this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
            }

            pageResponse.schema[s].content[i].placeholder[this.language] = ''
          }

          //check options
          if (pageResponse.schema[s].content[i].options && pageResponse.schema[s].content[i].options.length > 0) {
            for (let o = 0; o < pageResponse.schema[s].content[i].options.length; o++) {
              let optiontext = pageResponse.schema[s].content[i].options[o].text
              /*let optionscore = 0
              if (
                this.scores &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
              ) {
                optionscore = Number(
                  this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
                )
              }*/

              //? this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].score
              //: 0

              /*try {
                optiontext = JSON.parse(optiontext)
                console.log('é JSON', optiontext)
              } catch (e) {
                //no json detected
                console.log('NAO é JSON', optiontext)
              }*/

              if (typeof optiontext !== 'string' && typeof optiontext[this.language] === 'undefined') {
                optiontext[this.language] = ''
              }
              pageResponse.schema[s].content[i].options[o].text = optiontext
              //pageResponse.schema[s].content[i].options[o].score = optionscore
            }
          }
        }
      }

      return pageResponse
    },
    async createNewExam(data) {
      console.log('createNewExam', data)
      let nextLetter = String.fromCharCode(this.instance.forms.length + 65)
      let versionName = this.instance.title['pt'] + ' - versão ' + nextLetter
      let versionNameEN = this.instance.title['en'] + ' - version ' + nextLetter
      let pageTitle = {}
      let examTitle = this.instance.title

      try {
        let formData = {
          instructions: {
            //pt: '',
          },
          title: {
            //pt: data.name.length > 0 ? data.name : versionName,
          },
          options: {
            disableRightClick: true,
            availableLanguages: this.instance.languages,
            anonymous: false,
            //new
            pointsCorrectOption: 1,
            pointsWrongOption: 0,
            //new new
            scale: 20,
            pointsCorrectInput: 1,
            randomMultiplechoiceOptions: false,
            randomSections: false,
            negativePoints: false,
            limitNegativePoints: true,
            logInteractions: false,
          },
        }

        for (let index = 0; index < this.instance.languages.length; index++) {
          const processLanguage = this.instance.languages[index]
          formData.instructions[processLanguage] = ''
          formData.title[processLanguage] = processLanguage === 'pt' ? versionName : versionNameEN
          pageTitle[processLanguage] = processLanguage === 'pt' ? 'Página 1' : 'Page 1'
        }

        //Use the default options of the first version
        if (this.formOptions) {
          formData['options'] = this.formOptions
        }

        let pagesData = []

        if (data.key != null) {
          //load data from form
          let templateForm = await FormServices.getForm(data.key)
          let pagesPromises = []
          //response.pages.forEach(page => promises.push(FormServices.getPage(page.key)))
          templateForm.pages.forEach(page => pagesPromises.push(this.loadFormPage(page.key)))

          this.pages = []
          pagesData = await Promise.all(pagesPromises)
        }

        formData['type'] = 'exam'
        let responseForm = await FormServices.saveForm(formData)

        //verify if we have current language
        if (typeof responseForm.title[this.language] === 'undefined') {
          responseForm.title[this.language] = ''
        }

        let pagesPromises = []

        //add empty page to form
        if (pagesData.length == 0) {
          await FormServices.addFormPage(
            responseForm.key,
            pageTitle,
            [
              {
                boxed: false,
                required: false,
                title: '',
                key: '1',
                content: [],
              },
            ],
            {},
            null
          )
        } else {
          for (let index = 0; index < pagesData.length; index++) {
            let localPageTitle = pagesData[index]['title']
            // Normalize language titles
            for (let index = 0; index < this.instance.languages.length; index++) {
              const processLanguage = this.instance.languages[index]
              if (typeof localPageTitle[processLanguage] === 'undefined') {
                localPageTitle[processLanguage] =
                  processLanguage === 'pt' ? 'Página ' + (index + 1) : 'Page ' + (index + 1)
              }
            }

            pagesPromises.push(
              FormServices.addFormPage(
                responseForm.key,
                localPageTitle,
                pagesData[index]['schema'],
                pagesData[index]['scores'],
                null
              )
            )
          }
          await Promise.all(pagesPromises)
        }

        //add form to exam
        let formKeys = this.instance.forms.map(form => form.key) //[this.formid, newform.form.key]
        formKeys.push(responseForm.key)

        let local_instance = await FormServices.updateInstance(
          this.instance.key,
          formKeys,
          examTitle,
          this.instance.options,
          this.instance.startDate,
          this.instance.duration,
          this.instance.mode,
          this.instance.location
        )
        //}
        console.log('EXAM INSTANCE UPDATE', local_instance)

        //update local instance
        this.instance = local_instance
        this.closeOptionsModal()
        /*let responseInstance = await FormServices.createInstance(
          [responseForm.key],
          {
            pt: data.name,
          },
          formData.options,
          null
        )
        console.log(responseForm, responseInstance)
        this.$router.push({ name: 'exams-manage-editor-version', params: { id: responseInstance.key } })*/
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar o exame',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },
    cancelExamEditor() {
      this.$router.push({
        path: `/exam/${this.instance.key}/editor/${this.versionID}`,
      })
    },
    async addNewVersion() {
      let formKeys, newform, local_instance
      /*if (this.instanceid !== null) {
        let instanceKey = this.instance.key
        //create form
        newform = await this.createNewForm()
        formKeys = this.instance.formKeys
        formKeys.push(newform.form.key)
        local_instance = await FormServices.updateInstance(
          instanceKey,
          formKeys,
          this.form.title,
          this.form.options,
          null
        )
      } else {*/
      //create form
      newform = await this.createNewForm()
      formKeys = this.instance.forms.map(form => form.key) //[this.formid, newform.form.key]
      formKeys.push(newform.form.key)

      local_instance = await FormServices.updateInstance(
        this.instance.key,
        formKeys,
        newform.form.title,
        newform.form.options,
        this.instance.startDate,
        this.instance.duration,
        this.instance.mode,
        this.instance.location
      )
      //}

      //update local instance
      this.instance = local_instance
      //update local form
      //this.form = newform.form
      //update local pages
      //this.pages = [newform.page]
      //change route
      this.$router.push({
        path: `/exam/${this.instance.key}/editor/${newform.form.key}?edit=true`,
      })
    },
    async createNewForm() {
      let formData = defaultFormStructure
      let nextLetter = String.fromCharCode(this.instance.forms.length + 65)
      let versionName = this.instance.title['pt'] + ' - versão ' + nextLetter
      let versionNameEN = this.instance.title['en'] + ' - version ' + nextLetter
      let pageTitle = {}

      for (let index = 0; index < this.instance.languages.length; index++) {
        const processLanguage = this.instance.languages[index]
        formData.instructions[processLanguage] = ''
        formData.title[processLanguage] = processLanguage === 'pt' ? versionName : versionNameEN
        pageTitle[processLanguage] = processLanguage === 'pt' ? 'Página 1' : 'Page 1'
      }

      formData['type'] = 'exam'
      let response = await FormServices.saveForm(formData)

      //verify if we have current language
      /*if (typeof response.title[this.language] === 'undefined') {
        response.title[this.language] = ''
      }*/

      //let title = {}

      ///title[this.language] = this.language === 'pt' ? 'Página 1' : 'Page 1'

      //add page to form
      let responsePage = await FormServices.addFormPage(
        response.key,
        //title,
        pageTitle,
        [
          {
            boxed: false,
            required: false,
            title: '',
            key: '1',
            content: [],
          },
        ],
        {},
        null
      )

      return {
        form: response,
        page: responsePage,
      }
    },
    newVersionFrom(template) {
      console.log(template)
      if (template == 'blank') {
        this.addNewVersion()
      } else {
        console.log('TODO: VERSION FROM TEMPLATE')
      }
    },
    newVersion() {
      console.log('new version')
      this.currentModal = 'newVersion'
      this.showOptions = true
    },
    editForm(formKey) {
      this.$router.push({
        name: 'exams-manage-editor-version',
        params: {
          id: this.instanceID,
          versionid: formKey,
        },
        query: {
          edit: true,
        },
      })
    },
    saveForm() {
      this.$router.push({
        name: 'exams-manage-editor-version',
        params: {
          id: this.instanceID,
          versionid: this.versionID,
        },
        query: {
          edit: false,
        },
      })
    },
    selectVersion(id, edit) {
      console.log(id, edit)
      this.$router.push({
        name: 'exams-manage-editor-version',
        params: {
          id: this.instanceID,
          versionid: id,
        },
        query: {
          edit: edit,
        },
      })
    },
    updateLocalInstance(instance) {
      this.instance = instance
    },
    async checkFormClosureAbility() {
      //TODO: verify all versions and all languages; USE BACKEND RESPONSE
      console.log('checking closure ability')
      if (this.instance != null) {
        /*let canCloseAllForms = true

        let loadformPromises = []
        this.instance.forms.forEach(form => {
          loadformPromises.push(FormServices.getForm(form.key))
        })

        let result = await Promise.all(loadformPromises)
        result.forEach(formResult => {
          console.log(formResult)
          this.formOptions = formResult.options
          this.formTitle = typeof formResult.title === 'object' ? formResult.title['pt'] : formResult.title
          if (!formResult.permissions.close) {
            canCloseAllForms = false
          }
        })
        this.canCloseAllForms = canCloseAllForms
        if (canCloseAllForms && (this.isManager || this.isCreator)) {
          this.permissions.publish = true
        }*/
        //canCloseAllForms = await FormServices.canCloseAllForms()

        //request backend
        this.loadExamInstance(false)
      }
    },
    async addPermissions() {
      this.showOptions = true
      this.currentModal = 'permissions'
      this.usersWithPermissions = []
      this.usersWithPermissions = await FormServices.getInstancePermissions(this.instance.key)
    },
    async removeAllPermissions(userKey, u) {
      try {
        await FormServices.deleteInstancePermissions(this.instance.key, userKey)
        this.usersWithPermissions.splice(u, 1)
        this.$buefy.toast.open({
          duration: 2000,
          message: `Permissões removidas`,
          position: 'is-top',
        })
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao remover permissões')
      }
    },
    async save(attr) {
      if (attr === 'permissions') {
        //verify e-mail
        if (this.isPermissionEmailValid) {
          this.saving = true
          try {
            let result = await FormServices.addInstancePermissions(
              this.instance.key,
              this.permissions_email,
              this.permissions
            )
            console.log('permission result', result)
            this.usersWithPermissions = result.users
            this.showOptions = false
            this.currentModal = 'options'
          } catch (e) {
            this.saving = false
            console.error(e)
            alert(e.response.data['__errors__'][0]['detail'])
          }
          this.saving = false
          //
        }
      }
    },
    changeBool(attr, key) {
      this[attr][key] = !this[attr][key]
    },
    permissionsMapping(key) {
      const map = {
        canSeeAnswers: 'Ver respostas do exame',
        canStartStopInstance: 'Iniciar e terminar o exame',
      }
      return map[key] ? map[key] : ''
    },
    setPublishOption(id, value) {
      console.log('PublishOption', id, value)
      /*if (id === 'allow') {
        value = value.value
      }*/
      if (id === 'time') {
        let hour = value.split(':')[0]
        let min = value.split(':')[1]
        Vue.set(this.publishOptions, 'min', min)
        Vue.set(this.publishOptions, 'hour', hour)
      } else {
        Vue.set(this.publishOptions, id, value)
      }
      this.allowPublish = this.verifyPublishOptions(false)
    },
    verifyPublishOptions(showErrorMessage = true) {
      if (isNaN(parseInt(this.publishOptions.duration))) {
        if (showErrorMessage) {
          this.showError('A duração do exame não é válida')
        }
        return false
      }

      if (this.publishOptions.duration < 15) {
        if (showErrorMessage) {
          this.showError('A duração do exame deve ser maior que 15 minutos')
        }
        return false
      }

      if (this.publishOptions.startDate == null) {
        if (showErrorMessage) {
          this.showError('A data de início do exame não é válida')
        }
        return false
      }
      return true
    },
    getShareLink() {
      this.showOptions = true
      this.currentModal = 'share'
    },
    async unpublish() {
      try {
        let publish_result = await FormServices.publishInstance(this.instance.key, 'unpublish')
        console.log('unpublish result', publish_result)
        //this.instance.status = publish_result.status
        Vue.set(this.instance, 'status', publish_result.status)
        this.permissions = publish_result.permissions
        //reopen forms - USES BACKEND NOW
        /*let formPromises = []
        this.instance.forms.forEach(form => {
          formPromises.push(FormServices.updateFormStatus(form.key, 'reopen'))
        })
        Promise.all(formPromises)
          .then(result => {
            console.log('form reopen result', result)
            //this.instance.status = 'draft'
            //this.form.status = 'draft'
          })
          .catch(e => {
            console.error(e)
            //this.showError('Ocorreu um erro ao reabrir a(a) versão(ões) do exame para editar')
          })*/
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao despublicar exame')
      }
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
    async lockExam(lock) {
      try {
        this.publishing = true
        let status = lock ? 'lock_answers' : 'unlock_answers'
        let response = await FormServices.publishInstance(this.instance.key, status)
        console.log(response)
        this.instance.answersLocked = this.instance.answersLocked //lock ? true : false
        this.instance.status = response.status
        this.permissions = response.permissions
        //this.$emit('update-permissions', response.permissions)
        this.publishing = false
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao ' + (lock ? 'lacrar o exame' : 'deslacrar o exame'))
      }
    },
    async publishForm(forcePublish = false) {
      console.log('publishForm')
      //ask for start date if its null
      let options = this.publishOptions
      if (((this.instance && this.instance.startDate === null) || this.instance === null) && forcePublish === false) {
        //pass data to options:

        options.allow = this.instance !== null && this.instance.startDate !== null ? 'later' : 'now'
        options.startDate =
          this.instance !== null && this.instance.startDate !== null ? this.instance.startDate : new Date()
        //options.hour =
        //  this.instance !== null && this.instance.startDate !== null ? this.instance.startDate.getHours() : ''
        //options.min =
        //  this.instance !== null && this.instance.startDate !== null ? this.instance.startDate.getMinutes() : ''
        options.duration = this.instance !== null && this.instance.duration ? this.instance.duration : 60
        options.location = this.instance !== null && this.instance.location !== null ? this.instance.location : ''
        this.publishOptions = options
        this.allowPublish = this.verifyPublishOptions(false)
        this.showOptions = true
        this.currentModal = 'publish'
        /*} else if (this.instance === null) {
        //this.showOptions = false

        this.publishing = true
        //ask for a name
        //and start date?

        //change form status and save
        //this.form.status = 'active'
        //this.dirtyPages.add('form')
        //await this.saveForm(false)

        //TODO: USAR duration!!!
        let options = this.publishOptions
        let instanceOptions = this.form.options
        //use permissions from the form

        let startDate = options.allow === 'later' ? options.date : null
        if (startDate !== null) {
          startDate.setHours(options.hour, options.min, 0)
          startDate = startDate.toISOString()
        }

        let formKeys = [this.formid]
        let local_instance = await FormServices.createInstance(
          formKeys,
          this.form.title,
          instanceOptions,
          startDate,
          options.duration
        )
        this.instance = local_instance

        let formPromises = []
        let loadformPromises = []
        local_instance.forms.forEach(form => {
          loadformPromises.push(FormServices.getForm(form.key))
        })

        let resultForms = await Promise.all(loadformPromises)
        //close all forms
        resultForms.forEach(form => {
          if (form.status !== 'closed') formPromises.push(this.closeForm(form.key))
        })

        await Promise.all(formPromises)
          .then(async result => {
            console.log('closing forms', result)
            //Publish instance
            try {
              let publish_result = await FormServices.publishInstance(local_instance.key, 'publish')
              console.log('publish_result', publish_result)
              this.$router.push({ path: `/builder/${this.type}/${local_instance.key}/${this.formid}` })
              this.currentModal = 'share'
              this.instance.status = 'waiting'
              this.form.status = 'closed'
              //this.instance.permissions = publish_result.permissions
              this.$emit('update-permissions', publish_result.permissions)
            } catch (e) {
              console.error(e)
              this.showError('Ocorreu um erro ao publicar o exame. Tente de novo mais tarde.')
            }
            this.publishing = false
          })
          .catch(e => {
            console.error(e)
            this.showError(
              'Verifique que todas as versões do exame contém título, instruções e conteúdo. Tente de novo mais tarde.'
            )
            this.publishing = false
          })
     */
      } else if (this.instance !== null) {
        if (this.allowPublish) {
          //override default values by the option ones
          this.instance.startDate = options.startDate.toISOString()
          this.instance.duration = options.duration
          this.instance.location = options.location

          let duration = parseInt(options.duration)
          if (isNaN(duration) || duration < 15) {
            this.$buefy.dialog.alert({
              message: 'Por favor preencha a duração do exame. A duração mínima é de 15 minutos.',
              type: 'is-danger',
              hasIcon: false,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true,
            })
            return
          }

          if (this.instance.location === '') {
            this.$buefy.dialog.alert({
              message: 'Por favor preencha o local do exame',
              type: 'is-danger',
              hasIcon: false,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true,
            })
            return
          }
        }
        this.saveInstance(true)
        this.showOptions = false
        this.currentModal = 'options'
      }
    },
    async publishExam() {
      this.saveInstance(true)
    },
    async startExam(status = 'start', forceAction = false) {
      if (this.instance.status == 'draft') {
        this.$buefy.dialog.confirm({
          title: 'Não é possível iniciar o exame',
          message: 'O exame precisa de ser publicado antes de iniciar. Deseja publicar agora?',
          confirmText: 'Publicar',
          type: 'is-success',
          onConfirm: () => this.saveInstance(true),
        })
        return
      }

      if (this.instance.status === 'running') {
        if (!forceAction) {
          this.$buefy.dialog.confirm({
            title: 'Terminar o exame',
            message: 'Tem a certeza que deseja terminar o exame?',
            confirmText: 'Terminar',
            type: 'is-danger',
            onConfirm: () => this.startExam(status, true),
          })
          return
        }
        status = 'stop'
      }

      console.log('startExam', status)
      //start | stop
      try {
        this.publishing = true
        let response = await FormServices.publishInstance(this.instance.key, status)
        console.log(response)
        this.instance.status = response.status
        //status === 'start' ? 'running' : 'ended'
        this.permissions = response.permissions
        //this.$emit('update-permissions', response.permissions)
        this.publishing = false
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao ' + (status === 'start' ? 'começar o exame' : 'terminar o exame'))
      }
    },
    async closeForm(formData) {
      //let response = await FormServices.updateFormStatus(formId, 'close')
      formData['status'] = 'close'
      let response = await FormServices.saveForm(formData) //updateFormStatus(formId, 'close')
      console.log('closeForm', response)
    },
    toClipboardExamLink() {
      this.$copyText(this.examLink).then(() => {
        this.copied = true

        if (this.clipboard_timer !== null) {
          clearTimeout(this.clipboard_timer)
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: `Link copiado para a área de transferência.`,
            position: 'is-top',
          })
        }

        this.clipboard_timer = setTimeout(() => {
          this.copied = false
          this.clipboard_timer = null
        }, 3000)
      })
    },
    async saveAllFormSettings() {
      try {
        this.saving = true
        this.settingsSavingSuccess = false
        //TODO: update all forms
        let formLoadingPromises = []
        let formSavingPromises = []
        this.instance.forms.forEach(form => {
          formLoadingPromises.push(FormServices.getForm(form.key))
        })

        let results = await Promise.all(formLoadingPromises)
        results.forEach((formData, f) => {
          let newFormData = {}
          newFormData.title = this.instance.title
          newFormData.key = this.instance.forms[f].key
          newFormData.instructions = formData.instructions
          //add new options to each form
          newFormData.options = this.formOptions
          console.log('FORM DATA', newFormData)

          formSavingPromises.push(FormServices.saveForm(newFormData))
        })
        await Promise.all(formSavingPromises)
        this.settingsSavingSuccess = true
        console.log('FORM SAVING SUCCESSFUL')
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao guardar as configurações do exame. Tente de novo mais tarde.')
      } finally {
        this.saving = false
      }
    },
    async saveInstance(publish = false) {
      console.log('saving instance')
      //save instance and formOptions
      //update instance and publish instance
      this.publishing = publish
      this.saving = true
      this.settingsSavingSuccess = false

      //TODO: USAR duration!!!
      //let options = this.publishOptions

      //TODO: use permissions from the form
      let instanceOptions = this.instance.options
      /*let formOptions = this.formOptions //update all forms with the same settings

      //use permissions from the form
      let startDate = options.allow === 'later' ? this.options.date : null
      if (startDate !== null) {
        startDate.setHours(options.hour, options.min, 0)
        startDate = startDate.toISOString()
      }*/

      console.log('instanceDate', this.instance.startDate, typeof this.instance.startDate)
      console.log('instanceOptions', instanceOptions)
      let startDate = this.instance.startDate != null ? this.instance.startDate : null
      let formKeys = this.instance.forms.map(form => form.key)
      //let canCloseForms = true

      let local_instance = await FormServices.updateInstance(
        this.instance.key,
        formKeys,
        this.instance.title,
        instanceOptions,
        startDate,
        this.instance.duration,
        this.instance.mode,
        this.instance.location,
        this.instance.organic_unit_key
      )

      console.log('Remote instance', local_instance)
      this.instance = local_instance

      //let formPromises = []
      /*let loadformPromises = []
      local_instance.forms.forEach(form => {
        loadformPromises.push(FormServices.getForm(form.key))
      })

      let resultForms = await Promise.all(loadformPromises)*/

      /*for (let index = 0; index < resultForms.length; index++) {
        const canClose = resultForms[index].status == 'closed' || resultForms[index].permissions.close
        if (!canClose) {
          canCloseForms = false
        }
      }*/
      //console.log(publish, canCloseForms)
      /*if (publish && canCloseForms) {
        //close all forms
        resultForms.forEach(form => {
          //form['title'] = local_instance.title
          if (form.status !== 'closed') formPromises.push(this.closeForm(form))
        })
      } else if (publish && !canCloseForms) {
        this.showError(
          'Não é possível publicar o exame. Verifique que todas as versões têm conteúdo em todos os idiomas antes de publicar.'
        )
        return
      }*/

      /*Promise.all(formPromises)
        .then(async result => {
          if (publish) {
            console.log('closing forms', result)
            //Publish instance
            try {
              let publish_result = await FormServices.publishInstance(local_instance.key, 'publish')
              console.log('publish_result', publish_result)
              //this.currentModal = 'share'
              this.instance.status = publish_result.status //'waiting'
              this.permissions = publish_result.permissions
              //this.$router.push({ path: `/exam/${local_instance.key}/evaluation` })
              //this.form.status = 'closed'
            } catch (e) {
              console.error(e)
              this.showError('Ocorreu um erro ao publicar o exame. Tente de novo mais tarde.')
            }
          }
          this.publishing = false
          this.saving = false
          this.settingsSavingSuccess = true
          //verify if we can close the forms
          this.checkFormClosureAbility()
        })
        .catch(e => {
          console.error(e)
          this.showError('Ocorreu um erro ao guardar as configurações do exame. Tente de novo mais tarde.')
          this.publishing = false
          this.saving = false
        })*/

      if (publish) {
        try {
          let publish_result = await FormServices.publishInstance(local_instance.key, 'publish')
          console.log('publish_result', publish_result)
          //this.currentModal = 'share'
          this.instance.status = publish_result.status //'waiting'
          this.permissions = publish_result.permissions
          //this.$router.push({ path: `/exam/${local_instance.key}/evaluation` })
          //this.form.status = 'closed'
        } catch (e) {
          console.error(e)
          this.showError('Ocorreu um erro ao publicar o exame. Tente de novo mais tarde.')
        } finally {
          this.publishing = false
          this.saving = false
          this.settingsSavingSuccess = true
        }
      } else {
        this.publishing = false
        this.saving = false
        this.settingsSavingSuccess = true
        this.checkFormClosureAbility()
      }

      //verify if we can close the forms
      //
    },
    async saveInstanceSettings(value) {
      console.log('SAVING', value)

      //verify if language was changed
      let instanceLanguages = this.instance.languages
      let languagesChanged = false
      if (instanceLanguages.length !== value.languages.length) {
        languagesChanged = true
      } else {
        value.languages.forEach(lang => {
          if (!instanceLanguages.includes(lang)) languagesChanged = true
        })
      }

      if (languagesChanged) {
        //TODO: WARNING, race condition here againts intance languages... MAKE IT BETTER IN THE FUTURE
        //Change forms languages first
        let formPromises = []
        this.instance.forms.forEach(form => {
          let newFormData = {}
          newFormData.options = this.formOptions
          newFormData.key = form.key
          newFormData.options['availableLanguages'] = value.languages
          formPromises.push(FormServices.saveForm(newFormData))
        })

        await Promise.all(formPromises)
      }

      this.instance = value
      this.saveInstance()
    },
    saveFormSettings(value) {
      //TODO: WARNING, race condition here againts intance languages... MAKE IT BETTER IN THE FUTURE
      //get languages from instance and update form
      let instanceLanguages = this.instance.languages
      value['availableLanguages'] = Array.from(instanceLanguages)
      this.formOptions = value
      this.form.options = value
      this.saveAllFormSettings()
    },
    changeInstanceOptions(value, save = false) {
      console.log('changeInstanceOptions', value)
      Vue.set(this.instance.options, value[0], value[1])
      if (save) this.saveInstance()
    },
    changeInstance(value, save = false) {
      console.log('changeInstance', value)
      Vue.set(this.instance, value[0], value[1])
      if (save) this.saveInstance()
    },
    toogleInstanceOption(option, save = false) {
      console.log('toogleInstanceOption', option)
      Vue.set(this.instance.options, option, !this.instance.options[option])
      if (save) this.saveInstance()
    },
    toogleFormOption(option, save = false) {
      //TODO: UPDATE REMOTE OPTIONS
      console.log('toogleFormOption', option)
      Vue.set(this.formOptions, option, !this.formOptions[option])
      if (save) this.saveInstance()
      //this.form.options[option] =
    },
    changeFormOption(value) {
      console.log('changeFormOption', value)
      Vue.set(this.formOptions, value[0], value[1])
    },
    closeOptionsModal() {
      this.showOptions = false
    },
    async loadExamInstance(initialLoading = false) {
      let instance = await FormServices.getInstance(this.instanceID)
      //order versions, to be sure we have the same versions letters
      instance.forms.sort((a, b) => {
        return a.index - b.index
      })
      this.instance = instance
      this.remainingSeconds = instance.remaining_time
      if (instance.remaining_time > 0 && this.timerInterval == null && instance.status == 'running') {
        this.startTime()
      }
      this.permissions = instance.permissions
      this.users = instance.users
      delete this.instance.users
      delete this.instance.permissions
      //this.checkFormClosureAbility()

      if (initialLoading && instance.forms.length > 0) {
        await this.loadForm(instance.forms[0].key)
      }

      if (initialLoading) {
        setTimeout(() => {
          this.initialLoading = false
        }, 750)
      }
    },
    async loadForm(formkey) {
      let form = await FormServices.getForm(formkey)
      this.form = form
      this.formOptions = form.options
    },
    async updatePermissions(data = null) {
      if (data != null) {
        this.permissions = data
      } else {
        let instance = await FormServices.getInstance(this.instanceID)
        this.permissions = instance.permissions
      }
    },
    async getUsersData(users = []) {
      let usersData = await FormServices.getUsersInfo(users)
      this.users = Object.assign(this.users, usersData)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "examStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Fechado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  },
  "en": {
    "examStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Fechado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  }
}
</i18n>
