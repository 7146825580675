<template>
  <fw-layout
    :loading="loading"
    back-to-enable
    :right-sidebar-expanded="true"
    :right-sidebar-width="'editor-sidebar'"
    :main-sidebar="false"
    :footer="false"
    :notfound="!loading && !page"
    :connected-users="connectedUsers"
    wide
    full
    inner-content-scrollable
    :back-to="'/pages'"
    paddingless
  >
    <template #header-nav>
      <div class="flex gap-3 items-center">
        <div v-if="currentVersion" class="flex gap-3 items-center">
          <fw-heading
            size="md"
            marginless
            class="line-clamp-1 max-w-md"
            :class="{ 'text-gray-400': currentVersion.title.length == 0 }"
            >{{ currentVersion.title.length > 0 ? currentVersion.title : 'Sem título' }}</fw-heading
          >
        </div>
      </div>
    </template>
    <template #main-content>
      <PagesContentTranslator
        v-if="translator && currentVersion"
        :key="currentVersionKey + 'translator' + currentVersion.state"
        :ref="'editor_' + currentVersionKey"
        :page-key="pageKey"
        :page="page"
        :version-key="currentVersionKey"
        :version="currentVersion"
        :original-key="originalKey"
        :validations="validations"
        :editable="editable"
        :users="users"
        :comments-document="comments['document']"
        :comments="comments"
        :active-block="activeBlock"
        class="px-5 pb-5 main-editor min-h-full max-h-full"
        :class="{
          rtl: isRTL,
        }"
        @set-active-block="setActiveBlock($event)"
        @change-version="changeVersion($event)"
        @new-language="createNewLanguage()"
        @title-changed="updateTitle($event)"
        @new-comment="newComment"
        @document-loaded="prepareInlineCommentsNumber"
      />
      <PagesContentEditor
        v-else-if="page && currentVersion && validations"
        :key="currentVersionKey + 'editor' + currentVersion.state"
        :ref="'editor_' + currentVersionKey"
        class="main-editor min-h-full max-h-full"
        :class="{
          rtl: isRTL,
        }"
        :page-key="pageKey"
        :page="page"
        :version="currentVersion"
        :validations="validations"
        :edit="edit"
        :editable="editable"
        :users-editing="usersEditing"
        :users="users"
        :active-block="activeBlock"
        :comments-document="comments['document']"
        :comments="comments"
        @remove-content="removeBlock"
        @restore-block="restoreBlock"
        @change-order="changeBlockOrder"
        @set-active-block="setActiveBlock($event)"
        @file-uploaded="addFile"
        @files-uploaded="addFiles"
        @file-deleted="removeFile"
        @new-language="createNewLanguage()"
        @selection-update="selectionUpdate"
        @selected="selectedCommand = $event"
        @title-changed="updateTitle($event)"
        @new-comment="newComment"
        @document-loaded="documentLoaded"
      ></PagesContentEditor>
    </template>
    <template #right-sidebar>
      <sidebar-block-detail
        v-if="activeBlock != null && !loadingComments"
        :key="
          'sidebar-block-detail-' +
            activeBlock.segmentId +
            (comments[activeBlock.segmentId] ? comments[activeBlock.segmentId].length : 0) +
            sidebarKey
        "
        :block="activeBlock"
        :comments="comments[activeBlock.segmentId]"
        :users="users"
        :version="currentVersion"
        :myrole="myrole"
        @open-comment="openComment($event)"
        @new-comment="newComment(activeBlock.segmentId)"
        @edit-comment="editComment"
        @delete-comment="deleteComment"
        @close="setActiveBlock(null)"
      />
      <sidebar-editor
        v-else
        :myrole="myrole"
        :versions="page.versions"
        :editable="editable"
        :active-commands="activeCommands"
        :selected-command="selectedCommand"
        :active-block-type="activeBlockType"
        :editor-command="editorCommand"
        :sidebar="sidebar"
        :current-version="currentVersion"
        :translator="translator"
        :comments="commentsList"
        :users="users"
        :page="page"
        @tab="setSidebar($event)"
        @change-version="changeVersion($event)"
        @new-language="createNewLanguage()"
        @import-file="importFile"
        @new-comment="newComment"
        @open-comment="openComment($event)"
        @delete-comment="deleteComment"
        @edit-comment="editComment"
        @export-file="exportDocument"
        @snapshot="createSnapshot()"
        @share-document="modal = 'share'"
        @change-state="changePageState"
        @delete-document="deleteDocument()"
        @delete-version="deleteVersion()"
      />
    </template>
    <template #modals>
      <b-modal
        :active="modal == 'new-language'"
        :width="400"
        scroll="keep"
        trap-focus
        aria-role="dialog"
        aria-modal
        :can-cancel="true"
        custom-class="rounded-buefy-modal z-10 bg-white bg-opacity-50 backdrop-blur-sm"
        style="z-index: 10"
        @close="closeModal"
      >
        <ModalNewLanguage
          v-if="page != null"
          :used-languages="usedLanguages"
          :page-key="page.key"
          @success="appendNewVersion"
          @close="closeModal"
        ></ModalNewLanguage>
      </b-modal>
      <fw-modal :active="modal == 'comment'" confirm-close paddingless @close="closeModal">
        <BlockEditPost
          :collection-key="commentsCollectionKey"
          :parent-post-key="null"
          :post="activeComment"
          :type="'comment'"
          :allow-files="false"
          :metadata="activeCommentMetadata"
          @comment-saved="commentSaved($event)"
          @close="closeModal"
        />
      </fw-modal>
      <fw-modal :active="modal == 'share'" confirm-close paddingless @close="closeModal">
        <ModalShare
          :page-key="pageKey"
          :translate-mode="translator"
          :myrole="myrole"
          :version-key="currentVersionKey"
          :is-share-link-active="page.options.allow_public_url"
          @close="closeModal"
        />
      </fw-modal>
    </template>
  </fw-layout>
</template>
<script>
import ServicePages from '../services/ServicePages'
import Vue from 'vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'
export default {
  name: 'ViewEditor',
  components: {
    PagesContentEditor: () => import('../components/panels/PanelPagesContentEditor.vue'),
    PagesContentTranslator: () => import('../components/panels/PanelPagesContentTranslator.vue'),
    ModalNewLanguage: () => import('../components/modals/ModalNewLanguage.vue'),
    SidebarEditor: () => import('../components/sidebars/SidebarEditor.vue'),
    BlockEditPost: () => import('@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditPost'),
    SidebarBlockDetail: () => import('../components/sidebars/SidebarBlockDetail.vue'),
    ModalShare: () => import('@/fw-modules/fw-core-vue/pages/components/modals/ModalShare'),
  },
  data() {
    return {
      course: {},
      current: {},
      users: {},
      page: null,
      myrole: null,
      loading: false,
      loadingComments: false,
      currentVersion: null,
      currentVersionKey: null,
      errorSaving: false,
      savingData: false,
      dirtyBlocks: [],
      activeBlock: null,
      modal: null,
      sidebar: 'comments',
      //Real time presence status
      realtimeDebouncer: null,
      selectedCommand: 'paragraph',
      usersEditing: {},
      titleDebouncer: null,
      activeCommentMetadata: null,
      activeComment: null,
      connectedUsers: [],
      activeCommands: {},
      comments: {}, //comments will be stored in a dictionary with the block id as key
      commentsList: [], //list from more recent to older
      sidebarKey: 0,
      statesLabes: {
        pt: {
          draft: 'Rascunho',
          review: 'Em revisão',
          published: 'Publicado',
          closed: 'Bloqueado',
        },
        en: {
          draft: 'Draft',
          review: 'Under review',
          published: 'Published',
          closed: 'Locked',
        },
      },
      hppermissions: {
        read: false,
        write: false,
      },
    }
  },
  computed: {
    isRTL() {
      if (!this.currentVersion) {
        return false
      }
      let languagesRtl = ['ar', 'he', 'fa']
      let languageCode = this.currentVersion.language.split('-')[0]
      return this.currentVersion && languagesRtl.includes(languageCode)
    },
    language() {
      return this.$i18n.locale
    },
    commentsCollectionKey() {
      return this.currentVersion && this.currentVersion.collections.length > 0
        ? this.currentVersion.collections[0].key
        : null
    },
    originalKey() {
      let original = this.page.versions.find(v => v.is_default)
      console.log('original', original)
      return original ? original.key : null
    },
    usedLanguages() {
      return this.page.versions.map(v => v.language)
    },
    wsMessages() {
      return this.$store.state.session.unreadExamWsMessages
    },
    socketId() {
      return this.$store.state.socket.connectionId
    },
    activeBlockType() {
      if (this.currentVersion == null || this.currentVersion.blocks == null || this.currentVersion.blocks.length == 0)
        return null
      if (this.activeBlock >= this.currentVersion.blocks.length) {
        return null
      }
      if (this.activeBlock >= 0) {
        return this.currentVersion.blocks[this.activeBlock].type
      } else {
        return null
      }
    },
    /*activeBlockStyle() {
      return this.currentVersion.blocks[this.activeBlock]?.content?.style ?? 'h1'
    },*/
    expandedRightSidebar() {
      return this.sidebar != null
    },
    pageKey() {
      return this.$route.params.key
    },
    versionKey() {
      if (this.$route.params.version) {
        return this.$route.params.version
      }
      if (this.page && this.page.versions && this.page.versions.length > 0) {
        return this.page.versions[0].key
      }
      return null
    },
    editable() {
      return this.currentVersion != null && this.currentVersion.state == 'draft' && this.hppermissions.write
    },
    edit() {
      return this.$route.meta.edit
    },
    translator() {
      return this.$route.meta.translator
    },
    validations() {
      return this.currentVersion != null && this.currentVersion.validations != null
        ? this.currentVersion.validations
        : null
    },
    status() {
      return this.currentVersion?.state || 'draft' // this.page?.state || 'draft'
    },
  },
  watch: {
    wsMessages(newMessages) {
      if (this.realtimeDebouncer == null && newMessages && newMessages.length > 0) {
        this.realtimeDebouncer = setTimeout(() => {
          console.log('editorWSMessages changed', newMessages)
          //change user state
          for (let index = 0; index < newMessages.length; index++) {
            const message = newMessages[index]
            console.log('newMessage', message)
            if (message.type == 'editorOnlineUsers' && message.data.length > 0) {
              let currentVersionUsers = message.data.filter(
                element =>
                  element.page_version_key == this.versionKey || element.page_version_key == this.currentVersionKey
              )
              if (currentVersionUsers.length > 0) {
                console.log('currentVersionUsers', currentVersionUsers[0].users)
                this.connectedUsers = Array.isArray(currentVersionUsers[0].users)
                  ? currentVersionUsers[0].users
                  : Object.keys(currentVersionUsers[0].users).map(key => {
                      let userData = currentVersionUsers[0].users[key]
                      userData['key'] = key
                      return userData
                    })
              }
            } else if (message.type == 'editorUserBlockSelect') {
              Vue.set(this.usersEditing, message.page_version_key + ':' + message.block_key, message.user)
              //this.usersEditing[message.page_version_key + ':' + message.block_key] = message.user
            } else if (message.type == 'editorUserBlocUnselect') {
              if (this.usersEditing[message.page_version_key + ':' + message.block_key].key == message.user.key) {
                //this.usersEditing[message.page_version_key + ':' + message.block_key] = null
                Vue.set(this.usersEditing, message.page_version_key + ':' + message.block_key, null)
              }
            } else if (message.type == 'editorPostDelete') {
              //comment deleted
              let post = message['comment'] ? message['comment'] : message['post']
              console.log('editorPostDelete', post)
              this.deleteComment(post)
            } else if (message.type == 'editorNewComment') {
              //comment created
              let post = message['comment'] ? message['comment'] : message['post']
              let user = message.user
              if (user && user.key) {
                this.users[user.key] = user
              }
              console.log('editorNewComment', post)
              this.insertComment(post, true)
            } else if (message.type == 'editorPostEdit') {
              //comment edited
              let post = message['comment'] ? message['comment'] : message['post']
              console.log('editorPostEdit', post)
              this.insertComment(post)
            }
          }
          this.$store.commit('removeFromExamMessageQueue', newMessages.length)
          this.realtimeDebouncer = null
        }, 1000)
      }
    },
    socketId(newSocketId) {
      if (newSocketId != null) {
        this.subscribeEditor(this.versionKey || this.currentVersionKey)
      }
    },
    versionKey(newVal, oldVal) {
      if (oldVal) {
        this.unsubscribeEditor(oldVal)
      }
      if (newVal != oldVal) {
        this.subscribeEditor(newVal)
        console.log('version key changed')
        //reset values:
        this.activeBlock = null
        this.connectedUsers = []
        this.comments = {}
        this.commentsList = []
        this.dirtyBlocks = []
        this.activeCommentMetadata = null
        this.activeComment = null

        let versionIndex = this.page.versions.findIndex(element => element.key == newVal)

        if (versionIndex == -1) {
          versionIndex = this.page.versions.findIndex(element => element.is_original)
        }

        if (versionIndex == -1) {
          console.log('version not found')
          versionIndex = 0
        }

        this.currentVersionKey = this.page.versions[versionIndex].key
        this.loadPageVersion()
      }
    },
  },
  created() {
    this.loadPageContent()
    this.subscribeEditor(this.versionKey || this.currentVersionKey)
  },
  beforeDestroy() {
    this.unsubscribeEditor(this.versionKey || this.currentVersionKey)
  },
  methods: {
    deleteDocument() {
      this.$buefy.dialog.confirm({
        title: this.$t('delete_document_title'),
        message: this.$t('delete_document_message'),
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: async () => {
          try {
            let result = await ServicePages.deletePage(this.pageKey)
            console.log('deletePage', result)
            this.$router.push({ name: 'content-pages' })
          } catch (e) {
            console.error(e)
          }
        },
      })
    },
    deleteVersion() {
      this.$buefy.dialog.confirm({
        title: this.$t('delete_version_title'),
        message: this.$t('delete_version_message'),
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: async () => {
          try {
            let result = await ServicePages.deletePageVersion(this.pageKey, this.currentVersionKey)
            console.log('deletePageVersion', result)
            this.$router.push({ name: 'content-pages-editor', params: { key: this.pageKey } })
          } catch (e) {
            console.error(e)
          }
        },
      })
    },
    createNewLanguage() {
      //verify if original language has state closed
      let originalVersion = this.page.versions.find(element => element.is_original)
      if (originalVersion.state != 'draft') {
        this.modal = 'new-language'
      } else {
        this.$buefy.dialog.confirm({
          title: this.$t('warning_title'),
          message: this.$t('warning_message'),
          confirmText: this.$t('warning_confirm'),
          cancelText: this.$t('warning_cancel'),
          onConfirm: async () => {
            //lock original version
            let result = await ServicePages.changePageState(this.pageKey, originalVersion.key, 'closed')
            console.log('changePageState', result)
            this.modal = 'new-language'
          },
        })
      }
    },
    changePageState(state) {
      this.$buefy.dialog.confirm({
        title: this.$t('change_state_title'),
        message: this.$t('change_state_message') + ' ' + state + '?',
        confirmText: this.$t('change_state_confirm'),
        cancelText: this.$t('change_state_cancel'),
        onConfirm: async () => {
          try {
            let result = await ServicePages.changePageState(this.pageKey, this.currentVersionKey, state)
            console.log('changePageState', result)
            //change state of version
            this.currentVersion.state = state
            this.page.versions.forEach(element => {
              if (element.key == this.currentVersionKey) {
                element.state = state
              }
            })
          } catch (e) {
            console.error(e)
          }
        },
      })
    },
    openComment(comment) {
      //scroll to the block,
      //highlight the block
      console.log('openComment', comment)
      if (comment.metadata && comment.metadata.blockId) {
        this.scrollToBlock(comment.metadata.blockId)
      }
    },
    scrollToBlock(id) {
      console.log('scrollToBlock', id)
      //scroll smothly to element with the id
      let element = document.getElementById(id)
      let container = document.querySelector('.main-content')
      if (element) {
        console.log('scrolling to element', element)
        container.scrollTo({
          top: element.offsetTop - 100,
          behavior: 'smooth',
        })
      }
    },
    documentLoaded() {
      Vue.nextTick(() => {
        this.prepareInlineCommentsNumber()
      })
    },
    prepareInlineCommentsNumber() {
      //get all dom elements with the class block_comments
      let blockComments = Array.from(document.querySelectorAll('.block_comments'))
      //in the dom elements, its id its in data-id prop
      //console.log('blockComments', blockComments)
      //for eack block key in the comments
      Object.keys(this.comments).forEach(blockKey => {
        console.log('blockKey', blockKey)
        //get the block element
        let blockElement = blockComments.find(element => element.dataset.id == blockKey)
        //console.log('blockElement', blockElement)
        if (blockElement) {
          //get parent element
          let parentElement = blockElement.parentElement
          //add class
          parentElement.classList.add('opacity-100')
          //remove class
          parentElement.classList.remove('opacity-0')
          //console.log('parentElement', parentElement)
          //get the number of comments
          let comments = this.comments[blockKey]
          //set the number of comments in the element
          blockElement.innerHTML = comments.length
        }
      })
    },
    async loadAllComments() {
      this.loadingComments = true
      this.comments = {}
      this.comments['document'] = []
      let result = await this.loadComments(1)
      console.log('loadAllComments', result)
      let totalPages = result.pagination.total_pages
      if (totalPages > 1) {
        for (let index = 2; index <= totalPages; index++) {
          await this.loadComments(index)
        }
      }
      //await 1000 seconds to load all comments
      await new Promise(resolve => setTimeout(resolve, 2000))
      this.prepareInlineCommentsNumber()
      this.loadingComments = false
    },
    async loadComments(page = 1) {
      if (this.currentVersion.collections.length > 0) {
        console.log('load comments', this.currentVersion.collections)
        try {
          let data = await ServicePosts.getCollectionPosts(
            this.currentVersion.collections[0].key,
            page,
            50, //load all comments (or almost)
            'comment'
          )
          console.log('loadComments :>> ', data)
          let comments = data.posts
          this.users = { ...this.users, ...data.users }
          comments.forEach(comment => {
            if (this.comments[comment.metadata.blockId] == null) {
              this.comments[comment.metadata.blockId] = []
            }
            //verify if comment is already in the list
            let index = this.comments[comment.metadata.blockId].findIndex(element => element.key == comment.key)
            if (index == -1) {
              this.comments[comment.metadata.blockId].push(comment)
              this.commentsList.push(comment)
            }
          })
          return data
        } catch (e) {
          console.error(e)
        }
      }
    },
    exportDocument(format) {
      let targetLanguage = this.page.versions.find(element => element.key == this.currentVersionKey).language
      let title = this.page.versions.find(element => element.key == this.currentVersionKey).title
      switch (format) {
        case 'xliff': {
          if (this.translator) {
            let originalDocument = this.$refs['editor_' + this.currentVersionKey].getDocument('json')
            let targetDocument = this.$refs['editor_' + this.currentVersionKey].getDocument('json')
            let xlf = utils.generateXLIFF(originalDocument, targetDocument, '2.0')
            let blob = new Blob([xlf], { type: 'application/xml' })
            utils.downloadFileFromBlob(blob, title + '-' + targetLanguage + '.xlf')
          } else {
            this.$buefy.toast.open({
              message: this.$t('warning_xliff'),
              type: 'is-danger',
              position: 'is-bottom-right',
            })
          }
          break
        }
        case 'text': {
          let text = this.$refs['editor_' + this.currentVersionKey].getDocument('text')
          let blob = new Blob([text], { type: 'text/plain' })
          utils.downloadFileFromBlob(blob, title + '-' + targetLanguage + '.txt')
          break
        }
        case 'html': {
          let html = this.$refs['editor_' + this.currentVersionKey].getDocument('html')
          let blob = new Blob([html], { type: 'text/html' })
          utils.downloadFileFromBlob(blob, title + '-' + targetLanguage + '.html')
          break
        }
        default:
          break
      }
    },
    newComment(blockId = 'document') {
      this.activeComment = null
      this.activeCommentMetadata = {
        blockId: blockId,
      }
      this.modal = 'comment'
    },
    commentSaved(comment) {
      console.log('commentSaved', comment)
      if (this.activeComment == null) {
        let blockId = comment.metadata.blockId
        //its a new comment, add it to list and map
        if (!this.comments[blockId]) {
          Vue.set(this.comments, blockId, [])
        }
        this.comments[blockId].unshift(comment)
        //add it to first position
        this.commentsList.unshift(comment)
      } else {
        //replace content in list and map
        let blockId = comment.metadata.blockId
        let index = this.comments[blockId].findIndex(element => element.key == comment.key)
        let indexList = this.commentsList.findIndex(element => element.key == comment.key)
        if (index != -1) {
          Vue.set(this.comments[blockId], index, comment)
        }
        if (indexList != -1) {
          Vue.set(this.commentsList, indexList, comment)
        }
      }
      this.sidebarKey++
      this.prepareInlineCommentsNumber()
      this.activeComment = null
      this.closeModal()
    },
    insertComment(comment, isNewComment = false) {
      console.log('commentSaved', comment)
      if (isNewComment) {
        //verify if comment is already in the list
        if (this.commentsList.some(element => element.key == comment.key)) {
          console.log('comment already exists')
          return
        }
        let blockId = comment.metadata.blockId
        //its a new comment, add it to list and map
        if (!this.comments[blockId]) {
          Vue.set(this.comments, blockId, [])
        }
        this.comments[blockId].unshift(comment)
        //add it to first position
        this.commentsList.unshift(comment)
      } else {
        //replace content in list and map
        let blockId = comment.metadata.blockId
        let index = this.comments[blockId].findIndex(element => element.key == comment.key)
        let indexList = this.commentsList.findIndex(element => element.key == comment.key)
        if (index != -1) {
          Vue.set(this.comments[blockId], index, comment)
        }
        if (indexList != -1) {
          Vue.set(this.commentsList, indexList, comment)
        }
      }
      this.sidebarKey++
      this.prepareInlineCommentsNumber()
      this.closeModal()
    },
    deleteComment(comment) {
      console.log('deleteComment', comment)
      let blockId = comment.metadata.blockId
      let index = this.comments[blockId].findIndex(element => element.key == comment.key)
      let indexList = this.commentsList.findIndex(element => element.key == comment.key)
      if (index != -1) {
        Vue.delete(this.comments[blockId], index)
      }
      if (indexList != -1) {
        Vue.delete(this.commentsList, indexList)
      }
      this.prepareInlineCommentsNumber()
    },
    editComment(comment) {
      this.activeCommentMetadata = comment.metadata
      this.activeComment = comment
      this.modal = 'comment'
    },
    saveComment(comment) {
      console.log('saveComment', comment)
      this.activeComment = null
      this.closeModal()
    },
    importFile(file) {
      console.log('importing file', file)
      if (file.name.split('.').pop() == 'xlf') {
        let self = this
        let reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onload = async function() {
          let data = utils.parseXLIFF(reader.result)
          console.log('processed', data)
          let processedData = utils.createTiptapDocumentFromXLIFF(data)
          let originalDocument = processedData.document
          let comments = processedData.comments
          let targetDocument = utils.createTiptapDocumentFromXLIFF(data, 'target').document
          //verify if target language is created
          let targetLanguage = data[0].segments[0].targetLanguage
          console.log('targetLanguage', targetLanguage)
          if (self.page) {
            //verify if the target language is already created
            let existingVersion = self.page.versions.find(
              element => element.language.toLowerCase() == targetLanguage.toLowerCase()
            )
            if (existingVersion) {
              //open the version
              self.$router.push({
                name: 'content-pages-translator',
                params: { key: self.page.key, version: existingVersion.key },
              })
              //wait for the version to load
              //update the document
            } else {
              //create the version
              let newVersion = await ServicePages.createPageVersion(self.pageKey, targetLanguage)
              console.log('newVersion', newVersion)
              self.$router.push({
                name: 'content-pages-translator',
                params: { key: self.page.key, version: newVersion.version.key },
              })
            }
            setTimeout(() => {
              //self.editor.commands.setContent(targetDocument)
              console.log('ASYNC original', originalDocument, 'target', targetDocument, 'comments', comments)
              self.$refs['editor_' + self.currentVersionKey].importDocument(originalDocument, targetDocument, comments)
            }, 3000)
          }
          //if not, create it
          //if created, update the document

          //to broadcast the new document:
          //self.editor.commands.setContent(originalDocument)
          console.log('original', originalDocument, 'target', targetDocument, 'comments', comments)
          //comments will be created in a normal way (the user that uploads the document is the author of all the comments)
        }
        reader.onerror = function(evt) {
          console.error('error reading file', evt)
        }
        this.$buefy.toast.open({
          message: this.$t('file_imported'),
          type: 'is-success',
          position: 'is-bottom-right',
        })
      } else {
        this.$buefy.toast.open({
          message: this.$t('warning_invalid_file'),
          type: 'is-danger',
          position: 'is-bottom-right',
        })
        return
      }
    },
    createDocumentFromXLIFF(data, type = 'source') {
      //TODO: Convert to tiptap document (original + target)
      let comments = []
      let document = {
        type: 'doc',
        content: [],
      }
      for (let d = 0; d < data.length; d++) {
        //file
        if (data[d].notes) {
          //concat notes with comments
          comments.concat(data[d].notes)
        }
        for (let s = 0; s < data[d].segments.length; s++) {
          document.content.push({
            type: 'paragraph',
            attrs: {
              id: data[d].segments[s].unitId,
              groupId: data[d].segments[s].groupId ? data[d].segments[s].groupId : null,
            },
            content: [
              {
                type: 'text',
                text: data[d].segments[s][type],
              },
            ],
          })
          //Add block notes
          comments.concat(
            data[d].segments[s].notes.map(note => {
              return {
                blockId: data[d].segments[s].unitId,
                content: note,
              }
            })
          )
        }
      }
      return { document, comments }
    },
    updateTitle(title) {
      if (!this.hppermissions.write) {
        return
      }
      if (this.currentVersion) {
        this.currentVersion.title = title
      }
      //update versions
      if (this.page) {
        this.page.versions.forEach(element => {
          if (element.key == this.currentVersionKey) {
            element.title = title
          }
        })
      }
      //debounce save title
      if (this.titleDebouncer) {
        clearTimeout(this.titleDebouncer)
      }
      this.titleDebouncer = setTimeout(async () => {
        let result = await ServicePages.updatePageTitle(this.pageKey, this.currentVersionKey, this.currentVersion.title)
        console.log('updateTitle', result)
      }, 1000)
    },
    setSidebar(tab) {
      console.log('setSidebar', tab)
      this.sidebar = tab
    },
    async createSnapshot() {
      try {
        const result = await ServicePages.createSnapshot(this.page.key, this.versionKey || this.currentVersionKey)
        console.log('createSnapshot', result)
      } catch (e) {
        console.error(e)
      }
    },
    changeVersion(version) {
      if (!version.is_default) {
        this.$router.push({
          name: 'content-pages-translator',
          params: { key: this.page.key, version: version.key },
        })
      } else if (this.editable) {
        this.$router.push({
          name: 'content-pages-editor-with-version',
          params: { key: this.page.key, version: version.key },
        })
      } else {
        this.$router.push({
          name: 'content-pages-viewer-with-version',
          params: { key: this.page.key, version: version.key },
        })
      }
    },
    selectionUpdate(updated) {
      console.log('selectionUpdate', updated)
      this.activeCommands = updated
      //this.selectedCommand = updated.style ?? 'paragraph'
    },
    appendNewVersion(newVersionData) {
      this.page.versions.push(newVersionData.version)
      this.users = newVersionData.users
      this.$router.push({
        name: 'content-pages-translator',
        params: { key: this.pageKey, version: newVersionData.version.key },
      })
      this.closeModal()
    },
    subscribeEditor(versionKey) {
      if (versionKey) {
        //Subscribe editor
        ServicePages.subscribeToPage('subscribe_editor', this.pageKey, versionKey, this.socketId)
      } else {
        console.error('Version key is required for subscribing to editor')
      }
    },
    unsubscribeEditor(versionKey) {
      //Unsubscribe editor
      ServicePages.subscribeToPage('unsubscribe_editor', this.pageKey, versionKey, this.socketId)
    },
    restoreBlock(index, block) {
      this.currentVersion.blocks.splice(index, 0, block)
    },
    removeBlock(index) {
      this.currentVersion.blocks.splice(index, 1)
    },
    changeBlockOrder(dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return

      const result = [...this.currentVersion.blocks]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      this.currentVersion.blocks = result
    },
    editorCommand(cmd) {
      console.log('editor command ' + cmd)
      if (!['meta', 'paragraph', 'codeblock', 'h1', 'h2', 'h3'].includes(cmd)) {
        //toggle command
        this.activeCommands[cmd] = !this.activeCommands[cmd]
      }
      this.$refs['editor_' + this.currentVersionKey].execCommand(this.currentVersion.blocks[this.activeBlock].key, cmd)
    },
    markDirty(blockKey) {
      if (!this.dirtyBlocks.includes(blockKey)) {
        this.dirtyBlocks.push(blockKey)
      }
    },
    setActiveBlock(blockObject) {
      console.log('Set active block ', blockObject)
      this.activeBlock = blockObject
      //this.broadcastBlockFocus(this.currentVersion.blocks[index].key)
      /*Vue.nextTick(() => {
        if ((this.activeBlockType == 'text' || this.activeBlockType == 'heading') && this.sidebar == null) {
          this.sidebar = 'editor'
        }
        this.selectedCommand = this.activeBlockStyle
      })*/
    },
    async addFile(blockIndex, file) {
      console.log('add file')
      if (this.currentVersion.blocks[blockIndex].files == null) {
        this.currentVersion.blocks[blockIndex].files = []
      }
      let type = this.currentVersion.blocks[blockIndex].type
      if (type == 'image' || type == 'video') {
        let result = await ServicePages.filesPreview([file.key])
        console.log('FILE PREVIEW', result)
        this.currentVersion.blocks[blockIndex].files = [result[0]]
      } else {
        this.currentVersion.blocks[blockIndex].files.push(file)
      }
    },
    async addFiles(blockIndex, files) {
      console.log('add file')
      if (this.currentVersion.blocks[blockIndex].files == null) {
        this.currentVersion.blocks[blockIndex].files = files
      }
      let type = this.currentVersion.blocks[blockIndex].type
      if (type == 'image' || type == 'video') {
        let result = await ServicePages.filesPreview(files.map(f => f.key))
        console.log('FILE PREVIEW', result)
        this.currentVersion.blocks[blockIndex].files.concat(files)
      } else {
        this.currentVersion.blocks[blockIndex].files.concat(files)
      }
    },
    removeFile(file) {
      var blockIndex = file.blockIndex
      //get file index from file key
      var fileIndex = this.currentVersion.blocks[blockIndex].files.findIndex(element => {
        return element.key == file.fileKey
      })
      console.log('remove file', fileIndex, file.blockIndex)
      this.currentVersion.blocks[blockIndex].files.splice(fileIndex, 1)
    },
    closeModal() {
      this.activeCommentMetadata = null
      this.modal = null
    },
    saveData() {
      //save document data
      this.savingData = true
      let updateTitle = false
      let blocks = JSON.parse(JSON.stringify(this.currentVersion.blocks))

      var isTitleDirty = this.dirtyBlocks.indexOf('title')
      if (isTitleDirty !== -1) {
        this.dirtyBlocks.splice(isTitleDirty, 1)
        updateTitle = true
      }
      let dirtyBlocks = blocks.filter(element => {
        return this.dirtyBlocks.includes(element.key)
      })

      let updatePromises = []

      if (updateTitle) {
        updatePromises.push(
          ServicePages.updatePageTitle(this.pageKey, this.currentVersionKey, this.currentVersion.title)
        )
      }
      if (this.dirtyBlocks.length > 0) {
        updatePromises.push(ServicePages.updatePageContentBlocksBulk(this.pageKey, this.currentVersionKey, dirtyBlocks))
      }
      Promise.all(updatePromises)
        .then(result => {
          let newdata = updateTitle ? result[1] : result[0]
          console.log('saved', newdata)
          //replace block ids
          this.currentVersion.blocks.map(block => {
            //console.log('onld block key', block.key)
            if (newdata[block.key]) {
              //console.log('new block', newdata[block.key])
              let newkey = newdata[block.key].key
              block.key = newkey
            }
            //block.key = result[index].key
          })
          this.savingData = false
          this.errorSaving = false
          this.dirtyBlocks = []
        })
        .catch(e => {
          console.error(e)
          this.savingData = false
          this.errorSaving = true
        })
    },
    async loadPageVersion() {
      let result = await ServicePages.getPageVersionContent(this.pageKey, this.currentVersionKey)
      console.log('page version > ', result)
      if (result.blocks == null) result.blocks = []
      //if (result.title == null) result.title = ''
      result.blocks.sort((a, b) => a.index - b.index)
      //result.version['title'] = result.title
      if (result.version.title == null) result.version.title = ''
      this.currentVersion = result.version
      this.hppermissions = result.permissions
      //change to translation mode if its not original
      if (this.currentVersion.is_original == false && !this.translator) {
        this.$router.push({
          name: 'content-pages-translator',
          params: { key: this.pageKey, version: this.currentVersionKey },
        })
      }
      this.loadAllComments()
    },
    async loadPageContent() {
      this.loading = true
      try {
        if (this.page == null) {
          let pageVersions = await ServicePages.getPageVersions(this.pageKey)
          console.log('load page > ', pageVersions)
          this.page = pageVersions.page
          this.users = pageVersions.users
          this.myrole = pageVersions.user.role
          let versionIndex = this.page.versions.findIndex(element => element.key == this.versionKey)

          if (versionIndex == -1) {
            versionIndex = this.page.versions.findIndex(element => element.is_original)
          }

          if (versionIndex == -1) {
            console.log('version not found 2')
            versionIndex = 0
          }
          this.currentVersionKey = this.page.versions[versionIndex].key
          this.loadPageVersion()
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style>
.editor-sidebar {
  width: 20rem;
}
.main-editor .ProseMirror {
  background: white !important;
}
.main-editor .ProseMirror h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

.main-editor .ProseMirror h2 {
  font-size: 1.3rem;
  font-weight: bold;
}

.main-editor.rtl .main-text .ProseMirror p {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h1 {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h2 {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h3 {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h4 {
  direction: rtl;
}

.main-editor .ProseMirror pre {
  background-color: #444444 !important;
  color: #ffffff;
  font-family: monospace;
  padding: 6px 8px;
  border-radius: 6px;
  font-size: 0.9em;
}
.main-editor .ProseMirror ul {
  padding-left: 20px;
  list-style-type: disc;
}

.main-editor .ProseMirror h3 {
  font-size: 1.1rem;
  font-weight: bold;
}

.main-editor .ProseMirror h4 {
  font-size: 1rem;
  font-weight: bold;
}
</style>
<i18n>
  {
    "pt": {
      "delete_document_title": "Eliminar documento",
      "delete_document_message": "Tem a certeza que pretende eliminar este documento?",
      "delete_version_title": "Eliminar tradução",
      "delete_version_message": "Tem a certeza que pretende eliminar esta tradução?",
      "confirm": "Confirmar",
      "cancel": "Cancelar",
      "change_state_title": "Alterar estado da página",
      "change_state_message": "Tem a certeza que pretende alterar o estado da página para",
      "change_state_confirm": "Sim",
      "change_state_cancel": "Cancelar",
      "warning_xliff": "Apenas no modo de tradução é que pode exportar documentos xliff",
      "warning_invalid_file": "Tipo de ficheiro inválido",
      "file_imported": "Ficheiro importado com sucesso",
      "warning_title": "Aviso",
      "warning_message": "Para criar uma tradução, é necessário que a versão original esteja bloqueada. Iremos bloquear a versão original. Deseja continuar?",
      "warning_confirm": "Continuar",
      "warning_cancel": "Cancelar"
    },
    "en": {
      "delete_document_title": "Delete document",
      "delete_document_message": "Are you sure you want to delete this document?",
      "delete_version_title": "Delete translation",
      "delete_version_message": "Are you sure you want to delete this translation?",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "change_state_title": "Change page state",
      "change_state_message": "Are you sure you want to change the page state to",
      "change_state_confirm": "Yes",
      "change_state_cancel": "Cancel",
      "warning_xliff": "Only in translation mode you can export xliff documents",
      "warning_invalid_file": "Invalid file type",
      "file_imported": "File imported successfully",
      "warning_title": "Warning",
      "warning_message": "To create a translation, the original version must be locked. We will lock the original version. Do you want to continue?",
      "warning_confirm": "Continue",
      "warning_cancel": "Cancel"
    }
  }
</i18n>
