<template>
  <fw-layout-main-content>
    <template v-if="classes.length" #sidebar>
      <div class="pl-3 py-2 flex items-center">
        <div class="flex-1">
          <fw-heading size="h4" :counter="pagination.total_items" muted>Turmas</fw-heading>
        </div>
        <div class="min-h-10">
          <b-dropdown aria-role="list" position="is-bottom-left">
            <template #trigger="{ active }">
              <fw-button size="sm">
                <fw-icon-more class="w-5 h-5" :class="{ 'opacity-50': active }" />
              </fw-button>
            </template>
            <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click="newClass()">
              Criar nova turma
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="flex flex-1 flex-col gap-2 overflow-y-auto">
        <RecycleScroller
          v-if="classes && classes.length > 0 && !loadingList"
          v-slot="{ item, index }"
          :items="classes"
          :item-size="45"
          :buffer="25"
          key-field="key"
          class="px-2"
        >
          <RecordClass :data="item" :selected="currentClassKey" @click.native="openClass(item)" />
        </RecycleScroller>
        <div v-else-if="loadingList" class="py-5 text-center text-gray-500 text-sm">A carregar...</div>
      </div>
      <BlockPagination
        v-if="pagination && pagination.total_pages > 1"
        :total="pagination.total_items"
        :total-pages="pagination.total_pages"
        :current.sync="page"
        @page-changed="pageChanged"
      />
    </template>
    <template #default>
      <div v-if="currentClass" :key="currentClass.key" class="w-full h-full mx-auto max-w-screen-lg">
        <div class="flex mb-1">
          <div class="flex-1">
            <fw-heading size="h2">
              {{ currentClass ? currentClass.name : 'Turma' }}
            </fw-heading>
          </div>
          <b-dropdown aria-role="list" position="is-bottom-left">
            <template #trigger="{ active }">
              <fw-button size="sm" class="opacity-50" :class="{ 'opacity-100': active }">
                <fw-icon-more class="w-5 h-5" />
              </fw-button>
            </template>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" icon="trash" label="Eliminar" @click.native="deleteClass()">
                Eliminar turma
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <b-tabs v-model="tab" :animated="false" @input="changeTab">
          <b-tab-item label="Pessoas" value="people">
            <PanelManageCourseEditionClassPeople
              v-if="subview == 'people' && currentClass"
              :key="currentClass.key"
              :class-data="currentClass"
              :course="course"
              :edition="edition"
            />
          </b-tab-item>
          <b-tab-item label="Agendamentos" value="agenda">
            <PanelManageCourseEditionClassAgenda
              v-if="subview == 'agenda' && currentClass"
              :key="currentClass.key"
              :class-data="currentClass"
              :course="course"
              :edition="edition"
              :class-key="currentClassKey"
            />
          </b-tab-item>
        </b-tabs>
      </div>
      <fw-panel-info v-else type="basic" centered class="m-4 h-full flex justify-center items-center">
        <div class="flex items-center justify-center mb-3">
          <fw-icon-list-view class="w-10 h-10 opacity-70" />
        </div>
        <div>
          <div v-if="!classes.length">Não foram definidas turmas.</div>
          <div v-else>Escolha uma turma para explorar.</div>
          <div v-if="!classes.length" class="flex items-center justify-center mt-3">
            <fw-button type="xlight" @click.native="newClass()">Criar nova turma</fw-button>
          </div>
        </div>
      </fw-panel-info>
      <fw-modal v-if="showNewClassModal" :active.sync="showNewClassModal" :can-cancel="true" size="min" @close="close">
        <template #default>
          <ModalNewClass @new="createNewClass($event)" @cancel="close()" />
        </template>
      </fw-modal>
    </template>
  </fw-layout-main-content>
</template>

<script>
import RecordClass from '@/fw-modules/fw-core-vue/courses/components/records/RecordClass'
import ModalNewClass from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewClass'
import CoursesServices from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
//import PanelManageCourseEditionClassDashboard from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionClassDashboard'
import PanelManageCourseEditionClassPeople from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionClassPeople'
import PanelManageCourseEditionClassAgenda from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionClassAgenda'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'

export default {
  name: 'PanelManageCourseEditionClass',

  components: {
    RecordClass,
    ModalNewClass,
    //PanelManageCourseEditionClassDashboard,
    PanelManageCourseEditionClassPeople,
    PanelManageCourseEditionClassAgenda,
    RecycleScroller,
    BlockPagination,
  },

  props: {
    course: {
      type: Object,
      required: true,
    },
    edition: {
      type: Object,
      required: true,
    },
  },

  data: function() {
    return {
      showNewClassModal: false,
      loading: false,
      loadingList: false,
      classes: [],
      pagination: {
        total_items: 0,
        total_pages: 0,
      },
      page: 1,
      currentClass: null,
      users: {},
      tab: 'people',
    }
  },

  computed: {
    total() {
      return this.pagination.total_items || 0
    },
    subview() {
      return this.$route.params.subview || 'people'
    },
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    canCreateClasses() {
      return true
    },
    currentClassKey() {
      return this.$route.params.classKey || null
    },
  },

  watch: {
    currentClassKey() {
      this.loadClassData()
    },
    subview() {
      this.tab = this.subview
    },
  },
  created() {
    this.loadClasses()
    this.loadClassData()
  },

  mounted() {
    this.tab = this.subview
  },

  methods: {
    deleteClass() {
      //ask confirmation
      this.$buefy.dialog.confirm({
        title: 'Apagar turma',
        message: 'Tem a certeza que deseja <b>eliminar</b> esta turma? Todos os dados serão eliminados.',
        confirmText: 'Apagar turma',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          await ServiceCourses.deleteClass(this.course.key, this.edition.key, this.currentClassKey)
          this.classes = this.classes.filter(c => c.key != this.currentClassKey)
          this.currentClass = null
          this.$router.push({
            name: 'course-manage-edition',
            params: { id: this.course.key, editionId: this.edition.key, view: 'classes' },
          })
        },
      })
    },

    async loadClassData() {
      if (this.currentClassKey) {
        console.log('loading current class')
        this.loading = true
        try {
          let data = await CoursesServices.getClass(this.course.key, this.edition.key, this.currentClassKey)
          console.log('load current class', data)
          this.currentClass = data.data.class
          this.users = data.data.users
        } finally {
          this.loading = false
        }
      }
    },

    openClass(classeData) {
      if (this.currentClassKey == classeData.key) {
        return
      }
      this.currentClass = null
      this.$router.push({
        name: 'course-manage-edition-class',
        params: { id: this.course.key, editionId: this.edition.key, classKey: classeData.key },
      })
    },

    changeTab(tab) {
      console.log('changeTab', tab)
      this.$router.push({
        name: 'course-manage-edition-class',
        params: { id: this.course.key, editionId: this.edition.key, classKey: this.currentClassKey, subview: tab },
      })
    },

    async createNewClass(data) {
      try {
        let result = await CoursesServices.createClass(this.course.key, this.edition.key, {
          title: data.name,
        })
        console.log('createNewClass', result)
        this.classes.push(result.data)
        this.close()
        this.$router.push({
          name: 'course-manage-edition-class',
          params: { id: this.course.key, editionId: this.edition.key, classKey: result.data.key },
        })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar a turma.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },

    async loadClasses() {
      this.loadingList = true
      try {
        let data = await CoursesServices.getClasses(this.course.key, this.edition.key, this.page)
        console.log('load classes', data)
        this.classes = data.data.classes
        this.pagination = data.data.pagination
        if (this.currentClassKey == null && this.classes[0]) {
          this.openClass(this.classes[0])
        }
      } finally {
        this.loadingList = false
      }
    },

    close() {
      this.showNewClassModal = false
    },

    newClass() {
      this.showNewClassModal = true
    },

    pageChanged(page) {
      this.page = page
      this.loadClasses()
    },
  },
}
</script>

<i18n>
    {
      "en": {
        "classes": "Classes"
      },
      "pt": {
        "classes": "Turmas"
      }
    }
  </i18n>
