<template>
  <div class="w-96 rounded-3xl bg-panel p-5 flex gap-2 flex-col flex-shrink-0 select-none">
    <div class="text-sm text-title font-semibold">Atividade</div>
    <div class="flex gap-3 text-sm">
      <div
        class=" cursor-pointer"
        :class="{
          'text-primary font-semibold': activityFilter === 'main',
          'color-inactive-tab': activityFilter !== 'main',
        }"
        @click="changeFilter('main')"
      >
        {{ $t('main') }}
      </div>
      <div
        class=" cursor-pointer"
        :class="{
          'text-primary font-semibold': activityFilter === 'detailed',
          'color-inactive-tab': activityFilter !== 'detailed',
        }"
        @click="changeFilter('detailed')"
      >
        {{ $t('detailed') }}
      </div>
    </div>
    <div v-if="filteredActivity.length > 0" class="flex-1 relative overflow-hidden">
      <div
        ref="activitylist"
        class="h-full overflow-y-scroll flex flex-col gap-3 justify-start rounded-lg hide-scrollbar"
      >
        <ActivityCard
          v-for="actv in filteredActivity"
          :key="'actv_' + actv.key"
          :activity="actv"
          :users="users"
          @open-user="$emit('open-user', $event)"
        >
        </ActivityCard>
      </div>
      <div v-if="activityOverflow" class="absolute bottom-0 left-0 right-0 h-10 dashboard-overflow-gradient"></div>
    </div>
    <div v-else class="flex-1 flex flex-col justify-center items-center">
      <fw-icon-rest class="color-icon-panel h-12 w-12 mb-2"></fw-icon-rest>
      <div class="text-title text-sm">{{ $t('noactivity') }}</div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import ActivityCard from '@/fw-modules/fw-core-vue/exams/components/cards/ActivityCard'
export default {
  name: 'PanelDashboardActivity',
  components: {
    ActivityCard,
  },
  props: {
    activity: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Object,
      required: true,
    },
    mainActivityCodes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activityFilter: 'main',
      activityOverflow: false,
    }
  },
  computed: {
    filteredActivity() {
      if (this.activityFilter === 'main') {
        return this.activity.filter(a => this.mainActivityCodes.includes(a.code))
      }
      return this.activity
    },
  },
  mounted() {
    Vue.nextTick(() => {
      this.verifyActivityOverflow()
    })
  },
  methods: {
    changeFilter(filter) {
      this.activityFilter = filter
      Vue.nextTick(() => {
        this.verifyActivityOverflow()
      })
    },
    verifyActivityOverflow() {
      let container = this.$refs.activitylist
      if (container) {
        this.activityOverflow = container.scrollHeight > container.clientHeight
        if (this.activityOverflow) {
          container.addEventListener('scroll', () => {
            if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
              this.activityOverflow = false
            } else {
              this.activityOverflow = true
            }
          })
        }
      } else {
        this.activityOverflow = false
      }
    },
  },
}
</script>
<i18n>
    {
      "pt": {
        "main": "Principal",
        "detailed": "Detalhada",
        "noactivity": "Sem atividade para mostrar."
      },
      "en": {
        "main": "Main",
        "detailed": "Detailed",
        "noactivity": "No activity do show."
      }
    }
</i18n>
