<template>
  <button
    class="flex p-3 gap-2 relative w-full text-left group rounded-lg items-center bg-white group-hover:bg-gray-100 hover:bg-gray-100"
  >
    <div class="font-bold text-sm pl-7 py-1 text-gray-700" @click="$emit('open')">
      <fw-icon-book-mark-solid class="w-5 h-5" />
    </div>
    <div class="flex-1" @click="$emit('open')">
      <v-clamp class="font-medium" autoresize :max-lines="1">{{ data.title.replace('.', '') }} </v-clamp>
    </div>
    <b-dropdown
      v-if="editable"
      class="mt-1"
      :scrollable="true"
      :max-height="200"
      aria-role="list"
      position="is-bottom-left"
    >
      <template #trigger="{ active }">
        <fw-icon-more class="h-5 w-5 text-gray-500 mr-2" />
      </template>
      <b-dropdown-item aria-role="listitem">
        <div class="flex justify-start items-center gap-3 font-semibold text-gray-600" @click="$emit('edit')">
          Editar nome
        </div>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem">
        <div class="flex justify-start items-center gap-3 font-semibold text-gray-600" @click="$emit('delete')">
          Eliminar módulo
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </button>
</template>

<script>
export default {
  name: 'RecordTopicNew',
  //components: { TextStatus },
  props: {
    data: {
      type: Object,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
