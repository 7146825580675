<template>
  <fw-panel :title="unit.title" :counter="unit.topics.total">
    <template v-if="canCreateModules" #toolbar>
      <fw-button type="link" @click.native="newTopic()">Criar novo tópico</fw-button>
      <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
          <fw-button size="sm" class="opacity-50" :class="{ 'opacity-100': active }">
            <fw-icon-more class="w-5 h-5" />
          </fw-button>
        </template>
        <b-dropdown-item paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center gap-2"
            label="Editar título"
            @click.native="editTitle()"
          >
            <fw-icon-edit class="w-5 h-5" /> Editar título
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center justify-start gap-2"
            label="Eliminar"
            @click.native="deleteModule()"
          >
            <fw-icon-trash class="w-5 h-5" /> Eliminar
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <div v-if="topics.length > 0" class="bg-white rounded-md">
      <RecordTopic
        v-for="topicData in topics"
        :key="topicData.key + rerenderings"
        :data="topicData"
        :agenda="agenda"
        :agenda-mode="agendaMode"
        :sessions="sessions"
        :unit-key="unit.key"
        @open="openTopic(topicData)"
        @delete="deleteTopic(topicData)"
        @edit="editTopicTitle(topicData)"
        @visibility="toggleVisibility(topicData, $event)"
      ></RecordTopic>
    </div>
    <div v-else-if="!loading" class="bg-white rounded-md py-16 text-center text-gray-400">
      Módulo sem tópicos.
    </div>
    <LoadingPlaceholder v-else />
    <fw-modal v-if="showNewTopic" :active.sync="showNewTopic" :can-cancel="true" size="min" @close="close">
      <template #default>
        <ModalNewTopic @new="createNewTopic($event)" @cancel="close()" />
      </template>
    </fw-modal>
  </fw-panel>
</template>

<script>
import RecordTopic from '@/fw-modules/fw-core-vue/courses/components/records/RecordTopic'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CoursesServices from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ModalNewTopic from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewTopic'
export default {
  name: 'PanelModule',
  components: {
    RecordTopic,
    LoadingPlaceholder,
    ModalNewTopic,
  },
  props: {
    courseKey: {
      type: String,
      required: true,
    },
    editionKey: {
      type: String,
      required: true,
    },
    unit: {
      type: Object,
      required: true,
    },
    canCreateModules: {
      type: Boolean,
      default: false,
    },
    agenda: {
      type: Boolean,
      default: false,
    },
    agendaMode: {
      type: String,
      default: 'scheduled',
    },
    sessions: {
      type: Object,
      default: () => ({}),
    },
    classKey: {
      type: String,
      default: null,
    },
  },
  data: function() {
    return {
      loading: false,
      topics: [],
      showNewTopic: false,
      rerenderings: 0,
    }
  },
  created() {
    this.loadTopics()
  },
  methods: {
    refresh() {
      console.log('refreshing Panel Module')
      //this.$forceUpdate()
      this.rerenderings++
    },
    editTitle() {
      //TODO
      this.$buefy.dialog.prompt({
        message: `Novo título do módulo`,
        inputAttrs: {
          placeholder: this.unit.title,
          minlength: 3,
        },
        trapFocus: true,
        onConfirm: value => {
          console.log(value)
          CoursesServices.updateUnit(this.courseKey, this.editionKey, this.unit.key, {
            title: value,
          })
            .then(response => {
              console.log(response)
              this.unit.title = value
              //show snackbar
              this.$buefy.snackbar.open({
                message: 'Título do módulo atualizado.',
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 5000,
              })
            })
            .catch(error => {
              console.log(error)
            })
        },
      })
    },
    editTopicTitle(topicData) {
      //TODO
      this.$buefy.dialog.prompt({
        message: `Novo título do tópico`,
        inputAttrs: {
          placeholder: topicData.title,
          minlength: 3,
        },
        trapFocus: true,
        onConfirm: value => {
          console.log(value)
          CoursesServices.updateTopic(this.courseKey, this.editionKey, this.unit.key, topicData.key, {
            title: value,
          })
            .then(response => {
              console.log(response)
              topicData.title = value
              //show snackbar
              this.$buefy.snackbar.open({
                message: 'Título do tópico atualizado.',
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 5000,
              })
            })
            .catch(error => {
              console.log(error)
            })
        },
      })
    },

    /*async saveAgenda(data) {
      console.log('saveAgenda', data)
      try {
        if (this.editingTopics[0].session && this.editingTopics[0].session.key) {
          console.log('update', this.editingTopics[0].session)
          let body = {}
          body[this.editingTopics[0].session.key] = {
            start_date: data.start_date,
            end_date: data.end_date,
            duration: data.duration ?? null,
          }
          //update
          let result = await CoursesServices.updateClassSession(this.courseKey, this.editionKey, this.classKey, body)
          console.log('saveAgendaTopic', result.data)
          //TODO: this.sessions = result.data
          let moduleKey = this.editingTopics[0].unit_key
          let topicKey = this.editingTopics[0].key
          this.sessions[moduleKey][topicKey].start_date = data.start_date
          this.sessions[moduleKey][topicKey].end_date = data.end_date
          if (this.sessions[moduleKey][topicKey].exam) {
            this.sessions[moduleKey][topicKey].exam.duration = data.duration ?? null
          }
          this.close()
        } else {
          let body = {}
          let moduleKey = this.editingTopics[0].unit_key
          body[moduleKey] = {}
          body[moduleKey][this.editingTopics[0].key] = {
            start_date: data.start_date,
            end_date: data.end_date,
            duration: data.duration ?? null,
          }
          let result = await CoursesServices.createClassSession(this.courseKey, this.editionKey, this.classKey, body)
          console.log('saveAgendaTopic', result.data)
          this.sessions = result.data
          this.close()
        }
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao guardar a calendarização do tópico.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      } finally {
        this.close()
      }
    },*/
    async toggleVisibility(topicData, visible) {
      console.log('toggleVisibility', visible)
      let session =
        this.sessions[this.unit.key] && this.sessions[this.unit.key][topicData.key]
          ? this.sessions[this.unit.key][topicData.key]
          : {}
      //today from midnight
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      session['start_date'] = visible ? today : null
      session['end_date'] = null

      let body = {}
      let result
      try {
        if (session.key) {
          body[session.key] = session
          result = await CoursesServices.updateClassSession(this.courseKey, this.editionKey, this.classKey, body)
        } else {
          body[this.unit.key] = {}
          body[this.unit.key][topicData.key] = session
          result = await CoursesServices.createClassSession(this.courseKey, this.editionKey, this.classKey, body)
        }
        console.log('toggleVisibility', result.data)
        this.$buefy.snackbar.open({
          message: `Tópico ${visible ? 'disponível' : 'indisponível'}.`,
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 5000,
        })
        this.$emit('session-updated', {
          data: result.data,
          sessionKey: result.data.key,
          topicKey: topicData.key,
          unitKey: this.unit.key,
        })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao guardar a visibilidade do tópico.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },
    deleteModule() {
      //ask confirmation
      this.$buefy.dialog.confirm({
        title: 'Eliminar módulo',
        message: `Tem a certeza que pretende eliminar o módulo "${this.unit.title}" e todos os seus tópicos?`,
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          CoursesServices.deleteUnit(this.courseKey, this.editionKey, this.unit.key)
            .then(response => {
              console.log(response)
              //show snackbar
              this.$buefy.snackbar.open({
                message: 'Módulo eliminado.',
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 5000,
              })
              //redirect to course management
              this.$router.push({
                name: 'course-manage',
                params: { id: this.courseKey },
              })
            })
            .catch(error => {
              console.log(error)
            })
        },
      })
    },
    deleteTopic(data) {
      //ask confirmation
      this.$buefy.dialog.confirm({
        title: 'Eliminar tópico',
        message: `Tem a certeza que pretende eliminar o tópico "${data.title}"?`,
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          CoursesServices.deleteTopic(this.courseKey, this.editionKey, this.unit.key, data.key)
            .then(response => {
              console.log(response)
              //show snackbar
              this.$buefy.snackbar.open({
                message: 'Tópico eliminado.',
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 5000,
              })
              //remove from list
              this.topics = this.topics.filter(topic => topic.key !== data.key)
            })
            .catch(error => {
              console.log(error)
            })
        },
      })
    },
    close() {
      this.showNewTopic = false
    },
    newTopic() {
      this.showNewTopic = true
    },
    loadTopics() {
      this.loading = true
      CoursesServices.getTopics(this.courseKey, this.editionKey, this.unit.key)
        .then(response => {
          console.log(response)
          this.topics = response.data.topics
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    openTopic(topic) {
      console.log('openTopic', topic)
      if (topic.type === 'normal' && topic.page_key && !this.agenda) {
        this.$router.push({
          name: 'content-pages-editor',
          params: { key: topic.page_key },
        })
      } else if (this.agenda) {
        if (this.sessions && this.sessions[topic.key]) {
          topic['session'] = this.sessions[topic.key]
        }
        this.$emit('open-topic', topic)
      }
    },
    createNewTopic(data) {
      console.log(data)
      CoursesServices.createTopic(this.courseKey, this.editionKey, this.unit.key, {
        title: data.name,
        type: data.type,
      })
        .then(response => {
          console.log(response)
          this.topics.push(response.data)
          this.close()
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
