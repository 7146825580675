<template>
  <div>
    <fw-heading class="flex-1" size="xl">Configurações</fw-heading>

    <fw-panel title="Metadados" class="my-5" boxed="sm">
      <template #toolbar>
        <fw-button v-if="editable" type="link" @click.native="editable = false">
          Cancelar
        </fw-button>
        <fw-button v-else-if="!editable" type="link" @click.native="edit()">
          Editar
        </fw-button>
        <fw-button v-if="editable" type="primary" @click.native="saveMetadata()">
          Guardar
        </fw-button>
      </template>
      <template #default>
        <div class="flex flex-col gap-2">
          <b-field label="Nome do curso">
            <b-input v-if="editable" v-model="title" maxlength="250"></b-input>
            <div v-else-if="course && course.title && course.title.length > 0">{{ course.title }}</div>
            <div v-else class="text-gray-500 text-sm">Sem título definido.</div>
            <fw-tip v-if="$v.title.$error" error>
              <span v-if="!$v.title.required">Um curso tem de ter um título</span>
              <span v-else>Título inválido</span>
            </fw-tip>
          </b-field>
        </div>
      </template>
    </fw-panel>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
export default {
  name: 'PanelManageCourseSettings',

  props: {
    course: Object,
  },

  data() {
    return {
      editable: false,
      title: '',
    }
  },

  validations: {
    title: {
      required,
      min: minLength(3),
    },
  },

  methods: {
    saveMetadata() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.course.title = this.title
      this.$emit('save', this.course)
      this.editable = false
    },
    edit() {
      this.editable = true
      this.title = this.course.title
    },
  },
}
</script>
