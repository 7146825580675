<template>
  <div v-if="log" :class="{ 'border-b border-gray-100 px-1 py-1': row }">
    <div class="rounded-lg group py-1.5 px-2 gap-2 flex flex-col cursor-pointer items-stretch">
      <div class="flex w-full gap-2 items-center" :class="{ 'text-primary': expanded }" @click="openActivity()">
        <div class="flex-1 flex gap-3 items-center">
          <div class="flex-1 flex flex-col">
            <div class="flex items-center gap-2">
              <div class="font-medium text-sm leading-5 text-gray-300 uppercase">
                {{ log.level_name }}
              </div>
              <div class="flex-1 flex items-center gap-2">
                <v-clamp autoresize :max-lines="1">{{ log.message || $t('noMessage') }}</v-clamp>
              </div>
            </div>
          </div>
          <div v-if="user" class="hidden w-44 md:flex gap-2 items-center">
            <fw-avatar :user="user" :size="'2xs'" />
            <v-clamp class="font-semibold text-sm" autoresize :max-lines="1">{{
              user.display_name || user.full_name
            }}</v-clamp>
          </div>
          <div class="text-gray-500 group-hover:text-gray-800 text-sm text-right flex-shrink-0 w-32">
            {{ log.created_date | formatDateTime }}
          </div>
        </div>
        <div v-if="expandable">
          <button>
            <fw-icon-chevron-up v-if="expanded" class="w-5 h-5" />
            <fw-icon-chevron-down v-else class="w-5 h-5" />
          </button>
        </div>
      </div>
      <div v-if="expandable && expanded">
        <fw-panel-info type="basic" class="flex flex-col md:flex-row gap-2 md:gap-5">
          <div class="flex flex-col gap-1">
            <div v-if="log.message" class="flex flex-col">
              <fw-label marginless>{{ $t('message') }}:</fw-label>
              <div class="font-semibold text-lg">{{ log.message }}</div>
            </div>
            <div v-if="showDetails" class="flex gap-1">
              <fw-label marginless>{{ $t('context') }}:</fw-label>
              <div>
                <a
                  v-if="hasLink"
                  class="text-sm text-primary my-1 mx-2 hidden md:inline-block rounded-xl bg-opacity-10 bg-primary px-3 py-1"
                  @click="goToContext"
                  >{{ log.context }} {{ log.context_key }}</a
                >
                <span v-else class="text-sm text-gray-500 my-1 mx-2">{{ log.context }} {{ log.context_key }}</span>
              </div>
            </div>
            <div v-if="user" class="flex flex-col gap-1 mt-4 mb-5">
              <fw-label marginless>{{ $t('user') }}:</fw-label>
              <div class="flex gap-2 items-center">
                <fw-avatar :user="user" :size="'sm'" />
                <div>
                  <v-clamp class="font-semibold" autoresize :max-lines="2">{{
                    user.display_name || user.full_name
                  }}</v-clamp>
                  <span>{{ user.email }}</span>
                </div>
              </div>
              <div class="text-xs text-gray-500 ml-12 flex flex-col gap-1">
                <div v-if="log.by_user_agent">
                  {{ log.by_user_agent.os }} {{ log.by_user_agent.os_version }} / {{ log.by_user_agent.browser }}
                  {{ log.by_user_agent.browser_version }}
                </div>
                <div v-if="showDetails">IP: {{ log.by_ip_address }}</div>
              </div>
            </div>
            <template v-if="showDetails">
              <div class="flex gap-1">
                <fw-label marginless>Log key:</fw-label>
                <div>{{ log.key }}</div>
              </div>
              <div class="flex gap-1">
                <fw-label marginless>{{ $t('service') }}:</fw-label>
                <div>{{ log.service }}</div>
              </div>
              <div class="flex gap-1">
                <fw-label marginless>{{ $t('activityType') }}:</fw-label>
                <div>{{ log.code }}<br /></div>
              </div>
              <div class="flex gap-1">
                <fw-label marginless>{{ $t('messageType') }}:</fw-label>
                <div>{{ log.level_name }}</div>
              </div>
            </template>
            <div class="flex flex-col gap-1">
              <fw-label marginless>{{ $t('createdDate') }}:</fw-label>
              <div>{{ log.created_date | humanDateTimePT }}</div>
            </div>
          </div>
        </fw-panel-info>
        <fw-panel-info v-if="showDetails" type="basic">
          <fw-label marginless>{{ $t('metadataAndRefs') }}:</fw-label>
          <json-viewer :value="log.metadata || {}"></json-viewer>
          <json-viewer :value="log.reference_keys || {}"></json-viewer>
        </fw-panel-info>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardActivityLog',
  props: {
    log: {
      type: Object,
      default: function() {
        return {}
      },
    },
    users: {
      type: Object,
      default: function() {
        return {}
      },
    },
    basic: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: true,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    showDetails: {
      type: Boolean,
      default: () => {
        return Boolean(localStorage.getItem('fw-debug'))
      },
    },
  },
  data() {
    return {
      expanded: false,
      selectedLanguage: this.$store.state.language || this.$i18n.locale,
      contextGoTo: {
        procedure: '/manage/procedure/{CONTEXT_KEY}/dashboard',
      },
    }
  },
  computed: {
    user() {
      if (this.users && this.log.by_user_key in this.users) {
        return this.users[this.log.by_user_key]
      }
      return null
    },
    hasLink() {
      return this.log.context in this.contextGoTo
    },
    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
  },
  methods: {
    goToContext() {
      if (this.hasLink) {
        this.$router.push(this.contextGoTo[this.log.context].replace('{CONTEXT_KEY}', this.log.context_key))
      }
    },
    openActivity() {
      this.expanded = !this.expanded
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "noMessage": "Sem mensagem definida",
    "message": "Mensagem",
    "context": "Context (type and key)",
    "user": "Utilizador",
    "service": "Serviço",
    "activityType": "Tipo de atividade",
    "messageType": "Tipo de mensagem",
    "metadataAndRefs": "Metadados e referências",
		"createdDate": "Data de criação"
  },
  "en": {
    "noMessage": "No message defined",
    "message": "Message",
    "context": "Context (type and key)",
    "user": "User",
    "service": "Service",
    "activityType": "Activity type",
    "messageType": "Message type",
    "metadataAndRefs": "Metadata and references",
		"createdDate": "Created date"
  }
}
</i18n>
