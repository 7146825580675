<template>
  <section>
    <fw-heading size="xl">Pessoas</fw-heading>
    <fw-panel
      v-if="owner !== null"
      title="Responsável"
      boxed="xs"
      custom-class="bg-white"
      class="mt-5 mb-6"
      counter="1"
    >
      <div>
        <Person :key="owner.key" :person="owner" open-details-on-click :selectable="false" :clickable="true">
          <template #options>
            <fw-button
              v-if="canEdit && owner.key == user.key"
              type="link"
              @click.native="peopleModal('chose_people', 'owner')"
              >Alterar</fw-button
            >
          </template>
        </Person>
      </div>
    </fw-panel>
    <fw-panel title="Gestores" :counter="managers.length" :boxed="managers.length ? 'xs' : null">
      <template v-if="canEdit" #toolbar>
        <b-dropdown aria-role="list" position="is-bottom-left">
          <fw-button slot="trigger" type="link">Adicionar</fw-button>
          <b-dropdown-item aria-role="listitem" @click="peopleModal('chose_people', 'managers')">
            <span>Utilizador individual</span>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="peopleModal('import_bulk', 'managers')">
            <span>Lista de utilizadores</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #default>
        <LoadingPlaceholder v-if="loading.managers" list></LoadingPlaceholder>
        <fw-panel-info v-else-if="managers.length == 0" centered empty>
          Não existem gestores associados a este curso.
        </fw-panel-info>
        <div v-else class="flex flex-col gap-3">
          <Person
            v-for="(manager, e) in managers"
            :key="'manager' + e"
            :person="users[manager.user_key]"
            :selectable="false"
            :clickable="false"
          >
            <template v-if="canRemove && user.key !== manager.user_key" #options>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ }">
                  <fw-button type="transparent" size="sm" class="text-gray-400 text-sm">
                    <fw-icon-more class="h-5 w-5" />
                  </fw-button>
                </template>

                <b-dropdown-item aria-role="listitem">
                  <div
                    class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                    @click="removeManager(manager.user_key)"
                  >
                    Remover
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
        </div>
        <BlockPagination
          v-if="pagination.managers.totalPages > 1"
          :per-page="pagination.managers.limit"
          :total="pagination.managers.totalResults"
          :total-pages="pagination.managers.totalPages"
          :current.sync="pagination.managers.page"
          @page-changed="pageChanged"
        />
      </template>
    </fw-panel>
    <b-modal
      :active="modal != null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="modal == 'chose_people'"
        :title="modalType == 'owner' ? 'Escolha o responsável' : 'Adicionar gestores'"
        :instructions="modalType == 'owner' ? 'Seleccione o responsável do curo' : 'Seleccione os gestores do curso'"
        :multiselect="modalType != 'owner'"
        :not-alowed-users="notAlowedUsers"
        :invite-people="false"
        :endpoint="searchEndpoint"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
      <ModalImportBulkUsers
        v-if="modal == 'import_bulk'"
        :search-engine="bulkSearch"
        @selected="selectedPeople"
        @close="closeModal"
      ></ModalImportBulkUsers>
    </b-modal>
  </section>
</template>

<script>
// import RecordTournament from '@/components/records/RecordTournament'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalImportBulkUsers from '@/fw-modules/fw-core-vue/ui/components/modals/ModalImportBulkUsers'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

export default {
  components: {
    Person,
    // RecordTournament,
    ChoosePeopleModal,
    LoadingPlaceholder,
    ModalImportBulkUsers,
  },

  props: {
    course: {
      type: Object,
      default: () => ({}),
    },
    edition: {
      type: Object,
      default: () => ({}),
    },
    /*users: {
      type: Object,
      default: () => ({}),
    },*/
  },

  data() {
    return {
      loading: {
        managers: false,
      },
      users: {},
      managers: [],
      pagination: {
        managers: {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 50,
        },
      },
      validations: {
        can_add: false,
        can_delete: false,
      },
      modal: null,
      modalType: null,
    }
  },

  computed: {
    canEdit() {
      return this.validations.can_add
    },
    canRemove() {
      return this.validations.can_delete
    },
    owner() {
      return this.users[this.course.user_key] ?? null
    },
    notAlowedUsers() {
      return this.managers.map(el => el.key).concat(this.course.user_key)
    },
    user() {
      return this.$store.getters.getUser
    },
  },

  async mounted() {
    this.getManagers()
  },

  methods: {
    pageChanged(page) {
      this.pagination.managers.page = page
      this.getManagers()
    },
    peopleModal(modal, type) {
      this.modal = modal
      this.modalType = type
    },
    async selectedPeople(selection) {
      console.log(`selectedPeople type:${this.modalType}`, selection)
      switch (this.modalType) {
        case 'owner':
          this.updateOwner(selection)
          break
        case 'managers':
          this.updateManagers(selection)
          break
        default:
          console.log('not a type')
      }
    },
    async updateOwner(selection) {
      this.$buefy.dialog.confirm({
        title: 'Alterar responsável',
        message: `Tem a certeza quer deixar de ser o responsável do curso? Se não for gestor, perderá acesso ao painel de administração do curso.`,
        confirmText: 'Continuar',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            let result
            result = await ServiceCourses.updateCourseOwner(this.course.key, selection[0].key)
            console.log('addCourseManagers', result)
            this.modal = null
            this.modalType = null
            this.$buefy.snackbar.open({
              message: `Responsável alterado.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000,
            })
          } catch (e) {
            this.showError('Ocorreu um erro ao modificar o responsável do curso')
            console.error(e)
          }
        },
      })
    },
    async updateManagers(selection) {
      try {
        let result
        result = await ServiceCourses.addCourseManagers(
          this.course.key,
          selection.map(el => el.key)
        )
        console.log('addCourseManagers', result)
        this.users = { ...this.users, ...result.data.users }
        this.managers = this.managers.concat(result.data.managers)
        this.modal = null
        this.$buefy.snackbar.open({
          message: `Gestores adicionados.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 3000,
        })
      } catch (e) {
        this.showError('Ocorreu um erro ao adicionar o(s) gestor(es)')
        console.error(e)
      }
    },
    async removeManager(key) {
      //ask for confirmation
      this.$buefy.dialog.confirm({
        title: 'Remover gestor',
        message: `Tem a certeza que deseja remover o gestor?`,
        confirmText: 'Remover',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            let result = await ServiceCourses.removeCourseManagers(this.course.key, [key])
            console.log(result)
            this.managers = this.managers.filter(el => el.user_key != key)
            this.$buefy.snackbar.open({
              message: `Participante removido.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000,
            })
          } catch (e) {
            this.showError('Ocorreu um erro ao remover o participante')
            console.error(e)
          }
        },
      })
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
    async searchEndpoint(params) {
      return await ServiceCourses.searchUsers(this.course.key, null, params)
    },
    async bulkSearch(query) {
      query = query.split(',')
      return await ServiceCourses.bulkSearchUsers(this.course.key, null, query)
    },

    /*async getStudents() {
      this.loading.students = true

      utils.tryAndCatch(
        this,
        // Main code to run
        async () => {
          const response = await ServiceCourses.getClassStudents(this.course.key, this.editionKey, this.classKey, {
            page: this.pagination.students.page,
            limit: this.pagination.students.limit,
          })
          console.log('getStudents :>> ', response)
          this.students = response.data.students
          this.pagination.students = response.data.pagination
          this.users = { ...this.users, ...response.data.users }
        },
        // Finally
        () => {
          this.loading.students = false
        }
      )
    },*/

    async getManagers() {
      this.loading.managers = true
      utils.tryAndCatch(
        this,
        // Main code to run
        async () => {
          const response = await ServiceCourses.getCourseManagers(this.course.key, {
            page: this.pagination.managers.page,
            limit: this.pagination.managers.limit,
          })
          console.log('getStaff :>> ', response)
          this.managers = response.data.managers
          if (response.data.pagination) {
            this.pagination.managers = response.data.pagination
          }
          if (response.data.validations) {
            this.validations = response.data.validations
          }
          this.users = { ...this.users, ...response.data.users }
        },
        // Finally
        () => {
          this.loading.managers = false
        }
      )
    },

    closeModal() {
      this.modal = null
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade"
  },
  "en": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade"
  }
}
</i18n>
