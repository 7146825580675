<template>
  <LayoutDefault
    class="cursor-default select-none"
    :header-session-nav="true"
    :header-toolbar="false"
    :main-footer="false"
    :main-sidebar="true"
    :disable-back-to="true"
  >
    <template #main-sidebar>
      <SidebarExamManage :exam-id="formid != null ? formid : ''" />
    </template>
    <template #main-content> EXAM PREVIEW</template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import SidebarExamManage from '@/fw-modules/fw-core-vue/exams/components/sidebars/SidebarExamManage'
export default {
  name: 'ViewExamPreview',
  components: { LayoutDefault, SidebarExamManage },
  computed: {
    formid() {
      return this.$route.params.id ? this.$route.params.id : null
    },
    instanceid() {
      return this.$route.params.instanceid ? this.$route.params.instanceid : null
    },
  },
}
</script>
