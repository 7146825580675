var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-96 rounded-3xl bg-panel p-5 flex gap-2 flex-col flex-shrink-0 select-none"},[_c('div',{staticClass:"text-sm text-title font-semibold"},[_vm._v("Atividade")]),_c('div',{staticClass:"flex gap-3 text-sm"},[_c('div',{staticClass:"cursor-pointer",class:{
        'text-primary font-semibold': _vm.activityFilter === 'main',
        'color-inactive-tab': _vm.activityFilter !== 'main',
      },on:{"click":function($event){return _vm.changeFilter('main')}}},[_vm._v(" "+_vm._s(_vm.$t('main'))+" ")]),_c('div',{staticClass:"cursor-pointer",class:{
        'text-primary font-semibold': _vm.activityFilter === 'detailed',
        'color-inactive-tab': _vm.activityFilter !== 'detailed',
      },on:{"click":function($event){return _vm.changeFilter('detailed')}}},[_vm._v(" "+_vm._s(_vm.$t('detailed'))+" ")])]),(_vm.filteredActivity.length > 0)?_c('div',{staticClass:"flex-1 relative overflow-hidden"},[_c('div',{ref:"activitylist",staticClass:"h-full overflow-y-scroll flex flex-col gap-3 justify-start rounded-lg hide-scrollbar"},_vm._l((_vm.filteredActivity),function(actv){return _c('ActivityCard',{key:'actv_' + actv.key,attrs:{"activity":actv,"users":_vm.users},on:{"open-user":function($event){return _vm.$emit('open-user', $event)}}})}),1),(_vm.activityOverflow)?_c('div',{staticClass:"absolute bottom-0 left-0 right-0 h-10 dashboard-overflow-gradient"}):_vm._e()]):_c('div',{staticClass:"flex-1 flex flex-col justify-center items-center"},[_c('fw-icon-rest',{staticClass:"color-icon-panel h-12 w-12 mb-2"}),_c('div',{staticClass:"text-title text-sm"},[_vm._v(_vm._s(_vm.$t('noactivity')))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }