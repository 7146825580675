<template>
  <SidebarBase class="sticky top-0">
    <template #main>
      <ButtonSidebar label="Editor" icon="journal" :active="examView" @click.native="goToView('editor')">
        <template #suffix>
          <fw-icon-chevron-up v-if="examView" class="w-4 h-4" />
          <fw-icon-chevron-down v-else class="w-4 h-4" />
        </template>
      </ButtonSidebar>
      <VerticalSteps
        v-if="false"
        :steps="versions"
        :current-step="version"
        class="mb-2"
        @clicked="goToVersion($event)"
      />
      <ButtonSidebar
        :active="view == 'evaluation'"
        label="Respostas"
        icon="exam"
        @click.native="goToView('evaluation')"
      />
      <ButtonSidebar
        label="Atividade"
        :active="view == 'activity'"
        icon="activity"
        @click.native="goToView('activity')"
      />
      <ButtonSidebar
        label="Configurações"
        :active="view == 'settings'"
        icon="settings"
        @click.native="goToSettings('exam')"
      >
        <template v-if="false" #suffix>
          <fw-icon-chevron-up v-if="view == 'settings'" class="w-4 h-4" />
          <fw-icon-chevron-down v-else class="w-4 h-4" />
        </template>
      </ButtonSidebar>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

export default {
  name: 'SidebarManage',
  components: {
    ButtonSidebar,
    SidebarBase,
    VerticalSteps,
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    versions: {
      type: Array,
      default: () => [],
    },
    version: {
      type: String,
    },
    subsettings: {
      type: String,
      default: 'exam',
    },
    /*view: {
      type: String,
      default: 'dashboard',
    },*/
  },

  computed: {
    currentURL() {
      return this.$router.currentRoute.path
    },
    user() {
      return this.$store.getters.getUser
    },
    permissions() {
      return this.$store.getters.userPermissions
    },
    //isAdminOrManager() {
    //  return this.permissions.isAdmin || this.permissions.isManager
    //},
    language() {
      return this.$store.state.language
    },
    baseUrl() {
      //return //this.instanceId.length > 0
      //? 'builder/exam/' + this.instanceId + '/' + this.examId
      //:
      return '/form/' + this.form.key
    },
    examView() {
      return this.view == 'preview' || this.view == 'editor'
    },
    /*examSteps() {
      let versionLetter = 64
      return this.versions.map(version => {
        versionLetter++
        return {
          text: 'Versão ' + String.fromCharCode(versionLetter),
          value: version,
          iconText: String.fromCharCode(versionLetter),
        }
      })*/

    /*return [
        {
          text: 'Preview',
          value: 'preview', //used for router and vue key
          icon: '',
          //status: this.validations && this.validations.metadata ? 'finished' : 'next',
          meeting_datetime: null,
        },
        {
          text: 'Editor',
          value: 'editor', //used for router and vue key
          meeting_datetime: null, //no caso se ser necessário uma reunião para discutir critérios
          icon: '',
          //status: this.validations && this.validations.criteria ? 'finished' : 'next',
        },
      ]*/
    //},
    view() {
      return this.$route.meta.view ?? 'dashboard' //this.$store.state.examManageView
    },
  },

  methods: {
    goToView(view) {
      //open in new tab
      this.$router.push(this.baseUrl + '/' + view)
      //this.$emit('change-view', view)
    },
    goToVersion(version) {
      this.$router.push(this.baseUrl + '/editor/' + version)
    },
    goToSettings(settings) {
      this.$router.push(this.baseUrl + '/settings/' + settings)
    },
  },
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>
