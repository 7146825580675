<template>
  <div class="px-2">
    <button
      class="flex py-3 px-2 gap-3 relative w-full text-left group rounded-lg items-center font-medium text-gray-600 hover:text-gray-800 font-medium"
      :class="{
        'text-primary bg-white shadow': data.key == selected,
        'bg-gray-300 bg-opacity-20 shadow-sm': data.key != selected,
      }"
    >
      <div class="font-bold text-sm pl-7 py-1 pr-1 flex gap-1 text-gray-500">
        <span v-if="data.type == 'normal'">{{ index + 1 }}</span>
        <fw-icon-list-view class="w-5 h-5 text-gray-500" />
      </div>
      <div class="flex-1">
        <v-clamp v-if="data.title" class="font-medium" autoresize :max-lines="1">
          {{ data.title }}
        </v-clamp>
        <div v-else class="text-gray-400 text-sm">Sem título</div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'RecordTopicNew',
  //components: { TextStatus },
  props: {
    data: {
      type: Object,
    },
    selected: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
