<template>
  <div class="pb-5">
    <fw-heading class="mb-5" size="h2">Configurações</fw-heading>
    <fw-panel title="Apresentação" boxed="sm" class="my-5">
      <div class="flex gap-5">
        <div class="flex flex-col w-64">
          <fw-label>Imagem de fundo</fw-label>
          <div
            v-if="(!editable && edition.cover != null) || (editable && tmp.cover != null)"
            class="aspect-w-5 relative aspect-h-3 bg-cover bg-center rounded-md bg-gray-100 flex flex-col justify-center text-gray-500 items-center border  border-gray-200"
            :style="{ 'background-image': `url('${editionUrlCover}')` }"
          ></div>
          <div
            v-else-if="!editable && edition.cover == null"
            class="aspect-w-5 relative aspect-h-3 rounded-md bg-gray-100 flex flex-col justify-center text-gray-500 items-center border  border-gray-200"
          >
            <div class="absolute top-1/2 -mt-3 left-1/2 -ml-20 text-gray-500">
              Sem imagem de fundo
            </div>
          </div>
          <div
            v-if="editable"
            class="relative"
            :class="{
              'aspect-w-5 aspect-h-3 rounded-md bg-gray-100 border border-gray-200': tmp.cover == null,
              'mt-2 flex flex-col gap-2 items-center justify-center': tmp.cover != null,
            }"
          >
            <Uploader
              :label="tmp.cover == null ? 'Enviar imagem de fundo' : 'Mudar imagem de fundo'"
              :is-docked="true"
              :layout="tmp.cover == null ? 'minimal' : 'simple'"
              reference-id="coverupdate"
              allowed="images"
              clear-after
              input-id="coverupdate"
              :multiple="false"
              :files.sync="coverFileUpload"
              :size="0"
              :new-file-context="{}"
              file-type="file"
              file-code=""
              bucket-code="files"
              bucket-type="file"
              :borderless="true"
              class="cursor-pointer update-modal-uploader"
              uploader-class="w-full rounded"
              @upload="uploaded('cover', $event)"
            />
            <fw-button v-if="tmp.cover != null" type="link-muted" @click.native="deleteImage('cover')">
              Remover imagem
            </fw-button>
          </div>
        </div>
        <div class="flex flex-col flex-1">
          <fw-label>Imagem de capa</fw-label>
          <div
            v-if="(!editable && edition.banner != null) || (editable && tmp.banner != null)"
            class="aspect-w-4 aspect-h-1 relative bg-cover bg-center rounded-md bg-gray-100 flex flex-col justify-center text-gray-500 items-center border  border-gray-200"
            :style="{ 'background-image': `url('${editionUrlBanner}')` }"
          ></div>
          <div
            v-else-if="!editable && edition.banner == null"
            class="aspect-w-4 aspect-h-1 relative rounded-md bg-gray-100 flex flex-col justify-center text-gray-500 items-center border  border-gray-200"
          >
            <div class="absolute top-1/2 -mt-3 left-1/2 -ml-20 text-gray-500">
              Sem imagem de capa
            </div>
          </div>
          <div
            v-if="editable"
            class="relative"
            :class="{
              'aspect-w-4 aspect-h-1 rounded-md bg-gray-100 border border-gray-200': tmp.banner == null,
              'mt-2 flex gap-2 items-center justify-center': tmp.banner != null,
            }"
          >
            <Uploader
              :label="tmp.banner == null ? 'Enviar imagem de capa' : 'Mudar imagem de capa'"
              :is-docked="true"
              :layout="tmp.banner == null ? 'minimal' : 'simple'"
              reference-id="bannerupdate"
              allowed="images"
              clear-after
              input-id="bannerupdate"
              :multiple="false"
              :files.sync="bannerFileUpload"
              :size="0"
              :new-file-context="{}"
              file-type="file"
              file-code=""
              :borderless="true"
              bucket-code="files"
              bucket-type="file"
              class="cursor-pointer update-modal-uploader"
              uploader-class="w-full rounded"
              @upload="uploaded('banner', $event)"
            />
            <fw-button v-if="tmp.banner != null" type="link-muted" @click.native="deleteImage('banner')">
              Remover imagem
            </fw-button>
          </div>
        </div>
      </div>
    </fw-panel>
    <fw-panel title="Opções" boxed="sm" class="my-5">
      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Tipo de inscrição</fw-label>
        </div>
        <b-select v-if="editable" v-model="tmp.admission_type" placeholder="Tipo de inscrição">
          <option value="pre-admitted">Pré-admitido</option>
          <option v-if="false" value="pending-admission">Inscrições abertas com selecção manual dos candidatos</option>
          <option v-if="false" value="open">Inscrições abertas sem restrições </option>
        </b-select>
        <div v-else-if="edition.admission_type">
          {{
            edition.admission_type == 'pre-admitted'
              ? 'Lista de estudantes pré-admitidos'
              : edition.admission_type == 'pending-admission'
              ? 'Inscrições abertas com selecção manual dos candidatos'
              : 'Inscrições abertas sem restrições'
          }}
        </div>
        <div v-else class="text-gray-500 text-sm">Tipo de inscrição não definido.</div>
      </div>
      <b-field v-if="tmp.admission_type != 'pre-admitted'" label="Número de vagas">
        <b-numberinput v-if="editable" v-model="tmp.seats_total"></b-numberinput>
        <div v-else-if="edition.seats_total && edition.seats_total > 0">{{ edition.seats_total }}</div>
        <div v-else class="text-gray-500 text-sm">Vagas ilimitadas.</div>
      </b-field>
      <div v-if="false" class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>ECTS</fw-label>
        </div>
        <b-numberinput v-if="editable" v-model="tmp.ects"></b-numberinput>
        <div v-else-if="edition.ects && edition.ects > 0">{{ edition.ects }}</div>
        <div v-else class="text-gray-500 text-sm">ECTS não definidos.</div>
      </div>
      <b-field label="Visibilidade dos tópicos">
        <b-select
          v-if="editable"
          v-model="tmp.options.topics_visibility_mode"
          placeholder="Selecione quando quer mostrar os tópicos"
        >
          <option value="manual">
            Manualmente
          </option>
          <option v-if="false" value="scheduled">
            Por agendamento
          </option>
        </b-select>
        <div v-else-if="edition.options.topics_visibility_mode">
          {{ edition.options.topics_visibility_mode == 'scheduled' ? 'Por agendamento' : 'Manualmente' }}
        </div>
        <div v-else class="text-gray-500 text-sm">Modo de disponibilidade dos tópicos não definido.</div>
      </b-field>
      <fw-message v-if="!editable && edition.options.topics_visibility_mode == 'scheduled'"
        >Pode agendar os tópicos na página da turma</fw-message
      >
      <fw-message v-if="editable && tmp.options.topics_visibility_mode == 'scheduled'"
        >Pode agendar os tópicos na página da turma</fw-message
      >
      <b-field label="Modo de consumo dos tópicos">
        <b-select
          v-if="editable"
          v-model="tmp.options.topics_consume_mode"
          placeholder="Selecione se pretende disponibilizar o próximo tópico apenas se o anterior estiver concluído"
        >
          <option value="sequential">
            Sequencialmente (próximo tópico apenas disponível se o anterior estiver concluído)
          </option>
          <option value="free">
            Livre (todos os tópicos disponíveis desde o início)
          </option>
        </b-select>
        <div v-else-if="edition.options.topics_consume_mode">
          {{ edition.options.topics_consume_mode == 'sequential' ? 'Sequencialmente' : 'Livre' }}
        </div>
        <div v-else class="text-gray-500 text-sm">Modo de consumo dos tópicos não definido.</div>
      </b-field>
      <div class="-mt-2 ml-2.5">
        <fw-message v-if="!editable && edition.options.topics_consume_mode == 'free'" type="tip-light" :padding="false"
          >Todos os tópicos estarão disponíveis para consumo consoante a data ou visibilidade dos mesmos</fw-message
        >
        <fw-message
          v-else-if="!editable && edition.options.topics_consume_mode == 'sequential'"
          type="tip-light"
          :padding="false"
        >
          Um tópico só estará disponível apenas se o tópico anterior tiver sido concluído.
        </fw-message>
        <fw-message v-if="editable && tmp.options.topics_consume_mode == 'free'" type="tip-light" :padding="false"
          >Todos os tópicos estarão disponíveis para consumo consoante a data ou visibilidade dos mesmos</fw-message
        >
        <fw-message
          v-else-if="editable && tmp.options.topics_consume_mode == 'sequential'"
          type="tip-light"
          :padding="false"
        >
          Um tópico só estará disponível apenas se o tópico anterior tiver sido concluído.
        </fw-message>
      </div>
      <b-field class="mt-4" label="Disponibilidade do curso após termino">
        <b-select
          v-if="editable"
          v-model="tmp.options.access_course_content"
          placeholder="Escolha quando quer disponibilizar o conteúdo do curso"
        >
          <option value="always">
            Sempre - mesmo após a data de fim da edição
          </option>
          <option value="while_running">
            Apenas durante a duração da edição
          </option>
        </b-select>
        <div v-else-if="edition.options.access_course_content">
          {{
            edition.options.access_course_content == 'always'
              ? 'Conteúdo disponível após o término da edição'
              : 'Conteúdo apenas disponível durante a duração da edição'
          }}
        </div>
        <div v-else class="text-gray-500 text-sm">Disponibilidade do conteúdo não definido.</div>
      </b-field>
      <fw-button v-if="editable" type="primary" @click.native="save('options')">
        {{ $t('save') }}
      </fw-button>
    </fw-panel>
    <fw-panel title="Calendarização" boxed="sm" class="my-5">
      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Data de início da edição</fw-label>
        </div>
        <b-datepicker v-if="editable" v-model="tmp.start_date" :placeholder="'Data de início'"></b-datepicker>
        <div v-else-if="edition.start_date">{{ edition.start_date | formatDate }}</div>
        <div v-else class="text-gray-500 text-sm">Sem data de início definida.</div>
      </div>

      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Data de fim da edição</fw-label>
        </div>
        <b-datepicker
          v-if="editable"
          v-model="tmp.end_date"
          :min-date="tmp.start_date"
          :placeholder="'Data de fim'"
        ></b-datepicker>
        <div v-else-if="edition.end_date">{{ edition.end_date | formatDate }}</div>
        <div v-else class="text-gray-500 text-sm">Sem data de fim definida.</div>
      </div>

      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Data de início das inscrições</fw-label>
        </div>
        <b-datepicker v-if="editable" v-model="tmp.signup_start_date" :placeholder="'Data de início das inscrições'">
        </b-datepicker>
        <div v-else-if="edition.signup_start_date">{{ edition.signup_start_date | formatDate }}</div>
        <div v-else class="text-gray-500 text-sm">Sem data de início das inscrições.</div>
      </div>

      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Data de fim das inscrições</fw-label>
        </div>
        <b-datepicker
          v-if="editable"
          v-model="tmp.signup_end_date"
          :min-date="tmp.signup_start_date"
          :placeholder="'Data de fim das inscrições'"
        >
        </b-datepicker>
        <div v-else-if="edition.signup_end_date">{{ edition.signup_end_date | formatDate }}</div>
        <div v-else class="text-gray-500 text-sm">Sem data de fim das inscrições.</div>
      </div>
      <fw-button v-if="editable" type="primary" @click.native="save('calendar')">
        {{ $t('save') }}
      </fw-button>
    </fw-panel>
    <fw-panel v-if="editable" title="Operações" boxed="sm" class="my-5">
      <div class="flex flex-col items-start">
        <fw-label>Estado da edição</fw-label>
        <fw-message v-if="missingFields.length > 0" type="error" class="w-full mb-4">
          Erro ao alterar o estado da edição. Faltam os seguintes campos para poder mudar a edição para esse estado:
          <div v-for="(field, f) in missingFields" :key="f" class="font-semibold">
            {{ fieldLabels[field] ? fieldLabels[field] : field }}
          </div>
        </fw-message>
        <div class="flex gap-2">
          <b-select v-model="status" placeholder="Seleccione um estado para a edição">
            <option :value="'draft'">
              Rascunho
            </option>
            <option :value="'published'">
              Publicado
            </option>
            <option v-if="status == 'published' || status == 'closed'" :value="'closed'">
              Terminado
            </option>
          </b-select>
          <fw-button
            type="xlight"
            :disabled="edition.status == status"
            :loading="savingStatus"
            @click.native="saveStatus()"
          >
            Guardar estado
          </fw-button>
        </div>
      </div>
      <div v-if="me && course.user_key == me.key" class="flex gap-5 border-t pt-3 mt-5 items-center justify-center">
        <div class="flex-1">
          <fw-label>Eliminar edição do curso</fw-label>
          <div class="text-sm text-gray-600 mb-1.5">
            Atenção: Esta operação é irreversível. Ao eliminar uma edição do curso, todo o seu conteúdo, dados e
            configurações serão eliminados.
          </div>
        </div>
        <div>
          <fw-button type="border-danger" @click.native="deleteEdition()">Eliminar edição</fw-button>
        </div>
      </div>
    </fw-panel>
  </div>
</template>

<script>
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  name: 'PanelCourseSettings',
  components: {
    Uploader,
  },
  props: {
    edition: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fieldLabels: {
        signup_start_date: 'Data de início das inscrições',
        signup_end_date: 'Data de fim das inscrições',
        start_date: 'Data de início da edição',
        end_date: 'Data de fim da edição',
        ects: 'ECTS',
        admission_type: 'Tipo de inscrição',
        seats_total: 'Número de vagas',
        summary: 'Sumário',
        scientific_area: 'Área científica',
        languages: 'Idiomas',
        duration: 'Duração',
      },
      missingFields: [],
      coverFileUpload: [],
      bannerFileUpload: [],
      status: null,
      savingStatus: false,
      tmp: {
        title: '',
        start_date: null,
        end_date: null,
        signup_start_date: null,
        signup_end_date: null,
        admission_type: null,
        seats_total: 0,
        cover: null,
        banner: null,
        options: {
          topics_visibility_mode: 'scheduled',
        },
      },
    }
  },
  computed: {
    editionUrlCover() {
      return this.edition.cover != null && this.edition.cover.thumb_url_format
        ? this.getImageViewUrl(this.edition.cover, 'max800')
        : ''
    },
    editionUrlBanner() {
      return this.edition.banner != null && this.edition.banner.thumb_url_format
        ? this.getImageViewUrl(this.edition.banner, 'max800')
        : ''
    },
    me() {
      return this.$store.getters.getUser
    },
  },
  mounted() {
    this.status = this.edition.status
    let tmp = JSON.parse(JSON.stringify(this.edition))
    if (tmp.start_date) {
      tmp.start_date = new Date(tmp.start_date)
    }
    if (tmp.end_date) {
      tmp.end_date = new Date(tmp.end_date)
    }
    if (tmp.signup_start_date) {
      tmp.signup_start_date = new Date(tmp.signup_start_date)
    }
    if (tmp.signup_end_date) {
      tmp.signup_end_date = new Date(tmp.signup_end_date)
    }
    this.tmp = tmp
  },
  methods: {
    async saveStatus() {
      this.savingStatus = true
      this.missingFields = []
      try {
        const response = await ServiceCourses.updateEditionStatus(this.course.key, this.edition.key, this.status)
        console.log('saveStatus', response)
        this.$emit('updated', response.data)
        this.$buefy.snackbar.open({
          message: 'Estado da edição actualizado',
          type: 'is-success',
        })
      } catch (error) {
        console.error('saveStatus', error.response.data)
        if (
          error.response.data &&
          error.response.data['__errors__'] &&
          error.response.data['__errors__'].length > 0 &&
          error.response.data['__errors__'][0].missign_fields
        ) {
          let missign_fields = error.response.data['__errors__'][0].missign_fields
          this.missingFields = missign_fields
        } else {
          this.$buefy.toast.open({
            message: 'Erro ao actualizar estado da edição',
            type: 'is-danger',
          })
        }
        /*if (createResult['__errors__'] && createResult['__errors__'].length > 0) {
            this.error = createResult['__errors__'][0]
            return
          }*/
      } finally {
        this.savingStatus = false
      }
    },
    deleteImage(field) {
      this.tmp[field] = null
      this.saveImages()
    },
    saveImages() {
      let tmp = JSON.parse(JSON.stringify(this.edition))
      console.log('saveImages', this.tmp)
      tmp.cover = this.tmp.cover
      tmp.banner = this.tmp.banner
      this.$emit('save-silent', tmp)
      this.$buefy.snackbar.open({
        message: 'Imagens guardadas com sucesso',
        type: 'is-success',
      })
    },
    getImageViewUrl(file, size) {
      return ServiceStorage.getImageViewUrl(file, size)
    },
    uploaded(input, event) {
      console.log('uploaded', input, event)
      if (event.length > 0) {
        this.tmp[input] = event[0].response.data.file
      }
      this.saveImages()
    },
    deleteEdition() {
      this.$buefy.dialog.confirm({
        title: 'Eliminar edição do curso',
        message: 'Tem a certeza que deseja eliminar esta edição do curso? Perderá todos os dados associados.',
        confirmText: 'Eliminar',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await ServiceCourses.deleteEdition(this.course.key, this.edition.key)
            this.$router.push({ name: 'course-manage', params: { id: this.course.key } })
          } catch (error) {
            this.$buefy.toast.open({
              message: 'Erro ao eliminar edição do curso',
              type: 'is-danger',
            })
          }
        },
      })
    },
    edit() {
      let tmp = JSON.parse(JSON.stringify(this.edition))
      if (tmp.start_date) {
        tmp.start_date = new Date(tmp.start_date)
      }
      if (tmp.end_date) {
        tmp.end_date = new Date(tmp.end_date)
      }
      if (tmp.signup_start_date) {
        tmp.signup_start_date = new Date(tmp.signup_start_date)
      }
      if (tmp.signup_end_date) {
        tmp.signup_end_date = new Date(tmp.signup_end_date)
      }
      this.tmp = tmp
    },
    save(section) {
      //verify data
      let previous = JSON.parse(JSON.stringify(this.edition))
      if (section == 'options') {
        previous['admission_type'] = this.tmp.admission_type
        previous['seats_total'] = this.tmp.seats_total
        previous['ects'] = this.tmp.ects
        previous['options'] = this.tmp.options
      } else if (section == 'calendar') {
        previous.start_date = this.tmp.start_date
        previous.end_date = this.tmp.end_date
        previous.signup_start_date = this.tmp.signup_start_date
        previous.signup_end_date = this.tmp.signup_end_date
      }
      console.log('sending', previous)
      this.$emit('save', previous)
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "cancel": "Cancel",
      "edit": "Edit",
      "save": "Save"
    },
    "pt": {
      "cancel": "Cancelar",
      "edit": "Editar",
      "save": "Guardar"
    }
  }
</i18n>
