<template>
  <div>
    <fw-panel
      :title="$t('modules')"
      featured
      :counter="pagination.total_items"
      :boxed="pagination.total_items ? 'xs' : null"
    >
      <template v-if="canCreateModules" #toolbar>
        <fw-button type="link" @click.native="newClass()">Criar módulo</fw-button>
      </template>
      <template #default>
        <LoadingPlaceholder v-if="loading" />
        <div v-else>
          <div v-if="results.length > 0">
            <Container
              group-name="topics"
              drop-class="card-ghost-drop"
              :drop-placeholder="dropPlaceholderOptions"
              :should-animate-drop="() => true"
              :get-child-payload="index => getChildPayload(index)"
              drag-handle-selector=".drag-handle-new"
              class="flex flex-col gap-1"
              @drop="onDropSection($event)"
            >
              <Draggable
                v-for="moduleData in results"
                :key="'input_' + moduleData.key"
                class="relative box-question border-b border-gray-100 pb-0.5 group"
              >
                <div class="drag-handle-new top-2.5 opacity-50 group-hover:opacity-100">
                  <fw-icon-draggable class="w-5 h-5" />
                </div>
                <RecordModule
                  :data="moduleData"
                  :edition-key="editionKey"
                  :course-key="courseKey"
                  :can-create-modules="canCreateModules"
                  @open="openModule(moduleData)"
                  @edit="editModule(moduleData)"
                  @delete="deleteModule(moduleData)"
                ></RecordModule>
              </Draggable>
            </Container>
          </div>
          <fw-panel-info v-else-if="!loading" clean empty class="my-5">
            Não foram definidos módulos para esta edição do curso.
          </fw-panel-info>
          <BlockPagination
            v-if="pagination && pagination.total_pages > 1"
            :total="pagination.total_items"
            :total-pages="pagination.total_pages"
            :current.sync="page"
            @page-changed="pageChanged"
          />
        </div>
      </template>
    </fw-panel>
    <fw-modal v-if="showNewClassModal" :active.sync="showNewClassModal" :can-cancel="true" size="min" @close="close">
      <template #default>
        <ModalNewModule @new="createNewModule($event)" @cancel="close()" />
      </template>
    </fw-modal>
    <fw-modal
      v-if="showChangeOrderModal"
      :active.sync="showChangeOrderModal"
      :can-cancel="true"
      size="min"
      @close="close"
    >
      <template #default>
        <ModalChangeOrder :content="results" :endpoint="changeOrder" @saved="savedOrder($event)" @close="close()" />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CoursesServices from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ModalNewModule from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewModule'
import RecordModule from '@/fw-modules/fw-core-vue/courses/components/records/RecordModule'
import ModalChangeOrder from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalChangeOrder'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import { Container, Draggable } from 'vue-dndrop'
export default {
  name: 'PanelCourseClasses',
  components: {
    LoadingPlaceholder,
    ModalNewModule,
    RecordModule,
    ModalChangeOrder,
    Container,
    Draggable,
    BlockPagination,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    edition: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      showNewClassModal: false,
      showChangeOrderModal: false,
      loading: false,
      results: [],
      pagination: {
        total_items: 0,
        total_pages: 0,
      },
      page: 1,
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
    }
  },
  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    canCreateModules() {
      return true
    },
    courseKey() {
      return this.course.key
    },
    editionKey() {
      return this.edition.key
    },
  },
  created() {
    this.loadModules()
  },
  methods: {
    onDropSection(dropResult) {
      console.log('onDropSection', dropResult)
      //this.endpoint(dropResult.payload.key, dropResult.addedIndex)
      this.changeOrder(dropResult.payload.key, dropResult.addedIndex)
      let result = this.applyDrag(this.results, dropResult)
      console.log('applyDrag', result)
      this.results = result
      this.$emit('update', this.results)
      //this.$emit('saved', result)
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    getChildPayload(index) {
      //console.log('getChildPayload', p, s, index)
      return this.results[index]
    },
    async deleteModule(module) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que deseja eliminar este módulo?',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
        onConfirm: async () => {
          let result = await CoursesServices.deleteUnit(this.courseKey, this.editionKey, module.key)
          console.log('deleteModule', result)
          this.results = this.results.filter(item => item.key !== module.key)
          this.$emit('update', this.results)
        },
      })
    },
    async editModule(module) {
      this.$buefy.dialog.prompt({
        message: 'Editar nome do módulo',
        inputAttrs: {
          value: module.title,
          required: true,
          maxlength: 200,
        },
        onConfirm: async value => {
          let result = await CoursesServices.updateUnit(this.courseKey, this.editionKey, module.key, {
            title: value,
          })
          console.log('editModule', result)
          this.results = this.results.map(item => {
            if (item.key === module.key) {
              item.title = value
            }
            return item
          })
          this.$emit('update', this.results)
        },
      })
    },
    async changeOrder(moduleKey, newIndex) {
      console.log('changeOrder', moduleKey, newIndex)
      try {
        let result = await CoursesServices.changeOrderUnit(this.courseKey, this.editionKey, moduleKey, newIndex)
        console.log('changeOrder result', result)
        this.$buefy.snackbar.open({
          message: 'Módulos reordenados com sucesso.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 3000,
        })
      } catch (e) {
        console.error(e)
      }
    },
    savedOrder(data) {
      console.log('savedOrder', data)
      this.results = data
      this.$emit('update', this.results)
    },
    openModule(module) {
      this.$router.push({
        name: 'course-manage-edition-module-topic',
        params: {
          id: this.course.key,
          editionId: this.edition.key,
          view: 'modules',
          moduleId: module.key,
        },
      })
    },
    async createNewModule(data) {
      try {
        let result = await CoursesServices.createUnit(this.courseKey, this.editionKey, {
          title: data.name,
        })
        console.log('createNewModule', result)
        this.results.push(result.data)
        this.$emit('update', this.results)
        this.close()
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar o módulo.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },
    async loadModules() {
      this.loading = true
      try {
        let data = await CoursesServices.getUnits(this.courseKey, this.editionKey, this.page)
        console.log('load modules', data)
        this.results = data.data.units
        this.pagination = data.data.pagination
      } finally {
        this.loading = false
      }
    },
    close() {
      this.showNewClassModal = false
      this.showChangeOrderModal = false
    },
    newClass() {
      this.showNewClassModal = true
    },
    pageChanged(page) {
      this.page = page
      this.loadModules()
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "modules": "Units"
    },
    "pt": {
      "modules": "Módulos"
    }
  }
</i18n>

<style scoped>
.drag-handle-new {
  @apply h-6 w-6 rounded-lg items-center flex justify-center text-center absolute left-3 top-3 z-10;
  cursor: grab;
}
</style>
