<template>
  <fw-layout wide>
    <template #main-content>
      <fw-panel-info type="orange" boxed icon class="mb-5">
        <div>
          A nova versão de Cursos está em
          <span class="font-bold">fase de testes</span>. Caso se depare com algum erro ou identifique aspetos passíveis
          de melhoria, por favor, contacte-nos através do nosso canal de suporte
          <a class="underline" href="mailto:support@ucframework.pt">support@ucframework.pt</a>.
        </div>
      </fw-panel-info>
      <fw-panel :title="$t('courses')" featured>
        <template v-if="canCreateCourses" #toolbar>
          <fw-button type="link" @click.native="newCourse()">Criar novo curso</fw-button>
        </template>
        <ContextualSearch :loading="loading" :start-value="searchInput" @search="search"></ContextualSearch>

        <PanelStats
          v-if="!isSearching && hasInstances && !loadingStats"
          class="pt-2"
          title="Números"
          :stats="stats"
          :loading="loadingStats"
        />
      </fw-panel>

      <div v-if="isSearching">
        <fw-panel :title="'Resultados'" :counter="courses.length" class="my-5">
          <div v-if="courses.length > 0" class="bg-white rounded-md">
            <CourseRecord
              v-for="(instance, i) in courses"
              :key="'course_search_' + i"
              :data="instance"
              @click.native="goToCourse(instance)"
            ></CourseRecord>
          </div>
          <LoadingPlaceholder v-else-if="loading" />
          <div v-else-if="!loading" class="bg-white rounded-md py-16 text-center text-gray-400">
            Sem resultados
          </div>
        </fw-panel>
      </div>

      <!-- Homepage (no search) -->
      <div v-else class="my-5">
        <div
          v-if="!loading && courses.length > 0"
          class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5"
        >
          <CardCourseDraft
            v-for="(instance, i) in courses"
            :key="'course_draft_' + i"
            :instance="instance"
            @click.native="goToCourse(instance)"
          ></CardCourseDraft>
        </div>
        <fw-panel-info v-else-if="!loading" centered clean>
          <div class="flex flex-col gap-5 items-center justify-center p-10">
            <div class="text-center">
              <div class="rounded-full p-5 bg-gray-300 text-white">
                <fw-icon-cube class="w-12 h-12" />
              </div>
            </div>
            <div>Ainda não tem cursos criados.</div>
            <div class="flex gap-5 items-center">
              <fw-button type="link" @click.native="newCourse()">Criar curso</fw-button>
            </div>
          </div>
        </fw-panel-info>
        <LoadingPlaceholder v-else />
      </div>
      <BlockPagination
        v-if="pagination.totalPages > 1"
        :per-page="pagination.limit"
        :total="pagination.totalResults"
        :total-pages="pagination.totalPages"
        :current.sync="pagination.page"
        @page-changed="pageChanged($event)"
      />
    </template>

    <template #modals>
      <fw-modal
        v-if="showNewCourseModal"
        :active.sync="showNewCourseModal"
        :can-cancel="true"
        size="min"
        @close="close"
      >
        <template #default>
          <ModalNewCourse @new="createNewCourse($event)" @cancel="close()" />
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import CardCourseDraft from '@/fw-modules/fw-core-vue/courses/components/cards/CardCourseDraft'
import CourseRecord from '@/fw-modules/fw-core-vue/courses/components/records/RecordCourse'
import CoursesServices from '../../services/ServiceCourses'
import ModalNewCourse from '../../components/modals/manage/ModalNewCourse'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
export default {
  name: 'ViewCoursesManagement',
  components: {
    ContextualSearch,
    PanelStats,
    CardCourseDraft,
    CourseRecord,
    LoadingPlaceholder,
    ModalNewCourse,
    BlockPagination,
  },
  data: function() {
    return {
      showNewCourseModal: false,
      stats: [],
      rawStats: null,
      hideStatsOnSearching: false,
      loadingStats: true,
      loading: false,
      searchInput: '',
      filters: [
        {
          key: 'state',
          label: 'Estado',
          options: [
            {
              key: 'reset',
              label: 'Todos',
            },
            {
              key: 'draft',
              label: 'Em edição',
            },
            {
              key: 'running',
              label: 'Em curso',
            },
            {
              key: 'waiting',
              label: 'Publicados',
            },
            {
              key: 'scheduled',
              label: 'Agendados',
            },
            {
              key: 'ended',
              label: 'Terminados',
            },
          ],
        },
      ],
      sortBy: null,
      sortDirection: 'asc',
      courses: [],
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 10,
      },
    }
  },
  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    appName() {
      return this.$store.state.appName || 'ucstudent'
    },
    language() {
      return this.$store.state.language || this.$i18n.locale || 'pt'
    },
    hasInstances() {
      return !!(!this.loading && this.courses.length)
    },
    archivedCounter() {
      if (!this.rawStats) return 0
      return this.rawStats.ended + this.rawStats.deleted + this.rawStats.archived
    },

    // Checks
    isSearching() {
      return this.searchInput.length > 0
    },

    // Permissions
    canCreateCourses() {
      // TODO: We must have roles or permissions assigned to the user, not using app name to allow them to do things...
      return this.appName === 'ucteacher'
    },

    showStats() {
      return true
    },
  },
  created() {
    console.log('ViewCoursesManagement created')
    this.search({})
  },
  methods: {
    pageChanged(page) {
      console.log('pageChanged', page)
      this.pagination.page = page
      this.search({})
    },
    async createNewCourse(data) {
      try {
        let result = await CoursesServices.createCourse({
          title: data.name,
        })
        console.log('createNewCourse', result)
        this.$router.push({ name: 'course-manage', params: { id: result.data.key } })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar o curso',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },
    seeAll(type) {
      console.log('seeAll')
      this.search({
        filters: type === 'published' ? ['state:waiting', 'state:scheduled', 'state:ended'] : ['state:' + type],
      })
    },
    seeArchived() {
      console.log('seeArchived')
      this.search({
        filters: ['state:ended'],
      })
    },
    /*async loadStats() {
      this.loadingStats = true
      // let data
      // try {
      //   data = await this.api.getProceduresStats()
      // } catch (error) {
      //   console.error('Error loadStats: ', error)
      // }
      let stats = []
      for (const [key, value] of Object.entries(this.rawStats)) {
        stats.push({
          label: '',
          value: value,
          valueSuffix: null,
          description: ServiceExams.dashboardStatisticsMessages(this.language)[key],
        })
      }
      console.log('STATS', stats)
      this.stats = stats
      this.loadingStats = false
    },*/
    getFilterText(key) {
      var split = key.split(':')
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (var j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },
    async search(searchCriteria) {
      if (searchCriteria.term) {
        this.searchInput = searchCriteria.term
      } else {
        this.searchInput = ''
      }
      /*
       term: this.searchTerm,
        filters: this.selectedFilters,
        orderBy: this.orderItemsBy != null ? this.orderItemsBy.key : null,
        orderDirection: this.orderItemsBy != null ? this.orderDirection : null,
        user: this.user,
        dates: this.dates,
      */

      console.log('Searching...')
      console.log(searchCriteria)

      this.loading = true
      try {
        let result = await CoursesServices.getAllCourses({
          query: this.searchInput,
          orderDirection: this.sortDirection,
          page: this.pagination.page,
        })
        console.log('search', result)
        this.courses = result.data.courses
        this.pagination = result.data.pagination
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    goToCourse(instance) {
      if (this.appName != 'ucstudent') {
        this.$router.push({ name: 'course-manage', params: { id: instance.key } })
      } else {
        //TODO? this.$router.push({ name: 'exams-examination', params: { key: instance.key } })
      }
    },
    close() {
      this.showNewCourseModal = false
    },
    newCourse() {
      this.showNewCourseModal = true
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "courses": "Courses"
    },
    "pt": {
      "courses": "Cursos"
    }
  }
</i18n>
