<template>
  <div
    class="exam-record flex p-2 gap-3 my-1 cursor-pointer relative w-full text-left group rounded-lg items-center bg-white hover:bg-gray-100"
  >
    <!-- Title -->
    <div class="flex-1">
      <div class="flex text-base gap-2 text-left items-center">
        <fw-icon-group class="text-gray-400 h-5 w-5" />
        <div>
          <v-clamp
            v-if="data.title != null && data.title.length > 0"
            autoresize
            :max-lines="1"
            class="text-black font-medium"
          >
            {{ data.title }}
          </v-clamp>
          <div v-else class="text-gray-400">Sem título</div>
        </div>
      </div>
      <div v-if="data.created_date" class="text-xs text-gray-500 flex gap-2 ml-7">
        <div>Criado em {{ data.created_date | formatDateTime }}</div>
      </div>
    </div>
    <!-- Stats -->
    <div class="flex gap-1"></div>
  </div>
</template>

<script>
import utils from '../../utils'

export default {
  name: 'RecordCourse',
  //components: { TextStatus },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      coursesLabels: utils.statusLabels,
    }
  },
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
