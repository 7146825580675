<template>
  <fw-panel title="Novo tópico">
    <div class="flex flex-col">
      <b-field label="Nome do tópico">
        <b-input ref="input" v-model="topicName" maxlength="200"></b-input>
      </b-field>
      <b-field v-if="false" label="Tipo de tópico" class="mt-2">
        <b-select v-model="type" placeholder="Selecione o tipo de tópico" expanded>
          <option value="normal">Normal</option>
          <option value="evaluation">Avaliação</option>
          <option value="quality_evaluation">Questionário de qualidade</option>
        </b-select>
      </b-field>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        Cancelar
      </fw-button>
      <fw-button :type="!allowNext ? 'disabled' : 'primary'" :disable="!allowNext" class="w-32" @click.native="next()">
        Criar
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
export default {
  name: 'ModalNewTopic',
  data() {
    return {
      topicName: '',
      type: 'normal',
    }
  },
  computed: {
    allowNext() {
      return this.topicName.length > 0
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus()
    })
  },
  methods: {
    next() {
      //verify name
      if (this.topicName.length == 0) {
        return
      }

      this.$emit('new', {
        name: this.topicName,
        type: this.type,
      })
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
