<template>
  <fw-layout full mobile-ready back-to="/">
    <template #main-content>
      <PanelRecordings />
    </template>
  </fw-layout>
</template>

<script>
import PanelRecordings from '@/fw-modules/fw-meetings-vue/components/panels/PanelRecordings'

export default {
  components: {
    PanelRecordings,
  },
}
</script>
