<template>
  <div class="flex gap-5 p-3 items-center cursor-pointer exam-record" :class="{ 'border-b  border-gray-100': !last }">
    <div class="flex flex-1 gap-2 text-left items-center">
      <fw-icon-arrow-right class="text-gray-400 h-5 w-5 item-arrow" />
      <div class="max-w-5xl ">
        <v-clamp v-if="bucket.titles != null" autoresize :max-lines="1" class="text-black font-semibold ">
          {{ getBucketName(bucket) }}
        </v-clamp>
        <div v-else class="text-gray-400">Sem título</div>
      </div>
    </div>

    <div class="text-gray-700 text-sm flex-shrink-0">Criado em {{ bucket.created_date | formatDateTime }}</div>

    <fw-avatar v-if="users && bucket.user_key" :user="users[bucket.user_key]" size="xs" />
  </div>
</template>

<script>
export default {
  name: 'QuestionsBucketRecord',
  //components: { TextStatus },
  props: {
    users: {
      type: Object,
    },
    language: {
      type: String,
      default: 'pt',
    },
    bucket: {
      type: Object,
    },
    last: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getBucketName(bucket) {
      let name = bucket.titles[this.language]
      if (name !== null && typeof name !== 'undefined') {
        return name
      } else {
        let secondaryName = this.language === 'pt' ? bucket.titles['en'] : bucket.titles['pt']
        if (secondaryName !== null && typeof secondaryName !== 'undefined') {
          return secondaryName
        } else {
          return 'Sem título'
        }
      }
    },
  },
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
